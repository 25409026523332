import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { downloadUploadedDocuments } from "../../service/user-service";
import { caseActions } from "../../_services";

const CaseDocumentViewPage = (props) => {
  const { viewCaseId } = props;
  console.log("view documents...", viewCaseId);
  console.log("view Case Id...", viewCaseId);
  const [documentDetails, setDocumentDetails] = useState([]);
  const dispatch = useDispatch();
  const documentTypes = [
    "Labour License Copy",
    "EPF Payment Confirmation Receipt",
    "EPF Combined Challan",
    "EPF ECR Electronic Challan cum Return",
    "ESI Transaction Details Challan",
    "ESI Monthly Contribution Detail",
    "Wages Register",
    "Attendance Register",
    "Other Document (Eg: Application)",
  ];

  useEffect(() => {
    dispatch(caseActions.getAllCaseDocuments(viewCaseId)).then((data) => {
      if (data.payload) {
        console.log("response data....", data);
        setDocumentDetails(data.payload);
      }
    });
  }, []);

  // const handleViewDocument = (filePath) => {
  // 	console.log("file Path....", filePath);
  // 	dispatch(retriveCaseDocumentById(filePath)).then((response) => {
  // 		try {
  // 			const fileURL = URL.createObjectURL(response.data);
  // 			// window.open(fileURL);
  // 			const newTab = window.open();
  // 			newTab.document.open();
  // 			newTab.document.write(`
  // 			  <html>
  // 			    <body>
  // 			      <embed src="${fileURL}" height="100%" width="100%" type="application/pdf"/>
  // 			    </body>
  // 			  </html>
  // 			`);
  // 			newTab.document.close();
  // 		} catch (error) {
  // 			console.log("error...", error);
  // 		}
  // 	});
  // };
  const handleViewAndDownloadDocument = async (
    filePath,
    type,
    documentName
  ) => {
    console.log("file Path....", filePath);
    // dispatch(retriveCaseDocumentById(filePath)).then((response) => {
    try {
      if (type === "view") {
        // 	const fileURL = URL.createObjectURL(response.data);
        // 	// window.open(fileURL);
        // 	const newTab = window.open();
        // 	newTab.document.open();
        // 	newTab.document.write(`
        //   <html>
        //     <body>
        //       <embed src="${fileURL}" height="100%" width="100%" type="application/pdf"/>
        //     </body>
        //   </html>
        // `);
        // 	newTab.document.close();
        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
          		  <html>
          		    <body>
          		      <embed src="${filePath}" height="100%" width="100%" type="application/pdf"/>
          		    </body>
          		  </html>
          		`);
        newTab.document.close();
      } else if (type === "download") {
        //   const blob = new Blob([response.data], { type: "application/pdf" });
        //   const url = URL.createObjectURL(blob);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.download = `${documentName}`;
        //   link.click();
        //   URL.revokeObjectURL(url);
        try {
          const response = await fetch(filePath);
          const blob = await response.blob();

          // Create a temporary link and trigger download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = documentName;
          link.click();
        } catch (error) {
          console.log("Error:", error);
        }
      }
    } catch (error) {
      console.log("error...", error);
    }
    // });
  };

  const handleDownloadUploadedDocuments = () => {
    console.log("case details Id......", viewCaseId);
    dispatch(caseActions.downloadUploadedDocument(viewCaseId)).then(
      (response) => {
        const data = response.payload;
        console.log(response);
        console.log("response data....", data);
        const blob = new Blob([data], { type: "application/zip" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "documents.zip";
        link.click();
        URL.revokeObjectURL(url);
      }
    );
  };

  return (
    <Card>
      {documentDetails.length !== 0 ? (
        <div className="d-flex flex-row justify-content-end">
          <Button
            size="sm"
            variant="secondary"
            onClick={handleDownloadUploadedDocuments}
          >
            Download Documents
          </Button>
        </div>
      ) : (
        ""
      )}
      <CardBody>
        <ul>
          {documentDetails.map((documents, index) => (
            <li key={documents.id} className="m-b-20">
              <div className="container">
                <div className="row">
                  <div className="d-flex justify-content-between col-10">
                    <h6 className="col-4">{documentTypes[index]}</h6>
                    <p className="col-6">{documents.document_name}</p>
                  </div>
                  <div className="d-flex justify-content-between col-2">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        handleViewAndDownloadDocument(
                          documents.document_path,
                          "view",
                          documents.document_name
                        )
                      }
                    >
                      View
                    </Button>
                    <Button
                      variant="transparent"
                      size="lg"
                      onClick={() =>
                        handleViewAndDownloadDocument(
                          documents.document_path,
                          "download",
                          documents.document_name
                        )
                      }
                    >
                      <i className="fa fa-download text-primary" />
                    </Button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default CaseDocumentViewPage;
