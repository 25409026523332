import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, ToolTip, Btn } from "../../AbstractElements";
//import { Contractor } from "../../AbstractElements";
import { Button } from "react-bootstrap";
//import HeaderCard from '../../Components/Common/Component/HeaderCard'
import DataTableComponent from "./ViewContractor";
import ModalForm from "./modals/modal";
import { contractorActions, circleActions } from "../../_services";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ViewContractor = () => {
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const modalTitle = "Contractor creation";
	const type = "add";
	const [contractorCreation, setContractorCreation] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const [cirlceOptions, setCircleOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [basictooltip, setBasicTooltip] = useState(false);
	const toolTipToggle = () => setBasicTooltip(!basictooltip);

	const contractorCreationToggle = () => {
		setContractorCreation(!contractorCreation);
	};

	const handleAdd = () => {
		toggle();
	};

	useEffect(() => {
		dispatch(circleActions.getCircleNames()).then((response) => {
			setCircleOptions(response.payload);
		});
	}, []);

	const handleSelectedOption = (selectedValues) => {
		setSelectedOptions(selectedValues);
	};

	const handleDownload = () => {
		console.log("handle download.....");
		// setDisabled(true);
		toast.success("Download started, Please wait...");
		let searchValues = null;
		let searchKey = "circles";
		const selectedCircleIds = selectedOptions.map(
			(eachSelectedCircle) => eachSelectedCircle.value
		);
		if (selectedCircleIds.length !== 0) {
			console.log("I'm in if block");
			searchValues = selectedCircleIds.join(",");
		}
		dispatch(
			contractorActions.svpDatabaseDownload({ searchKey, searchValues })
		).then((response) => {
			console.log("response...............", response);
			const { payload } = response;
			const blobData = new Blob([payload]);
			const link = document.createElement("a");
			const url = URL.createObjectURL(blobData);
			link.href = url;
			link.download = "Contractors.xlsx";
			link.click();
			URL.revokeObjectURL(url);
		});
	};
	const entitlementCode = localStorage.getItem("entitilement");
	const customStyles = {
		control: (base) => ({
			...base,
			minWidth: "15vw",
		}),
	};
	return (
		<Fragment>
			<Breadcrumbs
				parent="Master's"
				title="Contractor"
				mainTitle="Contractor"
			/>
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								{entitlementCode === "AG001" ||
								entitlementCode === "SVP001" ||
								entitlementCode === "AA001" ||
								entitlementCode === "AU001" ||
								entitlementCode === "AC001" ? (
									""
								) : (
									<div className="d-flex justify-content-end">
										<Select
											className="basic-multi-select me-3"
											classNamePrefix="select"
											options={cirlceOptions}
											isMulti
											closeMenuOnSelect={false}
											components={animatedComponents}
											value={selectedOptions}
											onChange={handleSelectedOption}
											styles={customStyles}
											placeholder={"Select Circle..."}
										/>
										<div className="d-flex justify-content-around">
											<div className="btn-showcase">
												<Button
													variant="secondary"
													onClick={handleDownload}
													id="download"
												>
													Excel Export
												</Button>
												<ToolTip
													attrToolTip={{
														placement: "top",
														isOpen: basictooltip,
														target: "download",
														toggle: () => toolTipToggle(),
													}}
												>
													Contractor database download
												</ToolTip>
											</div>
											<Button
												variant="primary"
												className="mb-3"
												onClick={handleAdd}
											>
												Add New
											</Button>
										</div>
									</div>
								)}
								{modal ? (
									<ModalForm
										modal={modal}
										toggle={toggle}
										type={type}
										title={modalTitle}
										contractorCreationToggle={contractorCreationToggle}
									/>
								) : (
									""
								)}
								<DataTableComponent contractorCreation={contractorCreation} />
								{console.log(selectedOptions)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default ViewContractor;
