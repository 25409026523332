import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardBody, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import LoaderComponent from "../common/Spinner/LoaderComponent";
import { circleActions, contractorActions, userActions } from "../../_services";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const CreateUser = ({ toggle, userCreationToggle }) => {
  let loader;
  const orgId = localStorage.getItem("userId");
  const entitlementCode = localStorage.getItem("entitilement");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [options, setOptions] = useState([]);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();
  const [loadingElement, setLoadingElement] = useState(false);
  let password;
  const Displayalert = () => {
    toast.success("User created successfully!");
    /* Toast.fire({
      text: "User created successfully",
      icon: "success",
    }); */
  };
  useEffect(() => {
    const getLocation = async () => {
      await dispatch(circleActions.getCircleNames()).then((data) => {
        if (data.payload) {
          console.log("circle value in redux -->", data);
          setOptions(data.payload);
        }
      });
    };
    getLocation();
  }, []);

  const handleCircleChange = (event) => {
    console.log("circle id", event.target.value);
    if (event.target.value) {
      dispatch(circleActions.getCircleById(event.target.value)).then((data) => {
        console.log("data from circle user------->", data);
        if (data) {
          setValue("emailId", data.payload.email_id);
          password = data.payload.location_code;
        }
      });
    } else {
      toast.error("Please select the circle");
      setValue("emailId", "");
    }
  };
  const mobileNumberValidation = async (e) => {
    const validation = e.target.value;
    await dispatch(contractorActions.contactDetailsValidation(validation)).then(
      (response) => {
        console.log(response);
        if (response.payload === "Already exist") {
          console.log("no value");
          setValue("mbNo", "");
          toast.warn("Mobile Number already exist");
          /* Toast.fire({
          text: "Mobile number already exists",
          icon: "error",
        }); */
        } else {
          console.log("something went wrong");
        }
      }
    );
  };
  const onSubmit = (data) => {
    setLoadingElement(true);
    let circleVal = Number(data.circle);
    if (data && role == "Circle Admin") {
      const request = {
        first_name: data.fName.toUpperCase(),
        last_name: data.lName.toUpperCase(),
        mobile_number: data.mbNo,
        email_id: data.emailId.toUpperCase(),
        designation: data.Desigination.toUpperCase(),
        primaryRoles: data.role.toUpperCase(),
        user_name: data.emailId,
        password: password,
        circle_id: circleVal,
        is_primary: true,
      };
      dispatch(userActions.createUser(request)).then((data) => {
        console.log("creation user redux", data);
        if (data.type === "/createUser/fulfilled") {
          setLoadingElement(false);
          userCreationToggle();
          Displayalert();
          toggle();
          setLoadingElement(false);
        }
      });
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom01">
                  First Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom01"
                  autoComplete="off"
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  {...register("fName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.fName && "First name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom02">
                  Last Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom02"
                  autoComplete="off"
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  {...register("lName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.lName && "Last Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom06">
                  Roles<span className="text-danger">*</span>
                </Label>
                <select
                  className="form-select  bg-light text-dark"
                  autoComplete="off"
                  id="validationCustom06"
                  name="role"
                  {...register("role", { required: true })}
                  onChange={(val) => setRole(val.target.value)}
                >
                  {entitlementCode === "AA001" ? (
                    <>
                      <option value="">Choose Role </option>
                      <option value="Auditor">Auditor </option>
                    </>
                  ) : (
                    <>
                      <option value="">Choose Role </option>
                      <option value="Circle Admin">Circle Admin </option>
                      {/* <option value="Auditor Admin">Auditor Admin </option>
                      <option value="Auditor">Auditor </option>
                      <option value="Accountant">Accountant</option> */}
                    </>
                  )}
                </select>
                <span className="d-block text-danger">
                  {errors.role && "Role  is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom04">
                  Mobile Number<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom04"
                  autoComplete="off"
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Mobile Number"
                  name="mbNo"
                  maxlength="10"
                  {...register("mbNo", { required: true })}
                  onBlur={(e) => mobileNumberValidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.mbNo && "Mobile number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom05">
                  Desigination<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom05"
                  autoComplete="off"
                  name="Desigination"
                  type="text"
                  placeholder="Desigination"
                  {...register("Desigination", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.Desigination && "Desigination is required."}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <>
                  <Label className="form-label" for="validationCustom08">
                    Circle <span className="text-danger">*</span>
                  </Label>
                  <select
                    className="form-select  bg-light text-dark"
                    autoComplete="off"
                    id="validationCustom08"
                    name="circle"
                    {...register("circle", { required: true })}
                    onChange={(e) => handleCircleChange(e)}
                  >
                    <option value=""> Choose circle</option>
                    {options.map((eachCircle) => (
                      <option key={eachCircle.value} value={eachCircle.value}>
                        {eachCircle.label}
                      </option>
                    ))}
                  </select>
                  <span className="d-block text-danger">
                    {errors.circle && "circle  is required"}
                  </span>
                </>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom03">
                  Email id(User ID)<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="validationCustom03"
                  autoComplete="off"
                  type="email"
                  placeholder="Email id"
                  name="emailId"
                  {...register("emailId", { required: true })}
                  disabled="true"
                />
                <span className="d-block text-danger">
                  {errors.emailId && "Email Id  is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            {loadingElement ? (
              <LoaderComponent />
            ) : (
              <Button attrBtn={{ color: "primary" }}>{"Submit"}</Button>
            )}
            {/* <Button attrBtn={{ color: "primary" }}>{"Submit"}</Button> */}
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CreateUser;
