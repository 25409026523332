import React, { Fragment, useState } from "react";
import CommonModal from "./commonModal";
import CaseDetailsTable from "../CaseDetailsTable";
import PaymentRefForm from "../PaymentRefForm";
import CaseFlowHistoryDetails from "../CaseFlowHistoryDetails";

const ModalForm = ({
	modal,
	toggle,
	modalTitle,
	type,
	uploadCaseId,
	caseDetail,
	viewCaseId,
	changeStatus,
	caseCreationDetail,
	caseRemarks,
	isViewClicked,
	svcDetails,
	size,
	caseId,
	caseFlowHistoryDetails,
}) => {
	console.log("caseStaus...", changeStatus);

	return (
		<Fragment>
			{/* <Btn attrBtn={{ color: 'primary', onClick: toggle }} >{VerticallyCentered}</Btn> */}
			<CommonModal
				isOpen={modal}
				title={modalTitle}
				toggler={toggle}
				size="lg"
				type={type}
				caseDetail={caseDetail}
			>
				{type === "view" && (
					<CaseDetailsTable
						caseDetails={caseDetail}
						type={type}
						caseCreationDetails={caseCreationDetail}
						caseRemarks={caseRemarks}
						svcDetails={svcDetails}
						isViewClicked={isViewClicked}
					/>
				)}
				{/* {type !== "view" && (
					<CaseUploadForm
						toggle={toggle}
						caseDetailsId={uploadCaseId}
						changeStatus={changeStatus}
					/>
				)} */}
				{type === "makePayment" && (
					<PaymentRefForm caseId={caseId} toggle={toggle} />
				)}
				{type === "caseFlow" && (
					<CaseFlowHistoryDetails
						caseFlowHistoryDetails={caseFlowHistoryDetails}
					/>
				)}
			</CommonModal>
		</Fragment>
	);
};

export default ModalForm;
