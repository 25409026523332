import React, { Fragment } from "react";
import CommonModal from "./commonModal";
import CreateContractor from "../creationForm";
import EditContractor from "../Editform";

const ModalForm = ({
  modal,
  toggle,
  type,
  title,
  value,
  contractorCreationToggle,
  contractorUpdateToggle,
}) => {
  return (
    <Fragment>
      {/* <Btn attrBtn={{ color: 'primary', onClick: toggle }} >{VerticallyCentered}</Btn> */}
      <CommonModal isOpen={modal} title={title} toggler={toggle} size="lg">
        {type === "add" && (
          <CreateContractor
            toggle={toggle}
            contractorCreationToggle={contractorCreationToggle}
          />
        )}
        {type === "edit" && (
          <EditContractor
            value={value}
            toggle={toggle}
            contractorUpdateToggle={contractorUpdateToggle}
          />
        )}
      </CommonModal>
    </Fragment>
  );
};

export default ModalForm;
