import React from "react";
import { Form, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";

const PaymentRefForm = (props) => {
	const { caseId, toggle } = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();
	const dispatch = useDispatch();

	const onSubmit = (data) => {
		if (data) {
			const { paymentRefNo } = data;
			const reqBody = {
				caseId: caseId,
				paymentRefNo: paymentRefNo,
				status: "SUCCESS",
			};
			console.log(reqBody);
			dispatch(caseActions.paymentToBeReturned(reqBody)).then((response) => {
				console.log("response.........", response);
			});
			reset();
			toggle();
		} else {
			errors.showMessages();
		}
	};

	return (
		<Form
			className="needs-validation"
			noValidate=""
			onSubmit={handleSubmit(onSubmit)}
		>
			<Row>
				<div className="d-flex justify-content-between align-items-center">
					<Col className="col-3 ">
						<Label className="form-label" for="paymentRefNo">
							PAYMENT REF.NO. <span className="text-danger">*</span>
						</Label>
					</Col>
					<Col className="col-1">
						<strong>:</strong>
					</Col>
					<Col className="col-6 m-r-20">
						<input
							className="form-control"
							id="paymentRefNo"
							type="text"
							placeholder="Payment Reference Number"
							name="paymentRefNo"
							{...register("paymentRefNo", { required: true })}
						/>
						<span className="d-block text-danger">
							{errors.paymentRefNo && "Payment Reference Number is required"}
						</span>
						<div className="valid-feedback">Looks good!</div>
					</Col>
					<Col>
						<Button variant="primary" size="sm" type="submit">
							OK
						</Button>
					</Col>
				</div>
			</Row>
		</Form>
	);
};

export default PaymentRefForm;
