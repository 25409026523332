import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const CommonModal = (props) => {
	console.log("props....", props);
	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.toggler}
			size={props.size}
			centered
			className="modal-dialog-scrollable"
		>
			<ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
			<ModalBody className={props.bodyClass}>{props.children}</ModalBody>
			<ModalFooter>
				<br />
			</ModalFooter>
		</Modal>
	);
};

export default CommonModal;
