import React, { Fragment, useState } from "react";
import CommonModal from "./commonModal";
import CircleCreationFormModal from "../CircleCreationForm";
import EditCircle from "../EditCircle";

const ModalForm = ({
	modal,
	toggle,
	modalTitle,
	type,
	circleDetails,
	displayCircle,
	formUpdatedToggle,
	circleCreationToggle,
	size,
}) => {
	console.log("displaycircles...", displayCircle);

	return (
		<Fragment>
			{/* <Btn attrBtn={{ color: 'primary', onClick: toggle }} >{VerticallyCentered}</Btn> */}
			<CommonModal
				isOpen={modal}
				title={modalTitle}
				toggler={toggle}
				displayCircles={displayCircle}
				size={size}
			>
				{type === "add" && (
					<CircleCreationFormModal
						toggler={toggle}
						displayCircle={displayCircle}
						circleCreationToggle={circleCreationToggle}
					/>
				)}

				{type === "edit" && (
					<EditCircle
						toggler={toggle}
						circleDetails={circleDetails}
						formUpdatedToggle={formUpdatedToggle}
					/>
				)}
			</CommonModal>
		</Fragment>
	);
};

export default ModalForm;
