import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { caseActions, contractorActions } from "../../_services";

const CaseCreationFormModal = () => {
  const navigate = useNavigate();
  const svcProviderId = localStorage.getItem("userId");
  const [selectCircles, setSelectCircles] = useState([]);
  const [circleId, setCircleId] = useState(null);
  const [orgSvcMap, setOrgSvcMap] = useState(null);
  const [isbuttonDisabled, setIsbuttonDisabled] = useState(false);
  let filteredData = null;
  const dispatch = useDispatch();

  const Displayalert = () => {
    SweetAlert.fire({
      title: "Great!",
      text: "Case saved successfully!",
      icon: "success",
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(contractorActions.getContractorById(svcProviderId)).then(
      (data) => {
        console.log("response data....", data);
        const svcDetails = data.payload[0];
        const emailId = data.payload[1][0].contact_detail;
        setOrgSvcMap(data.payload[2]);
        setSelectCircles(data.payload[3]);
        //   const orglocationsId = orgSvcMap.map(
        //     (eachOrgSvcMap) => eachOrgSvcMap.org_location_id
        //   );
        //   fetchAllCircles().then((circlesInfo) => {
        //     const { data } = circlesInfo;
        //     console.log("circles data...", data);
        //     const filteredCircleNames = [];
        //     for (let orgLocationId of orglocationsId) {
        //       const filterCircles = data.filter(
        //         (eachCircle) => eachCircle.id === orgLocationId
        //       );
        //       filteredCircleNames.push({
        //         name: filterCircles[0].name,
        //         circleId: filterCircles[0].id,
        //       });
        //     }
        //     setSelectCircles(filteredCircleNames);
        //   });
        setValue("contractorFirm", svcDetails.establishment_name);
        setValue("email", emailId);
        setValue("epfRegistration", svcDetails.epf_number);
        setValue("esiRegistration", svcDetails.esi_number);
      }
    );
  }, []);
  // const acceptanceNoValidationFunction = async (e) => {
  //   const validation = e.target.value;
  //   await dispatch(AcceptanceNoValidation(validation)).then((response) => {
  //     console.log(response);
  //     if (response.status === 200 && response.data === null) {
  //       console.log("no value");
  //     } else if (response.data) {
  //       setValue("acceptanceOrderNo", "");
  //       toast.error("Acceptance  Number already exist");
  //     } else {
  //       console.log("something went wrong");
  //     }
  //   });
  // };

  // const aggrementNoValidationFunction = async (e) => {
  //   const validation = e.target.value;
  //   await dispatch(AggrementNoValidation(validation)).then((response) => {
  //     console.log(response);
  //     if (response.status === 200 && response.data === null) {
  //       console.log("no value");
  //     } else if (response.data) {
  //       setValue("agreementNo", "");
  //       toast.error("Aggrement Number already exist");
  //     } else {
  //       console.log("something went wrong");
  //     }
  //   });
  // };
  const handleCircleChange = (event) => {
    console.log("circle id", event.target.value);
    if (event.target.value) {
      filteredData = orgSvcMap.filter((labour_number) => {
        if (labour_number.org_location_id == event.target.value) {
          return true;
        }
        return false;
      });
      console.log("filteredData", filteredData);
      setValue("labourLicense", filteredData[0].labour_number);
    } else {
      toast.error("Please select the circle");
      /*       Toast.fire({
        text: "Please choose circle",
        icon: "error",
      }); */
      setValue("labourLicense", "");
    }
  };

  const onSubmit = async (formData) => {
    if (formData !== "") {
      setIsbuttonDisabled(true);
      console.log("data...", formData);
      const reqBody = {
        organizationId: 1,
        auditAgencyId: 1,
        svcProviderId: Number(svcProviderId),
        orgLocationId: Number(formData.circleId),
        subServiceProviderId: Number(svcProviderId),
        acceptanceOrderNo: formData.acceptanceOrderNo.toUpperCase(),
        agreementNo: formData.agreementNo.toUpperCase(),
        workStartDate: formData.workStartDate,
        workEndDate: formData.workEndDate,
        natureOfWork: formData.natureOfWork.toUpperCase(),
        svcProviderStatus: "DRAFT",
      };
      console.log("reqBody", reqBody);

      dispatch(caseActions.createCase(reqBody)).then((data) => {
        const id = data.payload[1];
        if (id !== null) {
          localStorage.setItem("caseId", id);
          navigate(`${process.env.PUBLIC_URL}/case/details`);
          reset();
        }
      });
    } else {
      setIsbuttonDisabled(false);
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardHeader
          className="text-center text-dark fs-3"
          style={{ padding: "5px" }}
        >
          Case Creation Form
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="contractorFirm">
                  Name of the Contractor Firm{" "}
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="contractorFirm"
                  autoComplete="off"
                  type="text"
                  placeholder="Contractor Firm"
                  name="contractorFirm"
                  disabled
                  {...register("contractorFirm", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.contractorFirm && "Contractor Firm is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="email">
                  Email Id
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="prefix"
                  autoComplete="off"
                  type="email"
                  placeholder="Email"
                  name="email"
                  disabled
                  {...register("email", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.email && "Email is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="epfRegistration">
                  EPF Registration Number
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="epfRegistration"
                  autoComplete="off"
                  type="text"
                  placeholder="EPF Registration Number"
                  name="epfRegistration"
                  disabled
                  {...register("epfRegistration", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.epfRegistration &&
                    "EPF Registration Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="esiRegistration">
                  ESI Registration Number
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="esiRegistration"
                  autoComplete="off"
                  type="text"
                  placeholder="ESI Registration Number"
                  name="esiRegistration"
                  disabled
                  {...register("esiRegistration", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.esiRegistration &&
                    "ESI Registration Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="circleName">
                  Name of the Circle <span className="text-danger">*</span>
                </Label>
                {/* <input
									className="form-control"
									id="circleName"
									type="text"
									placeholder="Circle Name"
									name="circleName"
									{...register("circleName", { required: true })}
								/> */}
                <select
                  className="form-select bg-light text-dark"
                  placeholder="Select circle Name"
                  autoComplete="off"
                  name="circleId"
                  {...register("circleId", { required: true })}
                  onChange={(e) => handleCircleChange(e)}
                >
                  {console.log("circleId...", circleId)}
                  <option value="">Choose circle</option>
                  {selectCircles.map((eachCircle) => (
                    <option key={eachCircle.value} value={eachCircle.value}>
                      {eachCircle.label}
                    </option>
                  ))}
                </select>
                <span className="d-block text-danger">
                  {errors.circleId && "Circle Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="labourLicense">
                  Labour License Number
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="labourLicense"
                  autoComplete="off"
                  type="text"
                  placeholder="Labour License Number"
                  name="labourLicense"
                  disabled
                  {...register("labourLicense", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.labourLicense && "Labour License Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="acceptanceNo">
                  Acceptance/ Formal Order No{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="acceptanceNo"
                  autoComplete="off"
                  type="text"
                  placeholder="Acceptance Number"
                  name="acceptanceOrderNo"
                  {...register("acceptanceOrderNo", { required: true })}
                  // onBlur={(e) => {
                  //   acceptanceNoValidationFunction(e);
                  // }}
                />
                <span className="d-block text-danger">
                  {errors.acceptanceOrderNo && "Acceptance Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="agreementNumber">
                  Agreement Number <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="agreementNumber"
                  autoComplete="off"
                  type="text"
                  placeholder="Agreement Number"
                  name="agreementNo"
                  {...register("agreementNo", { required: true })}
                  // onBlur={(e) => {
                  //   aggrementNoValidationFunction(e);
                  // }}
                />
                <span className="d-block text-danger">
                  {errors.agreementNo && "Agreement Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="workStartDate">
                  Period of work start date{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="workStartDate"
                  autoComplete="off"
                  type="date"
                  placeholder="Work start date"
                  name="workStartDate"
                  {...register("workStartDate", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.workStartDate && "Work start date is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="workEndDate">
                  Period of work end date <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="workEndDate"
                  autoComplete="off"
                  type="date"
                  placeholder="Work end date"
                  name="workEndDate"
                  {...register("workEndDate", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.workEndDate && "Work end date is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="12 mb-3">
                <Label className="form-label" for="workNature">
                  Nature of work <span className="text-danger">*</span>
                </Label>
                <textarea
                  className="form-control bg-light text-dark"
                  id="workNature"
                  autoComplete="off"
                  type="textarea"
                  placeholder="Nature of work"
                  name="natureOfWork"
                  rows="5"
                  {...register("natureOfWork", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.natureOfWork && "Nature of work is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isbuttonDisabled}
                >
                  Save and Next
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CaseCreationFormModal;
