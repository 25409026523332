const entitlementCode = localStorage.getItem("entitilement");
console.log(entitlementCode);

export const MENUITEMS = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },
];

export const MENU1 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard/default`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      {
        title: "Circle's",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/circle/creation`,
            type: "link",
            title: "Circle",
          },
          {
            path: `${process.env.PUBLIC_URL}/User/viewAll`,
            type: "link",
            title: "circle user",
          },
        ],
      },
      {
        title: "User's",
        icon: "user",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/internalUsers/viewAll`,
            type: "link",
            title: " Internal Users",
          },
          {
            path: `${process.env.PUBLIC_URL}/contractor/viewAll`,
            type: "link",
            title: "Contractor",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Linked",
    menucontent: "Ready to use linked",
    Items: [
      {
        title: "Linked-App",
        icon: "form",
        type: "sub",
        children: [
          {
            title: "Cloud",
            type: "link",
            path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
          },
          {
            title: "Productivity-Tracker",
            path: `${process.env.PUBLIC_URL}/table/datatable`,
            type: "link",
          },
        ],
      },
    ],
  },
];
export const MENU2 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      {
        title: "Circles",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/circle/creation`,
            type: "link",
            title: "Circle",
          },
          {
            path: `${process.env.PUBLIC_URL}/User/viewAll`,
            type: "link",
            title: "circle user",
          },
        ],
      },
      {
        title: "Users",
        icon: "user",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/internalUsers/viewAll`,
            type: "link",
            title: " Internal Users",
          },
          {
            path: `${process.env.PUBLIC_URL}/contractor/viewAll`,
            type: "link",
            title: "Contractor",
          },
        ],
      },

      {
        path: `${process.env.PUBLIC_URL}/case/submittedCase`,
        icon: "others",
        title: "Case Files",
        type: "link",
        active: true,
      },
      // {
      // 	path: `${process.env.PUBLIC_URL}/caseFlow`,
      // 	icon: "others",
      // 	title: "Case Flow",
      // 	type: "link",
      // 	active: true,
      // },
      {
        title: "Reports",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/datewiseReports`,
            type: "link",
            title: "Datewise",
          },
        ],
      },
    ],
  },
];
export const MENU3 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      // {
      //   title: "Master's",
      //   icon: "task",
      //   type: "sub",
      //   badge: "badge badge-light-secondary",
      //   badgetxt: "New",
      //   active: false,
      //   children: [

      //   ],
      // },
      {
        path: `${process.env.PUBLIC_URL}/case/viewAll`,
        icon: "others",
        title: "Case",
        type: "link",
      },
    ],
  },
];
export const MENU4 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      {
        title: "Circle's",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/circle/creation`,
            type: "link",
            title: "Circle",
          },
          {
            path: `${process.env.PUBLIC_URL}/User/viewAll`,
            type: "link",
            title: "circle user",
          },
        ],
      },
      {
        title: "User's",
        icon: "user",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/internalUsers/viewAll`,
            type: "link",
            title: " Internal Users",
          },
          {
            path: `${process.env.PUBLIC_URL}/contractor/viewAll`,
            type: "link",
            title: "Contractor",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/case/submittedCase`,
        icon: "others",
        title: "Case Files",
        type: "link",
      },
    ],
  },

  // {
  //   menutitle: "Linked",
  //   menucontent: "Ready to use linked",
  //   Items: [
  //     {
  //       title: "Linked-App",
  //       icon: "form",
  //       type: "sub",
  //       children: [
  //         {
  //           title: "Cloud",
  //           type: "link",
  //           path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
  //         },
  //         {
  //           title: "Productivity-Tracker",
  //           path: `${process.env.PUBLIC_URL}/table/datatable`,
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
export const MENU5 = [
  {
    menutitle: "Cloud Audit",
    // menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      {
        title: "Circle's",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/circle/creation`,
            type: "link",
            title: "Circle",
          },
          {
            path: `${process.env.PUBLIC_URL}/User/viewAll`,
            type: "link",
            title: "circle user",
          },
        ],
      },
      {
        title: "User's",
        icon: "user",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/internalUsers/viewAll`,
            type: "link",
            title: " Internal Users",
          },
          {
            path: `${process.env.PUBLIC_URL}/contractor/viewAll`,
            type: "link",
            title: "Contractor",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/case/submittedCase`,
        icon: "others",
        title: "Case Files",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/case/approvedCases`,
        icon: "others",
        title: "Approved Cases",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/case/rejectedCases`,
        icon: "others",
        title: "Rejected Cases",
        type: "link",
      },
    ],
  },

  // {
  //   menutitle: "Linked",
  //   menucontent: "Ready to use linked",
  //   Items: [
  //     {
  //       title: "Linked-App",
  //       icon: "form",
  //       type: "sub",
  //       children: [
  //         {
  //           title: "Cloud",
  //           type: "link",
  //           path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
  //         },
  //         {
  //           title: "Productivity-Tracker",
  //           path: `${process.env.PUBLIC_URL}/table/datatable`,
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
export const MENU6 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/case/submittedCase`,
        icon: "others",
        title: "Case Files",
        type: "link",
        active: true,
      },
      {
        path: `${process.env.PUBLIC_URL}/case/approvedCases`,
        icon: "others",
        title: "Approved Cases",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/case/rejectedCases`,
        icon: "others",
        title: "Rejected Cases",
        type: "link",
      },
    ],
  },
];
export const MENU7 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      // {
      // 	title: "Master's",
      // 	icon: "task",
      // 	type: "sub",
      // 	badge: "badge badge-light-secondary",
      // 	badgetxt: "New",
      // 	active: false,
      // 	children: [
      // 		{
      // 			path: `${process.env.PUBLIC_URL}/circle/creation`,
      // 			type: "link",
      // 			title: "Circle",
      // 		},
      // 		{
      // 			path: `${process.env.PUBLIC_URL}/user/viewAll`,
      // 			type: "link",
      // 			title: "Users",
      // 		},
      // 		{
      // 			path: `${process.env.PUBLIC_URL}/contractor/viewAll`,
      // 			type: "link",
      // 			title: "Contractor",
      // 		},
      // 	],
      // },
      {
        title: "PAYMENTS",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/invoice/verification`,
            icon: "others",
            title: "Verification",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/makePayments`,
            icon: "others",
            title: "Return",
            type: "link",
          },
        ],
      },

      {
        title: "INVOICE",
        icon: "task",
        type: "sub",
        badge: "badge badge-light-secondary",
        // badgetxt: "New",
        active: true,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/invoice/generation`,
            title: "Generation",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/invoice/downloads`,
            title: "Download",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/case/invoiceReports`,
            title: "Report",
            type: "link",
          },
        ],
      },
      // {
      // 	path: `${process.env.PUBLIC_URL}/case/rejectedCases`,
      // 	icon: "others",
      // 	title: "Rejected Cases",
      // 	type: "link",
      // },
    ],
  },

  // {
  //   menutitle: "Linked",
  //   menucontent: "Ready to use linked",
  //   Items: [
  //     {
  //       title: "Linked-App",
  //       icon: "form",
  //       type: "sub",
  //       children: [
  //         {
  //           title: "Cloud",
  //           type: "link",
  //           path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
  //         },
  //         {
  //           title: "Productivity-Tracker",
  //           path: `${process.env.PUBLIC_URL}/table/datatable`,
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const MENU8 = [
  {
    menutitle: "Cloud Audit",
    menucontent: "Dashboards",
    Items: [
      {
        // title: "Dashboard",
        // icon: "home",
        // type: "sub",
        // //badge: "badge badge-light-primary",
        // badgetxt: "5",
        // active: false,
        // children: [
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: "home",
        title: "Dashboard",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Menu",
    menucontent: "Ready to use Apps",
    Items: [
      // {
      //   title: "Master's",
      //   icon: "task",
      //   type: "sub",
      //   badge: "badge badge-light-secondary",
      //   badgetxt: "New",
      //   active: false,
      //   children: [

      //   ],
      // },
      {
        path: `${process.env.PUBLIC_URL}/masterCase/viewAll`,
        icon: "others",
        title: "Master Case",
        type: "link",
      },
    ],
  },
];
