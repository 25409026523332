import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import { useNavigate } from "react-router-dom";
import SearchBar from "../common/SearchBar";
// import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { Col, Card, CardBody, Form, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import { toast } from "react-toastify";
import { caseActions, circleActions, contractorActions } from "../../_services";
//import displayToastAlert from "../common/Toast";
import { Spinner } from "../../AbstractElements";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const UserComponent = (props) => {
  const entitlementCode = localStorage.getItem("entitilement");
  const navigate = useNavigate();
  const [loadingElement, setLoadingElement] = useState(false);
  const [Case, setCase] = useState([]);
  const [caseDetail, setcaseDetail] = useState([]);
  const [caseCreationDetail, setCaseCreationDetail] = useState({});
  const [transNum, setTransNum] = useState(null);
  const [svcDetails, setSvcDetails] = useState({});
  const [caseRemarks, setCaseRemarks] = useState([]);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("view");
  const [isViewClicked, setIsViewClicked] = useState(false);
  const modalTitle = "Case Details";
  const { searchText } = props;
  const toggle = () => setModal(!modal);
  let filteredData = null;
  let labourNumber = null;
  let columns = null;
  const dispatch = useDispatch();
  const [clearFilter, setClearFilter] = useState(false);
  let loadingContent;
  const loaderFunc = () => {
    // setTimeout(loaderFunc, 1000);
    setLoadingElement(false);
  };
  if (loadingElement) {
    loadingContent = (
      <div className="loader-box">
        <Spinner attrSpinner={{ className: "loader-2" }} />
      </div>
    );
  }
  const clearSearchFilter = () => {
    setClearFilter(!clearFilter);
  };

  const handleSearch = (startDate, endDate) => {
    console.log("I am in search button");
    const SearchbarRequest = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(caseActions.filteredCase(SearchbarRequest)).then((data) => {
      if (data.payload) {
        const svcProviderCases = data.payload;
        console.log("ServiceProviderCases...............", data.payload);
        const filterCaseWithCircleName = [];
        const casesWithContractorName = [];
        dispatch(circleActions.getAllCircle()).then((data) => {
          console.log("circles data....", data);
          for (let eachCase of svcProviderCases) {
            const orgLocationId = eachCase.org_location_id;
            for (let eachCircle of data.payload) {
              if (orgLocationId === eachCircle.id) {
                const newCase = {
                  ...eachCase,
                  name: eachCircle.name,
                };
                filterCaseWithCircleName.push(newCase);
                console.log("circle Names....", filterCaseWithCircleName);
              }
            }
          }
        });
        dispatch(contractorActions.getAllContractor()).then((data) => {
          console.log("svc details....", data);
          for (let eachCase of filterCaseWithCircleName) {
            const serviceProviderId = eachCase.svc_provider_id;
            for (let eachServiceProvider of data.payload) {
              const { id, establishment_name } = eachServiceProvider;
              if (id === serviceProviderId) {
                const newCase = {
                  ...eachCase,
                  establishmentName: establishment_name,
                };
                casesWithContractorName.push(newCase);
              }
            }
          }
          console.log("Case......>", casesWithContractorName);
          setCase(casesWithContractorName);
        });
      }
    });
  };

  useEffect(() => {
    setLoadingElement(true);
    const casesWithCircleName = [];
    const casesWithContractorName = [];
    const getdata = async () => {
      await dispatch(caseActions.getAllCase()).then(async (data) => {
        if (data.payload) {
          const initialCase = data.payload;
          console.log("initial case", initialCase);
          if (entitlementCode === "AU001" || entitlementCode === "AA001") {
            filteredData = initialCase.filter((caseObject) => {
              if (
                caseObject.case_status.svc_provider_status === "AUDIT CLEARED"
              ) {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "AC001") {
            filteredData = initialCase.filter((caseObject) => {
              if (caseObject.case_status.Accounts_Status === "SUCCESS") {
                return true;
              }
              return false;
            });
          }
          console.log("filtered Data...", filteredData);
          await dispatch(circleActions.getAllCircle()).then((data) => {
            console.log("circles data....", data);
            for (let eachCase of filteredData) {
              const orgLocationId = eachCase.org_location_id;
              for (let eachCircle of data.payload) {
                if (orgLocationId === eachCircle.id) {
                  const newCase = {
                    ...eachCase,
                    name: eachCircle.name,
                  };
                  casesWithCircleName.push(newCase);
                  console.log("circle Names....", casesWithCircleName);
                }
              }
            }
          });
          await dispatch(contractorActions.getAllContractor()).then((data) => {
            console.log("svc details....", data);
            for (let eachCase of casesWithCircleName) {
              const serviceProviderId = eachCase.svc_provider_id;
              for (let eachServiceProvider of data.payload) {
                const { id, establishment_name, gst_number } =
                  eachServiceProvider;
                if (id === serviceProviderId) {
                  const newCase = {
                    ...eachCase,
                    establishmentName: establishment_name,
                    gstNumber: gst_number,
                  };
                  casesWithContractorName.push(newCase);
                }
              }
            }
            console.log(
              "Cases with contractor Name.......",
              casesWithContractorName
            );
            setCase(casesWithContractorName);
            if (data) {
              setTimeout(loaderFunc, 1000);
            }
          });
        }
      });
    };
    getdata();
  }, [clearFilter]);
  console.log("case -->", Case);

  const handleViewClick = (caseId) => {
    console.log("caseId...", caseId);
    // e.preventDefault();
    console.log("caseId in view click...", caseId);
    setType("view");
    dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
      if (data.payload) {
        console.log("user details response....", data.payload);
        setcaseDetail(data.payload);
      }
      dispatch(caseActions.getCaseById(caseId)).then((data) => {
        console.log("case creation response....", data);
        const { svc_provider_id, org_location_id } = data.payload;
        console.log("svc id", svc_provider_id);
        dispatch(contractorActions.getContractorById(svc_provider_id)).then(
          (data) => {
            const { establishment_name, epf_number, esi_number, gst_number } =
              data.payload[0];
            const { contact_detail } = data.payload[1][0];
            const orgSvcMap = data.payload[2];
            labourNumber = orgSvcMap.filter((labour_number) => {
              if (labour_number.org_location_id === org_location_id) {
                return true;
              }
              return false;
            });
            const svcData = {
              establishmentName: establishment_name,
              epfNumber: epf_number,
              esiNumber: esi_number,
              gstNumber: gst_number,
              labourNumber: labourNumber[0].labour_number,
              emailId: contact_detail,
            };
            setSvcDetails(svcData);
          }
        );
        setCaseCreationDetail(data.payload);
      });
    });
    dispatch(caseActions.getCaseRemarks(caseId)).then((data) => {
      setCaseRemarks(data.payload);
    });
    toggle();
    setIsViewClicked(true);
  };

  const ShowFilteredCases = (e) => {
    e.preventDefault();
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;

    if (startDate == "" && endDate == "") {
      toast.error("Please select the value!");
      /* Toast.fire({
        text: "please select the value!",
        icon: "error",
      }); */
      //displayToastAlert("error", "please select the value!");
    }
  };
  const handleDocumentDownload = async (caseId, caseid, docType) => {
    console.log("Row", caseId);
    console.log("caseId", caseid);
    const documentType = docType;
    try {
      await dispatch(
        caseActions.getDocumentPath({ caseId, documentType })
      ).then(async (data) => {
        console.log("response. redux....", data);
        const { document_path, document_name } = data.payload;
        console.log("document_path", document_path);
        const filePath = document_path;
        const documentName = document_name;

        const fetchResponse = await fetch(filePath);
        const blob = await fetchResponse.blob();

        // Create a temporary link and trigger download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // Set the correct Content-Type header for PDF
        link.type = "application/pdf";

        if (docType === "invoice") {
          link.download = `${caseid}_Invoice.pdf`;
        } else {
          link.download = `${caseid}_Certificate.pdf`;
        }
        link.click();
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // const eyeIconFormatter = (id) => (
  // 	<span onClick={(e) => handleViewClick(e, id)}>
  // 		<i className="fa fa-eye"></i>
  // 	</span>
  // );
  const rowEvents = {
    // onClick: (e, row, rowIndex) => {
    // 	handleViewClick(e, row.id);
    // },
    onMouseEnter: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "pointer";
    },
    onMouseLeave: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "default";
    },
  };

  // // const invoiceValue = (row) => {
  //   const demo =
  //     `INVOICE NUMBER :${row.invoice_detail.id}\n` +
  //     `INVOICE DATE    : 08/07/2023 \n` +
  //     `CONTRACTOR     :${row.establishmentName}\n` +
  //     `GSTIN        :${row.gstNumber}\n` +
  //     `GROSS          :${row.invoice_detail.gross_amount}\n` +
  //     `GST            :${row.invoice_detail.cgst_amount}\n` +
  //     `NET AMOUNT     :${row.invoice_detail.net_amount}\n`;
  //   displayWarningAlert(demo, row);
  // };

  const displayWarningAlert = async (row) => {
    console.log("row-------->", row);
    let invoiceNumber = "";
    let invoice_date = new Date().toJSON().slice(0, 10);
    let invoiceDate = moment(invoice_date).format("DD/MM/YYYY");
    await dispatch(caseActions.caseInvoiceNumber(row.id)).then((data) => {
      console.log("first", data.payload.invoice_number);
      invoiceNumber = data.payload.invoice_number;
    });
    SweetAlert.fire({
      title: `Invoice Details`,

      // html:
      //   `<p> <b> INVOICE NUMBER : </b>${transaction_number}<br></p>` +
      //   `<p> <b>INVOICE DATE   : </b> ${new Date().toISOString()} <br> </p>` +
      //   `<p> <b>CONTRACTOR     : </b>${row.establishmentName}<br></p>` +
      //   `<p><b> GSTIN          : </b>${row.gstNumber}<br> </p>` +
      //   `<p><b>GROSS          : </b>${row.invoice_detail.gross_amount}<br> </p>` +
      //   `<p><b>GST            : </b>${
      //     Number(row.invoice_detail.cgst_amount) +
      //     Number(row.invoice_detail.sgst_amount)
      //   }<br> </p>` +
      //   `<p><b>NET AMOUNT     : </b>${row.invoice_detail.net_amount}<br> </p>`,
      html: `
      <style>
table{
    display: block;
    padding-left: 72px;
    font-size: 14px;
  }
</style>
      <table style="text-align:left";font-size:"10px" id="tableStyle" >
          <tr>
            <th>INVOICE NUMBER</th>
            <td style="padding:7px">:</td>
            <td>${invoiceNumber}</td>
          </tr>
          <tr>
            <th>INVOICE DATE</th>
            <td style="padding:7px">:</td>
            <td>${invoiceDate}</td>
          </tr>
          <tr>
            <th>CONTRACTOR </th>
            <td style="padding:7px">:</td>
            <td>${row.establishmentName}</td>
          </tr>
             <tr>
            <th>GSTIN </th>
            <td style="padding:7px">:</td>
            <td>${row.gstNumber}</td>
          </tr>
             <tr>
            <th>GROSS AMOUNT(₹)  </th>
            <td style="padding:7px">:</td>
            <td>${row.invoice_detail.gross_amount}</td>
          </tr>
          <tr>
            <th>GST(₹)  </th>
            <td style="padding:7px">:</td>
            <td>${
              Number(row.invoice_detail.cgst_amount) +
              Number(row.invoice_detail.sgst_amount) +
              Number(row.invoice_detail.igst_amount)
            }
          </tr>
          <tr>
            <th>NET AMOUNT(₹)   </th>
            <td style="padding:7px">:</td>
            <td>${row.invoice_detail.net_amount}</td>
          </tr>
        </table>`,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Generate",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        // new route for email;
        setLoadingElement(true);
        const caseId = row.id;
        const documentType = "invoice";
        dispatch(
          caseActions.invoiceEmailTrigger({ caseId, documentType })
        ).then((data) => {
          if (data.payload) {
            console.log("Email Sent successfully");
            const reqBody = {
              accountsStatus: "INVOICE GENERATED",
            };
            const caseId = row.id;
            dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then(
              (data) => {
                if (data) {
                  setTimeout(loaderFunc, 1000);
                }
                console.log("======->", data);
                if (data.payload) {
                  toast.success("Invoice generated successfully");
                  navigate(`${process.env.PUBLIC_URL}/case/approvedCases`);
                }
              }
            );
          } else {
            console.log("Email Not sent successfully");
          }
        });
      } else {
        // DisplayApprovalAlert("rejection-failure");
        // toast.error("rejection-failure");
      }
    });
  };

  const handleInvoice = (e, row) => {
    setLoadingElement(true);

    const invoiceGenDetails = {
      caseId: row.id,
      pdfType: "invoice",
    };
    dispatch(caseActions.generateInvoiceDocument(invoiceGenDetails)).then(
      (data) => {
        if (data) {
          setTimeout(loaderFunc, 1000);
        }
        console.log(`Invoice generated response....`, data);
        displayWarningAlert(row);
      }
    );
  };

  const caseIdFormatter = (cell, row, rowIndex) => {
    return (
      <a
        style={{ textDecoration: "underline", color: "rgb(13, 110, 253)" }}
        onClick={() => handleViewClick(row.id)}
      >
        {row.case_id}
      </a>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with leading zeros if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month (months are zero-based) and pad with leading zeros if necessary
    const year = date.getFullYear().toString(); // Get the full year

    return `${day}/${month}/${year}`; // Format the date as "dd/mm/yyyy"
  };
  if (entitlementCode === "AU001" || entitlementCode === "AA001") {
    columns = [
      {
        text: "S.No",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
        formatter: caseIdFormatter,
      },
      {
        dataField: "createdAt",
        text: "Created Date",
        filter: dateFilter({
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "name",
        text: "Circle",
        filter: textFilter({
          style: { padding: "3px 10px ", border: "none", borderRadius: "3px" },
          placeholder: "Circle...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },

      {
        dataField: "action",
        text: "Action",
        formatter: (cell, row) => {
          return (
            <Button
              size="lg"
              variant="transparent"
              onClick={() =>
                handleDocumentDownload(row.id, row.case_id, "certificate")
              }
            >
              <i className="fa fa-download text-primary" />
            </Button>
          );
        },
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
    ];
  } else if (entitlementCode === "AC001") {
    columns = [
      {
        text: "S.No",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
        formatter: caseIdFormatter,
      },
      {
        dataField: "createdAt",
        text: "Created Date",
        filter: dateFilter({
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "invoice_detail.payment_ref_no",
        text: "Pay Ref Number",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Pay Ref Number ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "case_status.auditor_admin_status",
        text: "Admin Status",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Status ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "9vw",
        },
        formatter: (cell, row) => {
          if (row.case_status.auditor_admin_status === "APPROVED") {
            return (
              <span className="badge bg-success">
                {row.case_status.auditor_admin_status}
              </span>
            );
          } else if (row.case_status.auditor_admin_status === "PENDING") {
            return (
              <span className="badge bg-light txt-dark">
                {row.case_status.auditor_admin_status}
              </span>
            );
          } else {
            return (
              <span className="badge bg-danger">
                {row.case_status.auditor_admin_status}
              </span>
            );
          }
        },
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (cell, row) => {
          if (
            row.case_status.Accounts_Status === "SUCCESS" &&
            entitlementCode === "AC001"
          ) {
            return (
              <>
                <div className="d-flex flex-column justify-content-evenly">
                  <Button
                    variant="success"
                    size="sm"
                    onClick={(e) => handleInvoice(e, row)}
                    className="mb-2"
                  >
                    Invoice
                  </Button>
                </div>
              </>
            );
          } else if (
            row.case_status.Accounts_Status === "INVOICE GENERATED" &&
            entitlementCode === "AC001"
          ) {
            return (
              <>
                <Button
                  size="lg"
                  variant="transparent"
                  onClick={() =>
                    handleDocumentDownload(
                      row.id,
                      row.case_certificate_invoice_map_model.invoice_number,
                      "invoice"
                    )
                  }
                >
                  <i className="fa fa-download text-primary" />
                </Button>
              </>
            );
          }
        },
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
    ];
  }
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "All",
        value: Case.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={(e) => ShowFilteredCases(e)}>
        <Col sm="12">
          <Card>
            {/* <HeaderCard title={TaskDetails} /> */}
            <CardBody className="grid-showcase grid-align">
              <SearchBar
                clearSearchFilter={clearSearchFilter}
                handleSearch={handleSearch}
              />
              {/* <SearchButton /> */}
            </CardBody>
          </Card>
        </Col>
        <Row>
          <Col>
            {loadingElement ? (
              loadingContent
            ) : (
              <BootstrapTable
                keyField="id"
                data={Case}
                columns={columns}
                headerClasses="header-class"
                filter={filterFactory()}
                hover
                // selectRow={selectRow}
                rowEvents={rowEvents}
                pagination={paginationFactory(options)}
              />
            )}
          </Col>
        </Row>
      </Form>
      {modal ? (
        <ModalForm
          modal={modal}
          type={type}
          modalTitle={modalTitle}
          value={caseDetail}
          toggle={toggle}
          caseDetail={caseDetail}
          caseCreationDetail={caseCreationDetail}
          caseRemarksDetails={caseRemarks}
          isViewClicked={isViewClicked}
          svcDetails={svcDetails}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default UserComponent;
