import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardBody, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { circleActions, contractorActions, userActions } from "../../_services";

const EditUser = ({ toggle, value, userUpdatedToggle }) => {
  console.log("value----->", value);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const id = value[0].id;
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [role, setRole] = useState("");
  const [circle, setCircle] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  let existMobileNo = value[0].mobile_number;

  const entitlementCode = localStorage.getItem("entitilement");

  console.log("selectedOptions", selectedOptions);

  const handleEdit = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    const getLocation = async () => {
      await dispatch(circleActions.getCircleNames()).then((data) => {
        console.log("circle name id in dit form--->", data);
        if (data.payload) {
          console.log(data);
          setOptions(data.payload);
        }
      });
    };
    if (value[0].role === "CIRCLE ADMIN") {
      let id = value[0].circle_id;
      console.log(id);
      dispatch(circleActions.getCircleById(id)).then((data) => {
        console.log("response data circle redux...>", data);
        if (data.payload) {
          const { name } = data.payload;
          setCircle(name);
        } else {
          console.log("error", data.payload);
        }
      });
    }
    console.log("circle--->", circle);
    getLocation();
    setValue("fName", value[0].first_name);
    setValue("lName", value[0].last_name);
    setValue("emailId", value[0].email_id);
    setValue("mbNo", value[0].mobile_number);
    setValue("Desigination", value[0].designation);
    setValue("role", value[0].role);
    setValue("circle", value[0].circle_id);
  }, [value, setValue]);
  console.log("circle--->", circle);

  const Displayalert = () => {
    toast.success("User updated successfully!");
    /* Toast.fire({
      text: "User updated successfully",
      icon: "success",
    }); */
  };

  const handleCircleChange = (event) => {
    console.log("circle id", event.target.value);
    if (event.target.value) {
      dispatch(circleActions.getCircleById(event.target.value)).then((data) => {
        console.log("data from circle user------->", data);
        if (data) {
          setValue("emailId", data.payload.email_id);
        }
      });
    } else {
      toast.error("Please select the circle");

      setValue("emailId", "");
    }
  };
  const mobileNumberValidation = async (e) => {
    const validation = e.target.value;
    if (existMobileNo != validation) {
      await dispatch(
        contractorActions.contactDetailsValidation(validation)
      ).then((response) => {
        console.log(response);
        if (response.payload === "Already exist") {
          console.log("no value");
          setValue("mbNo", "");
          toast.warn("Mobile Number already exist");
          /* Toast.fire({
          text: "Mobile number already exists",
          icon: "error",
        }); */
        } else {
          console.log("something went wrong");
        }
      });
    }
  };

  const onSubmit = (data) => {
    console.log("data -->", data);
    if (data) {
      let circleVal = Number(data.circle);
      const reqBody = {
        first_name: data.fName.toUpperCase(),
        last_name: data.lName.toUpperCase(),
        mobile_number: data.mbNo,
        email_id: data.emailId.toUpperCase(),
        designation: data.Desigination.toUpperCase(),
        primaryRoles: data.role.toUpperCase(),
        user_name: data.emailId,
        password: data.emailId.slice(0, 4),
        is_primary: true,
        circle_id: circleVal,
      };
      console.log("user info -->", reqBody);
      dispatch(userActions.updateUserById({ id, reqBody })).then((data) => {
        console.log("update user redux", data);
        if (data.type === "/UpdateUserById/fulfilled") {
          userUpdatedToggle();
          Displayalert();
          toggle();
        }
      });
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom01">
                  First Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark"
                  id="validationCustom01"
                  autoComplete="off"
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  disabled={!isEditMode}
                  {...register("fName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.fName && "First name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom02">
                  Last Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark"
                  id="validationCustom02"
                  autoComplete="off"
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  disabled={!isEditMode}
                  {...register("lName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.lName && "Last Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom06">
                  Roles<span className="text-danger">*</span>
                </Label>
                <select
                  className="form-control  bg-light text-dark"
                  id="validationCustom04"
                  autoComplete="off"
                  name="role"
                  disabled={value.role === "CIRCLE ADMIN" ? true : !isEditMode}
                  {...register("role")}
                >
                  <option value="">Choose Circle </option>
                  <option value="CIRCLE ADMIN">CIRCLE ADMIN </option>
                </select>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom04">
                  Mobile Number(User ID)<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark b-secondary"
                  id="validationCustom04"
                  autoComplete="off"
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Mobile Number"
                  name="mbNo"
                  maxlength="10"
                  disabled="true"
                  {...register("mbNo", { required: true })}
                  onBlur={(e) => {
                    mobileNumberValidation(e);
                  }}
                />
                <span className="d-block text-danger">
                  {errors.mbNo && "Mobile number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom05">
                  Desigination<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark"
                  id="validationCustom05"
                  autoComplete="off"
                  name="Desigination"
                  type="text"
                  placeholder="Desigination"
                  disabled={!isEditMode}
                  {...register("Desigination", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.Desigination && "Desigination is required."}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <>
                  <Label className="form-label" for="validationCustom08">
                    Circle <span className="text-danger">*</span>
                  </Label>
                  <select
                    className="form-select  bg-light text-dark"
                    id="validationCustom08"
                    autoComplete="off"
                    disabled={!isEditMode}
                    name="circle"
                    {...register("circle", { required: true })}
                    onChange={(e) => handleCircleChange(e)}
                  >
                    <option selected value={value.id}>
                      {circle}
                    </option>
                    {options.map((eachCircle) => (
                      <option key={eachCircle.value} value={eachCircle.value}>
                        {eachCircle.label}
                      </option>
                    ))}
                  </select>
                  <span className="d-block text-danger">
                    {errors.circle && "circle  is required"}
                  </span>
                </>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom03">
                  Email id<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark b-secondary"
                  id="validationCustom03"
                  autoComplete="off"
                  type="email"
                  placeholder="Email id"
                  name="emailId"
                  disabled="true"
                  {...register("emailId", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.emailId && "Email Id  is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            {isEditMode &&
            (entitlementCode === "AG001" ||
              entitlementCode === "ORG001" ||
              entitlementCode === "AA001") ? (
              <>
                <Button
                  color="secondary"
                  className="me-3"
                  onClick={() => setIsEditMode(false)}
                >
                  Cancel
                </Button>
                <Button color="primary">{"Update"}</Button>
              </>
            ) : entitlementCode === "AG001" ||
              entitlementCode === "ORG001" ||
              entitlementCode === "AA001" ? (
              <Button color="primary" onClick={handleEdit}>
                Edit
              </Button>
            ) : (
              ""
            )}
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EditUser;
