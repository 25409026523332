import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "Validation";
console.log("counterss");
function createInitialState() {
  console.log("1");
  return {
    Validation: [],
    error: null,
    loader: false,
  };
}

function createReducers() {
  function toggleAuthPopup(state, { payload }) {
    state.checkPopup = payload;
  }
  function updateAuthMessage(state, { payload }) {
    state.unAuthMessage = payload;
  }
  return {
    toggleAuthPopup,
    updateAuthMessage,
  };
}

function createExtraActions() {
  console.log("2");
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  function mailVAlidation() {
    return createAsyncThunk(
      `/mailValidation`,
      async (mailId) =>
        await fetchAPIWapper.get(
          `${baseUrl}/validation/mailId?mailId=${mailId}`
        )
    );
  }

  function usernameValidation() {
    return createAsyncThunk(
      `/usernameValidation`,
      async (name) =>
        await fetchAPIWapper.get(
          `${baseUrl}/validation/username?username=${name}`
        )
    );
  }

  return {
    mailVAlidation: mailVAlidation(),
    usernameValidation: usernameValidation(),
  };
}

function createExtraReducers() {
  console.log("7");
  function mailVAlidation() {
    let { pending, fulfilled, rejected } = extraActions.mailVAlidation;

    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("mailVAlidation _service--> " + action.payload);
        state.Reports = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }

  function usernameValidation() {
    let { pending, fulfilled, rejected } = extraActions.mailVAlidation;

    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("usernameValidation _service--> " + action.payload);
        state.Reports = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }

  return {
    ...mailVAlidation(),
    ...usernameValidation(),
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const validationActions = { ...slice.actions, ...extraActions };
export const validationReducer = slice.reducer;
