import React, { Fragment, useState, useContext } from "react";
import { Btn, H4, P, H6, Image } from "../../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { Facebook, Linkedin, Twitter } from "react-feather";

import { useNavigate } from "react-router-dom";

import CustomizerContext from "../../_helper/Customizer";

import { ToastContainer, toast } from "react-toastify";

import logoWhite from "../../assets/images/logo/pinakin.png";
import logoDark from "../../assets/images/logo/pinakin.png";
import { doLogin } from "../index";
import { loginUser } from "../../service/user-service";
import { useDispatch } from "react-redux";
import { authActions } from "../../_services";

const LoginForm = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);

  const { layoutURL } = useContext(CustomizerContext);

  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });
  const dispatch = useDispatch();
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setLoginDetail({
      ...loginDetail,
      [field]: actualValue,
    });
  };

  const history = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (
      loginDetail.username.trim() === "" ||
      loginDetail.password.trim() === ""
    ) {
      toast.error("Username Or Password Required!");
      return;
    }
    dispatch(authActions.login(loginDetail)).then((data) => {
      console.log(" ---------->", data);
      if (data.payload.token) {
        toast.success("Login Successful");
        history(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        toast.warn("Login Credential Wrong Give Correct Data!");
        localStorage.removeItem("data");
      }
    });
    // loginUser(loginDetail).then((data) => {
    //   console.log("login --->", data);
    //   if (data.data.token) {
    //     doLogin(data, () => {});
    //   } else {
    //   }
    // });
  };

  return (
    <Fragment>
      <style>{`
			.img-fluid-login-logo-ai {
				max-width: 100px !important;
			}
		`}</style>
      <div className="login-card">
        <div>
          <div>
            <Link
              className={`logo ${logoClassMain ? logoClassMain : ""}`}
              to={process.env.PUBLIC_URL}
            >
              <Image
                attrImage={{
                  className: "img-fluid for-light img-fluid-login-logo-ai",
                  src: logoWhite,
                  alt: "loginpage",
                }}
              />
              <Image
                attrImage={{
                  className: "img-fluid for-dark img-fluid-login-logo-ai",
                  src: logoDark,
                  alt: "loginpage",
                }}
              />
            </Link>
          </div>

          <div className="login-main">
            <Form className="theme-form login-form" onSubmit={handleFormSubmit}>
              <H4>Sign in to account</H4>
              <P>Enter your username and password to login</P>
              <FormGroup>
                <Label className="col-form-label m-0">Username</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Username"
                  autoComplete="off"
                  id="username"
                  onChange={(e) => handleChange(e, "username")}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label m-0">Password</Label>
                <div className="position-relative">
                  <Input
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    name="login[password]"
                    placeholder="Password"
                    autoComplete="off"
                    onChange={(e) => handleChange(e, "password")}
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    <span className={togglePassword ? "" : "show"}></span>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="position-relative">
                {/* <div className="checkbox">
                  <Input id="checkbox1" type="checkbox" />
                  <Label className="text-muted" for="checkbox1">
                    Remember password
                  </Label>
                </div>
                <Link
                  className="link"
                  to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`}
                >
                  Forgot password?
                </Link> */}
              </FormGroup>
              <FormGroup>
                <Btn
                  attrBtn={{
                    className: "d-block w-100 mt-2",
                    color: "primary",
                    type: "submit",
                    id: "login",
                  }}
                >
                  Sign in
                </Btn>
              </FormGroup>
              {/* <div className="login-social-title">
                <H6 attrH6={{ className: "text-muted mt-4 or" }}>
                  Or Sign in with
                </H6>
              </div> */}

              {/* <div className="social my-4 ">
                <div className="btn-showcase">
                  <a
                    className="btn btn-light"
                    href="https://www.linkedin.com/login"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Linkedin className="txt-linkedin" /> LinkedIn
                  </a>
                  <a
                    className="btn btn-light"
                    href="https://twitter.com/login?lang=en"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Twitter className="txt-twitter" />
                    twitter
                  </a>
                  <a
                    className="btn btn-light"
                    href="https://www.facebook.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Facebook className="txt-fb" />
                    facebook
                  </a>
                </div>
              </div> */}

              {/* <P attrPara={{ className: "text-center mb-0 " }}>
                Don't have account?
                <Link
                  className="ms-2"
                  to={`${process.env.PUBLIC_URL}/pages/authentication/register-simple`}
                >
                  Create Account
                </Link>
              </P> */}
            </Form>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </Fragment>
  );
};

export default LoginForm;
