import React, { Fragment, useState,useEffect } from "react";
import { Col, Card, CardBody, Form, Label, Row } from "reactstrap";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CaseDocumentViewPage from "./caseDocumentViewPage";
import { caseActions } from "../../_services";
/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */


const CaseUploadForm = ({
  toggle,
  caseDetailsId,
  uploadedDocuments,
  isEditDocuments,
}) => {
  const caseId = localStorage.getItem("caseId");
  console.log("case details Id.....", caseDetailsId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [loadingElement, setLoadingElement] = useState(false);
  const [fileMappingsObject, setFileMappingsObject] = useState({});
  const [fileObjectsArray, setFileObjectsArray] = useState([]);

  const fileNames = [
    "application",
    "labourLicenseCopy",
    "attendanceRegister",
    "wagesCopy",
    "epfPayment",
    "epfChallan",
    "epfEcrChallan",
    "esiTranscationChallan",
    "esiMonthlyDetail",
    "otherDocument",
  ];

  useEffect(() => {
    console.log("file Mapping Object from useEffect", fileMappingsObject);
    
    const orderedFileNames = fileNames
      .map((fileName) => fileMappingsObject[fileName])
      .filter(Boolean);

    setFileObjectsArray(orderedFileNames);
    console.log("file objects Array", orderedFileNames);
  }, [fileMappingsObject]);

  const handleChange = (event) => {
    const newFile = event.target.files[0];
    const eventName = event.target.name;

    setFileMappingsObject(prevState => ({
      ...prevState,
      [eventName]: newFile
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const handleCancelEdit = () => {
    toggle();
  };
  const handleUpdateDocuments = () => {
    const reqBody = {
      isActive: false,
    };
    dispatch(
      caseActions.updateCaseDocumentById({ caseDetailsId, reqBody })
    ).then((data) => {
      // console.log("response.....", data);
    });
  };

  const onSubmit = async (data) => {
    setLoadingElement(true);
    if (isEditDocuments) {
      handleUpdateDocuments();
    }
    if (data !== "") {
      setDisabled(true);
      const formData = new FormData();
      formData.append("caseId", caseId);
      formData.append("caseDetailsId", caseDetailsId);
      console.log(`files in onSubmit function....${fileObjectsArray}`);
      fileObjectsArray.forEach((file, index) => {
        console.log(file);
        formData.append(`file`, file);
      });
      dispatch(caseActions.uploadCaseDocuments(formData)).then((data) => {
        console.log("data from redux", data);
        if (data.payload === "Success") {
          // localStorage.removeItem("caseId");
          localStorage.removeItem("caseDetailsId");
          toast.success("Documents Uploaded Successfully !");
          // Displayalert();
          reset();
          navigate(`${process.env.PUBLIC_URL}/case/details`);
          toggle();
        }
      });
    } else {
      errors.showMessages();
    }
  };

  return uploadedDocuments.length !== 0 ? (
    <CaseDocumentViewPage
      uploadedDocuments={uploadedDocuments}
      caseDetailsId={caseDetailsId}
    />
  ) : (
    <Fragment>
      <Card>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="application">
                  Application <span className="text-danger">*</span>{" "}
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="application"
                  autoComplete="off"
                  type="file"
                  name="application"
                  {...register("application", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.application && "Application is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="labourLicenseCopy">
                  Labour License Copy <span className="text-danger">*</span>{" "}
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="labourLicenseCopy"
                  autoComplete="off"
                  type="file"
                  name="labourLicenseCopy"
                  {...register("labourLicenseCopy", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.labourLicenseCopy &&
                    "Labour License Copy is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="attendanceRegister">
                  Attendance Register <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="attendanceRegister"
                  autoComplete="off"
                  type="file"
                  name="attendanceRegister"
                  {...register("attendanceRegister", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.attendanceRegister &&
                    "Attendance register is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="wagesCopy">
                  Wages Register/Slip/Bank Remitance Copy{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="wagesCopy"
                  autoComplete="off"
                  type="file"
                  name="wagesCopy"
                  {...register("wagesCopy", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.wagesCopy && "Wages Copy is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="epfPayment">
                  EPF Payment Confirmation Report{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="epfPayment"
                  autoComplete="off"
                  type="file"
                  name="epfPayment"
                  {...register("epfPayment", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.epfPayment && "EPF Payment Confirmation is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="epfChallan">
                  EPF Combined Challan <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="epfChallan"
                  autoComplete="off"
                  type="file"
                  name="epfChallan"
                  {...register("epfChallan", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.epfChallan && "EPF Challan is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="epfEcrChallan">
                  EPF ECR Electronic Challan cum Return{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="epfEcrChallan"
                  autoComplete="off"
                  type="file"
                  name="epfEcrChallan"
                  {...register("epfEcrChallan", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.epfEcrChallan && "EPF ECR Challan is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="esiTranscationChallan">
                  ESI Transaction Details Challan{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="esiTranscationChallan"
                  autoComplete="off"
                  type="file"
                  name="esiTranscationChallan"
                  {...register("esiTranscationChallan", { required: true })}
                  onChange={handleChange}
                  style={{ height: "70px" }}
                />
                <span className="d-block text-danger">
                  {errors.esiTranscationChallan &&
                    "ESI Transaction Details Challan is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="esiMonthlyDetail">
                  ESI Monthly Contribution Detail{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="esiMonthlyDetail"
                  autoComplete="off"
                  type="file"
                  name="esiMonthlyDetail"
                  {...register("esiMonthlyDetail", { required: true })}
                  onChange={handleChange}
                  style={{
                    height: "70px",
                  }}
                />
                <span className="d-block text-danger">
                  {errors.esiMonthlyDetail &&
                    "ESI monthly details are required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col className="mb-3">
                <Label className="form-label" for="otherDocument">
                  Other Documents
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="otherDocument"
                  autoComplete="off"
                  type="file"
                  name="otherDocument"
                  {...register("otherDocument", { required: false })}
                  onChange={handleChange}
                  style={{ height: "70px", textAlign: "center" }}
                />

                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {isEditDocuments ? (
                  <>
                    <Button
                      variant="danger"
                      size="md"
                      className="me-3"
                      onClick={handleCancelEdit}
                      disabled={disabled}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      disabled={disabled}
                    >
                      Update
                    </Button>
                  </>
                ) : (
                  <Button variant="primary" type="submit" disabled={disabled}>
                    Save
                  </Button>
                )}
                {loadingElement && <p>Uploading Documents Please Wait...</p>}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CaseUploadForm;
