import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";

const CaseDocumentViewPage = (props) => {
	const { viewCaseId, uploadedDocuments, caseDetailsId } = props;
	console.log("view documents...", viewCaseId);
	console.log("view Case Id...", viewCaseId);
	const [documentDetails, setDocumentDetails] = useState([]);
	const [modal, setModal] = useState(false);
	const [modalTitle, setModalTitle] = useState("Edit Uploaded Documents");
	const toggle = () => setModal(!modal);
	const dispatch = useDispatch();
	const [isEditDocuments, setIsEditDocuments] = useState(false);
	const [editDocument, setEditDocument] = useState(false);
	const [size, setSize] = useState("lg");
	const [documentId, setDocumentId] = useState();
	const documentTypes = [
		"Application",
		"Labour License Copy",
		"Attendance Register",
		"Wages Register",
		"EPF Payment Confirmation Receipt",
		"EPF Combined Challan",
		"EPF ECR Electronic Challan cum Return",
		"ESI Transaction Details Challan",
		"ESI Monthly Contribution Detail",
		"Other Documents",
	];

	useEffect(() => {
		if (uploadedDocuments === undefined) {
			dispatch(caseActions.getAllCaseDocuments(viewCaseId)).then((data) => {
				if (data.payload) {
					console.log("response data....", data);
					setDocumentDetails(data.payload);
				}
			});
		} else if (uploadedDocuments.length !== 0) {
			setDocumentDetails(uploadedDocuments);
		}
	}, [uploadedDocuments]);

	const handleViewAndDownloadDocument = async (
		filePath,
		type,
		documentName
	) => {
		console.log("file Path....", filePath);
		// dispatch(retriveCaseDocumentById(filePath)).then((response) => {
		try {
			if (type === "view") {
				// console.log("Response..............", response);
				// console.log("Response.data.............", response.data);
				// const fileURL = URL.createObjectURL(response.data);
				// const fileURL = filePath;
				// window.open(fileURL);
				// const newTab = window.open();
				// newTab.document.open();
				// newTab.document.write(`
				// 		  <html>
				// 		    <body>
				// 		      <embed src="${filePath}" height="100%" width="100%" type="application/pdf"/>
				// 		    </body>
				// 		  </html>
				// 		`);
				// newTab.document.close();
				const newTab = window.open();
				newTab.document.open();
				newTab.document.write(`
          		  <html>
          		    <body>
          		      <embed src="${filePath}" height="100%" width="100%" type="application/pdf"/>
          		    </body>
          		  </html>
          		`);
				newTab.document.close();
			} else if (type === "download") {
				// const blob = new Blob([response.data], { type: "application/pdf" });
				// const url = URL.createObjectURL(blob);
				// const link = document.createElement("a");
				// link.href = url;
				// link.download = `${documentName}`;
				// link.click();
				// URL.revokeObjectURL(url);
				// const link = document.createElement("a");
				// link.href = filePath;
				// link.download = documentName;
				// link.click();
				try {
					const response = await fetch(filePath);
					const blob = await response.blob();

					// Create a temporary link and trigger download
					const link = document.createElement("a");
					link.href = URL.createObjectURL(blob);
					link.download = documentName;
					link.click();
				} catch (error) {
					console.log("Error:", error);
				}
			}
		} catch (error) {
			console.log("error...", error);
		}
		// });
	};

	const handleEditDocuments = () => {
		toggle();
		setIsEditDocuments(true);
		setSize("xl");
	};

	const handleDownloadUploadedDocuments = () => {
		console.log("case details Id......", viewCaseId);
		dispatch(caseActions.downloadUploadedDocument(viewCaseId)).then((data) => {
			console.log("data from redux....", data);
			const blob = new Blob([data.payload], { type: "application/zip" });
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = "documents.zip";
			link.click();
			URL.revokeObjectURL(url);
		});
	};

	const handleEditSingleDocument = (documentId) => {
		console.log("document Id .....", documentId);
		setDocumentId(documentId);
		setModalTitle("Edit Document");
		setEditDocument(true);
		setSize("md");
		toggle();
	};

	return (
		<Card>
			{/* <CardHeader className="d-flex flex-row justify-content-end mt-0">
				<Button size="md" variant="secondary">
					Download
				</Button>
			</CardHeader> */}
			{documentDetails.length !== 0 && viewCaseId !== undefined ? (
				<div className="d-flex flex-row justify-content-end">
					<Button
						size="sm"
						variant="secondary"
						onClick={handleDownloadUploadedDocuments}
					>
						Download Documents
					</Button>
				</div>
			) : (
				""
			)}
			<CardBody>
				<ul className="container-fluid">
					{documentDetails.map((documents, index) => (
						<li key={documents.id} className="m-b-20">
							<div className="container">
								<div className="row">
									<div className="d-flex flex-row justify-content-between col-10">
										<h6 className="col-4">{documentTypes[index]}</h6>
										<p className="col-6">{documents.document_name}</p>
									</div>
									{viewCaseId !== undefined ? (
										<div className="d-flex flex-row justify-content-between col-2">
											<Button
												variant="primary"
												size="sm"
												onClick={() =>
													handleViewAndDownloadDocument(
														documents.document_path,
														"view",
														documents.document_name
													)
												}
											>
												View
											</Button>
											<Button
												variant="transparent"
												size="lg"
												onClick={() =>
													handleViewAndDownloadDocument(
														documents.document_path,
														"download",
														documents.document_name
													)
												}
											>
												<i className="fa fa-download text-primary" />
											</Button>
										</div>
									) : (
										<div className="col-2 d-flex justify-content-between">
											<Button
												variant="primary"
												size="sm"
												className="m-r-10"
												onClick={() =>
													handleViewAndDownloadDocument(
														documents.document_path,
														"view",
														documents.document_name
													)
												}
											>
												View
											</Button>
											<Button
												variant="secondary"
												size="sm"
												className="m-l-10"
												onClick={() => handleEditSingleDocument(documents.id)}
											>
												Edit
											</Button>
										</div>
									)}
								</div>
							</div>
						</li>
					))}
				</ul>
				{uploadedDocuments !== undefined ? (
					<Button
						variant="secondary"
						size="md"
						onClick={handleEditDocuments}
						className="mt-5"
					>
						Edit Documents
					</Button>
				) : (
					""
				)}
				{}
				{modal && (
					<ModalForm
						modal={modal}
						toggle={toggle}
						uploadedDocuments={[]}
						modalTitle={modalTitle}
						caseDetailsId={caseDetailsId}
						isEditDocuments={isEditDocuments}
						editDocument={editDocument}
						documentId={documentId}
						size={size}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export default CaseDocumentViewPage;
