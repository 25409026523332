import React, { useEffect } from "react";
import { Form, Label, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";
import { useNavigate } from "react-router";

const PaymentRefForm = (props) => {
  const { caseId, toggle, refundAmount } = props;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  console.log("payment page", refundAmount);
  console.log("caseId....", caseId);
  // useEffect(() => {
  //   const getData = async () => {
  //     setValue("returnAmount", refundAmount);
  //   };
  //   getData();
  // });

  const onSubmit = (data) => {
    if (data) {
      const { paymentRefNo } = data;
      const reqBody = {
        caseId: caseId,
        paymentRefNo: paymentRefNo,
        status: "SUCCESS",
      };
      console.log(reqBody);
      dispatch(caseActions.paymentToBeReturned(reqBody)).then((response) => {
        console.log("response.........", response);
      });
      reset();
      toggle();
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    } else {
      errors.showMessages();
    }
  };

  return (
    <Form
      className="needs-validation"
      noValidate=""
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row>
        <p>
          Amount of{" "}
          <span className="badge badge-success f-s-5">Rs. {refundAmount}</span>{" "}
          to be returned
        </p>
        <div className="d-flex justify-content-between align-items-center">
          <Col className="col-3 ">
            <Label className="form-label" for="paymentRefNo">
              PAYMENT REF.NO. <span className="text-danger">*</span>
            </Label>
          </Col>
          <Col className="col-1">
            <strong>:</strong>
          </Col>
          <Col className="col-6 m-r-20">
            <input
              className="form-control"
              id="paymentRefNo"
              type="text"
              placeholder="Payment Reference Number"
              name="paymentRefNo"
              {...register("paymentRefNo", { required: true })}
            />
            <span className="d-block text-danger">
              {errors.paymentRefNo && "Payment Reference Number is required"}
            </span>
            <div className="valid-feedback">Looks good!</div>
          </Col>
          <Col>
            <Button variant="primary" size="sm" type="submit">
              OK
            </Button>
          </Col>
        </div>
      </Row>
    </Form>
  );
};

export default PaymentRefForm;
