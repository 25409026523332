import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "Contractors";
console.log("counterss");
function createInitialState() {
	console.log("1");
	return {
		Contractor: [],
		error: null,
		loader: false,
	};
}

function createReducers() {
	function toggleAuthPopup(state, { payload }) {
		state.checkPopup = payload;
	}
	function updateAuthMessage(state, { payload }) {
		state.unAuthMessage = payload;
	}
	return {
		toggleAuthPopup,
		updateAuthMessage,
	};
}

function createExtraActions() {
	console.log("2");
	const baseUrl = `${process.env.REACT_APP_API_URL}`;
	function getAllContractor() {
		return createAsyncThunk(
			`/getAllContractor`,
			async () => await fetchAPIWapper.get(`${baseUrl}/serviceProviders`)
		);
	}

	function CreateContractor() {
		console.log("3");
		return createAsyncThunk(
			`/createContractor`,
			async (reqBody) =>
				await fetchAPIWapper.post(`${baseUrl}/serviceProviders`, reqBody)
		);
	}
	function getContractorById() {
		console.log("4");
		return createAsyncThunk(
			`/getContractorById`,
			async (id) =>
				await fetchAPIWapper.get(`${baseUrl}/serviceProviders/${id}`)
		);
	}
	function updateContractById() {
		return createAsyncThunk(
			`/UpdateContractorById`,
			async ({ id, reqBody }) =>
				await fetchAPIWapper.put(`${baseUrl}/serviceProviders/${id}`, reqBody)
		);
	}

	function contactDetailsValidation() {
		return createAsyncThunk(
			`/getContractorById`,
			async (contactDetail) =>
				await fetchAPIWapper.get(
					`${baseUrl}/validation/contactDetail?contact_detail=${contactDetail}`
				)
		);
	}
	function labourNoValidation() {
		return createAsyncThunk(
			`/getContractorById`,
			async (labourNum) =>
				await fetchAPIWapper.get(
					`${baseUrl}/validation/labourNo?labourNumber=${labourNum}`
				)
		);
	}
	function svpDatabaseDownload() {
		return createAsyncThunk(
			`/excelReports/svpDatabase`,
			async ({ searchKey, searchValues }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/excelReports/svpDatabase?${searchKey}=${searchValues}`
				)
		);
	}
	function masterSvpFilter() {
		return createAsyncThunk(
			`/serviceProviders/msvp`,
			async ({ startDate, endDate, searchKey, searchValues }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/serviceProviders/msvp?startDate=${startDate}&endDate=${endDate}&${searchKey}=${searchValues}`
				)
		);
	}
	return {
		getAllContractor: getAllContractor(),
		createContractor: CreateContractor(),
		getContractorById: getContractorById(),
		updateContractorById: updateContractById(),
		contactDetailsValidation: contactDetailsValidation(),
		labourNoValidation: labourNoValidation(),
		svpDatabaseDownload: svpDatabaseDownload(),
		masterSvpFilter: masterSvpFilter(),
	};
}

function createExtraReducers() {
	console.log("7");
	function getAllContractor() {
		let { pending, fulfilled, rejected } = extraActions.getAllContractor;

		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getAllContractor _service--> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function createContractor() {
		console.log("8");
		let { pending, fulfilled, rejected } = extraActions.createContractor;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("createContractor _service--> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getContractorById() {
		console.log("9");
		let { pending, fulfilled, rejected } = extraActions.getContractorById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getUserById _service --> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function updateContractorById() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.updateContractorById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function contactDetailsValidation() {
		console.log("10");
		let { pending, fulfilled, rejected } =
			extraActions.contactDetailsValidation;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("contactDetailsValidation _service --> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function labourNoValidation() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.labourNoValidation;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("labourNoValidation _service --> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function svpDatabaseDownload() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.labourNoValidation;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("labourNoValidation _service --> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}

	function masterSvpFilter() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.masterSvpFilter;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("masterSvpFilter _service --> " + action.payload);
				state.Contractor = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}

	return {
		...createContractor(),
		...getAllContractor(),
		...getContractorById(),
		...updateContractorById(),
		...contactDetailsValidation(),
		...labourNoValidation(),
		...svpDatabaseDownload(),
		...masterSvpFilter(),
	};
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const contractorActions = { ...slice.actions, ...extraActions };
export const contractorReducer = slice.reducer;
