import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

const CaseRemarksDetails = ({ caseRemarksDetails }) => {
	const entitlementCode = localStorage.getItem("entitilement");
	console.log("entitlementCode", entitlementCode);
	console.log("case remarks ... in case remarks component", caseRemarksDetails);
	const [filteredCases, setFilteredCases] = useState([]);

	const getFilteredCases = (caseRemarksDetails) => {
		console.log("I'm in get filtered Cases");
		console.log("Case Reamrks Details........", caseRemarksDetails);
		if (entitlementCode === "AA001" || entitlementCode === "ORG001") {
			setFilteredCases(caseRemarksDetails);
		} else if (entitlementCode === "AU001") {
			const filteredRemarks = caseRemarksDetails.filter(
				(eachRemark) =>
					eachRemark.rejectedBy === "RETURNED AUDITOR ADMIN" ||
					eachRemark.rejectedBy === "RETURNED AUDITOR"
			);
			setFilteredCases(filteredRemarks);
		} else if (entitlementCode === "MSVP001") {
			const filteredRemarks = caseRemarksDetails.filter(
				(eachRemark) =>
					eachRemark.rejectedBy === "RETURNED AUDITOR" ||
					eachRemark.rejectedBy === "ACCOUNTS REJECTED"
			);
			console.log("MVSP001..............", filteredRemarks);
			setFilteredCases(filteredRemarks);
		}
	};

	useEffect(() => {
		getFilteredCases(caseRemarksDetails);
	}, [caseRemarksDetails]);

	console.log("filtered remarks.....", filteredCases);
	return (
		<>
			<h6 className="mt-3 text-center">Case Remarks</h6>
			<Table bordered hover className="mt-3">
				<thead className="table-secondary">
					<tr>
						<th className="w-25">Date of Rejection</th>
						<th>Remarks</th>
						<th>Rejected By</th>
					</tr>
				</thead>
				<tbody>
					{filteredCases.map((eachRemark) => {
						return (
							<tr key={eachRemark.id}>
								<td>
									{eachRemark.dateOfRejection
										.slice(0, 10)
										.split("-")
										.reverse()
										.join("/")}
								</td>
								<td>{eachRemark.remarks}</td>
								{eachRemark.rejectedBy === "RETURNED AUDITOR" ? (
									<td>AUDITOR</td>
								) : eachRemark.rejectedBy === "RETURNED AUDITOR ADMIN" ? (
									<td>AUDITOR ADMIN</td>
								) : (
									<td>ACCOUNTANT ADMIN</td>
								)}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default CaseRemarksDetails;
