import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import ModalForm from "./modals/modal";
import CaseViewModal from "./modals/CaseViewModal";
import { Card, CardBody } from "reactstrap";
import CaseCreationDetails from "./CaseCreationDetails";
import SeviceProviderDetails from "./ServiceProviderDetails";
import CaseRemarksDetails from "../Case/CaseRemarksDetails";

const CaseDetailsTable = (props) => {
	const {
		caseDetails,
		handleEdit,
		type,
		caseCreationDetails,
		isViewClicked,
		svcDetails,
		caseRemarksDetails,
	} = props;
	console.log("case creation Details..", caseDetails);
	const [modal, setModal] = useState(false);
	const [modalTitle, setModalTitle] = useState(" Upload Case Documents");
	const toggle = () => setModal(!modal);
	const [uploadCaseId, setUploadCaseId] = useState(null);
	const [viewDocuments, setViewDocuments] = useState(false);
	const [caseViewModal, setCaseViewModal] = useState(false);
	const viewToggle = () => setCaseViewModal(!caseViewModal);
	const [viewCaseId, setViewCaseId] = useState(null);

	const handleUpload = (id) => {
		toggle();
		setUploadCaseId(id);
	};

	const handleViewDocument = (caseDetailsId) => {
		console.log("Case details for view documents page...", caseDetailsId);
		setViewCaseId(caseDetailsId);
		setViewDocuments(true);
		viewToggle();
		setModalTitle("View Case Documents");
	};

	return (
		<Card>
			<CardBody>
				{isViewClicked && (
					<>
						<SeviceProviderDetails svcDetails={svcDetails} />
						<CaseCreationDetails caseCreationDetails={caseCreationDetails} />
					</>
				)}
				<Table bordered hover responsive className="mt-3 table">
					<thead className="table-secondary">
						<tr>
							<th>Year</th>
							<th>Month</th>
							<th>Man Days</th>
							<th>EPF Transaction Number</th>
							<th>ESI Transaction Number</th>
							{type === "view" ? (
								<th>View Documents</th>
							) : (
								<>
									<th>Upload</th>
									<th>Edit</th>
								</>
							)}
						</tr>
					</thead>
					{/* <tbody>
					{cases.map((eachCase) => (
						<tr key={eachCase.id}>
							<td>{eachCase.year}</td>
							<td>{eachCase.month}</td>
							<td>{eachCase.man_days}</td>
							<td>{eachCase.epf_trans_no}</td>
							<td>{eachCase.esi_trans_no}</td>
							<td>
								<Button
									variant="primary"
									size="sm"
									onClick={() => openModal(eachCase.id)}
								>
									Upload
								</Button>
							</td>
							<td>
								<Button
									variant="secondary"
									size="sm"
									onClick={() => handleEdit(eachCase.id)}
								>
									Edit
								</Button>
							</td>
							<td>
								<Button variant="danger" size="sm">
									Delete
								</Button>
							</td>
						</tr>
					))}
				</tbody> */}
					<tbody>
						{caseDetails.map((eachCase) => (
							<tr key={eachCase.id}>
								<td>{eachCase.year}</td>
								<td>{eachCase.month}</td>
								<td>{eachCase.man_days}</td>
								<td>{eachCase.epf_trans_no}</td>
								<td>{eachCase.esi_trans_no}</td>
								{type === "view" ? (
									<>
										<td>
											<Button
												variant="transparent"
												onClick={() => handleViewDocument(eachCase.id)}
											>
												<i className="fa fa-eye" />
											</Button>
										</td>
									</>
								) : (
									<>
										<td>
											<Button
												variant="transparent"
												onClick={() => handleUpload(eachCase.id)}
											>
												<i className="icofont icofont-ui-file fs-5"></i>
											</Button>
										</td>
										<td>
											<Button
												variant="transparent"
												onClick={() => handleEdit(eachCase.id)}
											>
												<i className="icofont icofont-ui-edit fs-5"></i>
											</Button>
										</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</Table>
				{isViewClicked && (
					<CaseRemarksDetails caseRemarksDetails={caseRemarksDetails} />
				)}
				{modal && (
					<ModalForm
						modal={modal}
						modalTitle={modalTitle}
						toggle={toggle}
						uploadCaseId={uploadCaseId}
					/>
				)}
				{viewDocuments && (
					<CaseViewModal
						caseViewModal={caseViewModal}
						modalTitle={modalTitle}
						viewToggle={viewToggle}
						viewCaseId={viewCaseId}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export default CaseDetailsTable;
