import React, { useState, useEffect } from "react";
import { Form, Card, CardBody, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { circleActions } from "../../_services";
import { useDispatch } from "react-redux";
import { reportsActions } from "../../_services";
import { useNavigate } from "react-router";

const DatewiseReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [circleDetails, setCircleDetails] = useState([]);
  const [circleId, setCircleId] = useState("none");
  const [svpStatus, setSvpStatus] = useState("none");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(circleActions.getCircleNames()).then((response) => {
      console.log(response.payload);
      setCircleDetails(response.payload);
    });
  }, []);

  const serviceProviderStatus = [
    "ALL STATUS",
    "DRAFT",
    "SUBMITTED",
    "UNDER REVIEW",
    "AUDIT CLEARED",
    "PAYMENT FAILED",
    "AUDIT RETURN",
  ];

  const dateFormat = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const startDateChange = (date) => {
    setStartDate(date);
  };
  const endDateChange = (date) => {
    setEndDate(date);
  };
  const handleClear = () => {
    console.log("Clear button is triggered");
    navigate(`${process.env.PUBLIC_URL}/datewiseReports`);
  };
  const handleDownload = () => {
    const startDateFormat = dateFormat(startDate);
    const endDateFormat = dateFormat(endDate);
    console.log(startDateFormat, endDateFormat, circleId, svpStatus);
    dispatch(
      reportsActions.getDatewiseReports({
        startDateFormat,
        endDateFormat,
        circleId,
        svpStatus,
      })
    ).then((response) => {
      const { payload } = response;
      console.log("response......", payload);
      if (payload === "NO CASE DATA") {
        setMessage(payload);
        setShowMessage(true);
      } else {
        setShowMessage(false);
        const blobData = new Blob([payload]);
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Date Wise Reports.xlsx";
        link.click();
        URL.revokeObjectURL(url);
      }
    });
  };
  const handleSelectCicrle = (e) => {
    if (e.target.value) {
      setCircleId(e.target.value);
    }
  };
  const handlesvpStatus = (e) => {
    if (e.target.value) {
      setSvpStatus(e.target.value);
    }
  };
  return (
    <Card style={{ height: "100vh" }}>
      <CardBody>
        <Form>
          <Row className="mb-3">
            <Col>
              <label htmlFor="startDate">Choose Start Date</label>
              <DatePicker
                className="form-control digits  bg-light text-dark"
                selected={startDate}
                onChange={startDateChange}
                id="startDate"
                dateFormat="dd-MM-yyyy"
              />
            </Col>
            <Col>
              <label htmlFor="startDate">Choose Start Date</label>
              <DatePicker
                className="form-control digits  bg-light text-dark"
                selected={endDate}
                onChange={endDateChange}
                id="startDate"
                dateFormat="dd-MM-yyyy"
              />
            </Col>
            <Col>
              <label htmlFor="selectCircle">Select Circle</label>
              <select
                name="circle"
                id="selectCircle"
                className="form-select  bg-light text-dark"
                onChange={(e) => handleSelectCicrle(e)}
              >
                <option value="" selected disabled>
                  Choose Circle
                </option>
                {circleDetails.map((eachCircleInfo) => {
                  return (
                    <option
                      key={eachCircleInfo.value}
                      value={eachCircleInfo.value}
                    >
                      {eachCircleInfo.label}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col>
              <label htmlFor="contractorStatus">Select Contractor Status</label>
              <select
                name="contractor"
                id="contractorStatus"
                className="form-select  bg-light text-dark"
                onChange={(e) => handlesvpStatus(e)}
              >
                <option value="" selected disabled>
                  Choose Contractor Status
                </option>
                {serviceProviderStatus.map((eachServiceProvider) => {
                  return <option>{eachServiceProvider}</option>;
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button variant="primary" onClick={handleClear}>
                Clear
              </Button>
              <Button variant="secondary" onClick={handleDownload}>
                Download
              </Button>
            </div>
          </Row>
        </Form>
        {showMessage && (
          <div
            className="d-flex flex-column justify-content-center"
            style={{ height: "50vh" }}
          >
            <p className="text-center font-danger f-w-700">{message}</p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default DatewiseReport;
