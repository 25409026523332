import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  circleActions,
  contractorActions,
  userActions,
} from "../../../_services";

const UserDesignation = () => {
  const entitlementCode = localStorage.getItem("entitilement");
  const userId = localStorage.getItem("userId");
  const [designation, setDesignation] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (entitlementCode === "ORG001") {
      setDesignation(localStorage.getItem("userName"));
    } else if (entitlementCode === "CA001") {
      dispatch(userActions.getUserById(userId)).then((data) => {
        console.log("data----->user-------------------------------->", data);
        const firstName = data.payload[0].first_name;
        const circleId = data.payload[0].circle_id;
        dispatch(circleActions.getCircleById(circleId)).then((data) => {
          setDesignation(`Hi. ${firstName} (${data.payload.name})`);
        });
      });
    } else if (entitlementCode === "SVP001") {
      dispatch(contractorActions.getContractorById(userId)).then((data) => {
        const svcDetails = data.payload[0];
        console.log("svcDetails....", svcDetails);
        setDesignation(`Hi. ${svcDetails.establishment_name}`);
        console.log(svcDetails.establishment_name);
      });

      // setDesignation("Contractor");
    } else if (entitlementCode === "MSVP001") {
      setDesignation(`Master Contractor`);
    } else {
      dispatch(userActions.getUserById(userId)).then((data) => {
        console.log("Users data....", data);
        setDesignation(`Hi. ${data.payload[0].first_name}`);
      });
    }
  }, [entitlementCode]);

  return (
    <div className="me-3">
      <h6>{designation}</h6>
    </div>
  );
};

export default UserDesignation;
