import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import CaseSubmitButtons from "./CaseSubmitButtons";

const CommonModal = (props) => {
	console.log("props....", props);
	const toggle = props.toggler;
	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.toggler}
			size={props.size}
			centered
			className="modal-dialog-scrollable"
		>
			<ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
			<ModalBody className={props.bodyClass}>{props.children}</ModalBody>
			{/* <ModalFooter>
				{props.mode === "payment" ? (
					<CaseSubmitButtons type={"submit"} toggle={toggle} />
				) : (
					""
				)}
			</ModalFooter> */}
		</Modal>
	);
};

export default CommonModal;
