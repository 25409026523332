import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Power } from "react-feather";
import man from "../../../assets/images/dashboard/user/dashboard profile.png";
// import prolifyLogo from "../../../assets/images/logo/Proli_logo.png";

import { LI, UL, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { useDispatch } from "react-redux";
import {
  userActions,
  contractorActions,
  authActions,
} from "../../../_services";
const UserHeader = () => {
  let secondaryRoleId;
  const navigate = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("undefine");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const entitlementCode = localStorage.getItem("entitilement");
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [secondaryRoles, setSecondaryRoles] = useState([]);
  let filteredSecondaryRoles = [];
  // const secondaryRoles = [
  // 	{ roleId: 6, roleName: "Auditor Admin" },
  // 	{ roleId: 7, roleName: "Auditor" },
  // 	{ roleId: 8, roleName: "Accountant" },
  // 	{ roleId: 9, roleName: "Master Contractor" },
  // ];

  const fetchSecondaryRoles = () => {
    secondaryRoleId = localStorage.getItem("secondaryRoleIds");
    console.log("secondaryRoleId............", secondaryRoleId);
    if (secondaryRoleId !== null) {
      secondaryRoleId = JSON.parse(secondaryRoleId);
      const searchValues = secondaryRoleId.join(",");
      const searchKey = "roleIds";
      dispatch(
        authActions.fetchSecondaryRoles({ searchKey, searchValues })
      ).then((response) => {
        console.log(
          "response.................in fetchSecondaryRoles..........",
          response
        );
        const { payload } = response;
        setSecondaryRoles(payload);
      });
    }
  };

  if (entitlementCode === "AA001") {
    filteredSecondaryRoles = secondaryRoles.filter(
      (eachRole) => eachRole.roleName !== "Auditor Admin"
    );
  } else if (entitlementCode === "AU001") {
    filteredSecondaryRoles = secondaryRoles.filter(
      (eachRole) => eachRole.roleName !== "Auditor"
    );
  } else if (entitlementCode === "AC001") {
    filteredSecondaryRoles = secondaryRoles.filter(
      (eachRole) => eachRole.roleName !== "Accountant"
    );
  } else if (entitlementCode === "MSVP001") {
    filteredSecondaryRoles = secondaryRoles.filter(
      (eachRole) => eachRole.roleName !== "Master Contractor"
    );
  }

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    // setProfile(prolifyLogo);
    if (entitlementCode === "ORG001") {
      setName("Organisation");
    } else if (entitlementCode === "SVP001") {
      dispatch(contractorActions.getContractorById(userId)).then((data) => {
        const svcContactDetail = data.payload[1][1];
        const mobileNumber = svcContactDetail.contact_detail;
        console.log("svcDetails....", mobileNumber);
        setName(mobileNumber);
      });
    } else {
      dispatch(userActions.getUserById(userId)).then((response) => {
        console.log("first---->", response);
        const { mobile_number } = response.payload[0];
        setName(mobile_number);
      });
    }
    fetchSecondaryRoles();
  }, [entitlementCode]);

  const Logout = () => {
    localStorage.removeItem("data");
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("Token");
    localStorage.removeItem("userId");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.removeItem("secondaryRoleIds");
    localStorage.setItem("authenticated", false);
    localStorage.setItem("login", "false");
    navigate(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    navigate(redirect);
  };

  const handleSecondaryRole = (roleId) => {
    const userLoginId = JSON.parse(localStorage.getItem("userLoginId"));
    console.log(`userId is ${userLoginId} and roleId is ${roleId}`);
    let info = {
      userId: userLoginId,
      roleId: roleId,
    };
    dispatch(authActions.refreshTokenForSwitchUser(info)).then((response) => {
      console.log("response for token refresh.....", response);
      if (response.payload) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
        window.location.reload();
      }
    });
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <img
          //   attrImage={{
          // className: "b-r-10 m-0",
          src={`${authenticated ? auth0_profile.picture : profile}`}
          alt=""
          //   }}
          style={{ width: "30px" }}
        />
        <div className="media-body">
          <span>{authenticated ? auth0_profile.name : name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {entitlementCode === "ORG001"
              ? "Admin"
              : entitlementCode === "AA001"
              ? "Auditor Admin"
              : entitlementCode === "CA001"
              ? "Circle Admin"
              : entitlementCode === "SVP001"
              ? "Contractor"
              : entitlementCode === "MSVP001"
              ? "Master Contractor"
              : entitlementCode === "AU001"
              ? "Auditor"
              : entitlementCode === "AC001"
              ? "Accountant"
              : ""}
            {/* <i className="middle fa fa-angle-down"></i> */}
          </P>
        </div>
        <div className="media-body">
          <LI attrLI={{ onClick: Logout }}>
            <Power className="mt-1" />
          </LI>
        </div>
      </div>

      {secondaryRoleId !== null && (
        <UL
          attrUL={{
            className: "simple-list profile-dropdown onhover-show-div",
          }}
        >
          {/* <LI
					attrLI={{
						onClick: () =>
							UserMenuRedirect(
								`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`
							),
					}}
				>
					<span>Account </span>
				</LI> */}
          {/* <LI
					attrLI={{
						onClick: () =>
							UserMenuRedirect(
								`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`
							),
					}}
				>
					<span>Inbox</span>
				</LI> */}
          {/* <LI
					attrLI={{
						onClick: () =>
							UserMenuRedirect(
								`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`
							),
					}}
				>

					<span>Taskboard</span>
				</LI> */}
          {filteredSecondaryRoles.map((eachRole) => {
            return (
              <LI
                key={eachRole.roleId}
                attrLI={{
                  onClick: () => handleSecondaryRole(eachRole.roleId),
                }}
              >
                <span>{eachRole.roleNametempAllRoles}</span>
              </LI>
            );
          })}
        </UL>
      )}
    </li>
  );
};

export default UserHeader;
