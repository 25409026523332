import { store, authActions } from "../../_services";

// function authToken() {
//     return store.getState().auth.token;
// }
function authToken() {
  return localStorage.getItem("Token");
}

function authHeader(url) {
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: token };
  } else {
    return {};
  }
}

// function handleResponse(response) {
//   console.log("response from response", response);
//   return response.text().then((authresponse) => {
//     console.log("auth REsponse", authresponse);
//     let data = authresponse && JSON.parse(authresponse);
//     // let data = authresponse;
//     console.log("handleResponse", data);
//     if (!response.ok) {
//       let checkError = ``;
//       if ([401, 403].includes(response.status)) {
//         const logout = () => store.dispatch(authActions.logout());
//         logout();
//       }
//       if (data && data.errors && Object.keys(data.errors).length >= 1) {
//         for (let errorList in data.errors) {
//           let checkErrors = data.errors[errorList];
//           let checkLength = 0;
//           while (checkLength < checkErrors.length) {
//             checkError += `${checkErrors[checkLength]} \n`;
//             checkLength++;
//           }
//         }
//       }
//       const error = checkError ? checkError : data.message;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

function handleResponse(response) {
  if (response.headers.get("content-type").includes("application/json")) {
    return response.json().then((data) => {
      console.log("handleResponse JSON", data);
      if (!response.ok) {
        let checkError = ``;
        if ([401, 403].includes(response.status)) {
          const logout = () => store.dispatch(authActions.logout());
          logout();
        }
        if (data && data.errors && Object.keys(data.errors).length >= 1) {
          for (let errorList in data.errors) {
            let checkErrors = data.errors[errorList];
            let checkLength = 0;
            while (checkLength < checkErrors.length) {
              checkError += `${checkErrors[checkLength]} \n`;
              checkLength++;
            }
          }
        }
        const error = checkError ? checkError : data.message;
        return Promise.reject(error);
      }
      return data;
    });
  } else if (response.headers.get("content-type").includes("application/zip")) {
    return response.blob().then((blobData) => {
      console.log("handleResponse blobData", blobData);
      if (!response.ok) {
        let checkError = ``;
        if ([401, 403].includes(response.status)) {
          const logout = () => store.dispatch(authActions.logout());
          logout();
        }
        if (
          blobData &&
          blobData.errors &&
          Object.keys(blobData.errors).length >= 1
        ) {
          for (let errorList in blobData.errors) {
            let checkErrors = blobData.errors[errorList];
            let checkLength = 0;
            while (checkLength < checkErrors.length) {
              checkError += `${checkErrors[checkLength]} \n`;
              checkLength++;
            }
          }
        }
        const error = checkError ? checkError : blobData.message;
        return Promise.reject(error);
      }
      return blobData;
    });
  } else if (
    response.headers.get("content-type").includes("application/vnd.ms-excel")
  ) {
    return response.blob().then((blobData) => {
      console.log("handleResponse blobData", blobData);
      if (!response.ok) {
        let checkError = ``;
        if ([401, 403].includes(response.status)) {
          const logout = () => store.dispatch(authActions.logout());
          logout();
        }
        if (
          blobData &&
          blobData.errors &&
          Object.keys(blobData.errors).length >= 1
        ) {
          for (let errorList in blobData.errors) {
            let checkErrors = blobData.errors[errorList];
            let checkLength = 0;
            while (checkLength < checkErrors.length) {
              checkError += `${checkErrors[checkLength]} \n`;
              checkLength++;
            }
          }
        }
        const error = checkError ? checkError : blobData.message;
        return Promise.reject(error);
      }
      return blobData;
    });
  } else {
    return Promise.reject("Unsupported response type");
  }
}

function request(method) {
  return (url, body) => {
    console.log(body, url);
    let requestOptions = {
      method,
      headers: authHeader(url),
    };
    requestOptions.headers["Accept"] = "application/json";
    requestOptions.headers["Accept"] = "multipart/form-data";
    if (body) {
      if (body instanceof FormData) {
        requestOptions.body = body;
      } else {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
    }
    console.log("requestOptions", requestOptions.headers);
    return fetch(url, requestOptions).then(handleResponse);
  };
}

export const fetchAPIWapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};
