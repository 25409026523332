import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardHeader, Form, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import CaseDetailsTable from "./CaseDetailsTable";
import CaseDetailsUpdateForm from "./caseDetailsUpdateForm";
import { useNavigate, useParams } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ModalForm from "./modals/modal";
import { caseActions } from "../../_services";
// import Spinner from "react-bootstrap/Spinner";
import { Spinner } from "../../AbstractElements";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const EditCaseDetails = () => {
	const [editCaseId, setEditCaseId] = useState(null);
	const [caseDetails, setCaseDetails] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const navigate = useNavigate();
	const { caseId } = useParams(); // passing the caseId in url
	const dispatch = useDispatch();
	const [Case, setCase] = useState([]);
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const modalTitle = "PAYMENT DETAILS";
	const [paymentDetails, setPaymentDetails] = useState({});
	const [yearDetails, setYearDetails] = useState([]);
	const [currentValue, setCurrentValue] = useState(undefined);
	const [loadingElement, setLoadingElement] = useState(false);
	let tempDocument = 0;

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const displayDangerAlert = () => {
		SweetAlert.fire({
			title: "Oops!",
			text: "No cases to submit!",
			icon: "error",
		});
	};

	const displayWarningAlert = () => {
		SweetAlert.fire({
			title: "Are you sure?",
			text: "Once case is submitted, you will not be able to edit this case!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
			reverseButtons: false,
		}).then((result) => {
			if (result.value) {
				statusChange();
			}
		});
	};

	const displayFailureAlert = () => {
		SweetAlert.fire({
			title: "Looking Not Good!",
			text: "Case not submitted! kindly upload pending documents",
			icon: "warning",
		});
	};

	const displaySuccessAlert = () => {
		SweetAlert.fire({
			title: "Great!",
			text: "Case Saved Successfully!",
			icon: "success",
		});
	};

	const deleteCaseDetail = (caseDetailId) => {
		console.log("case details id...", caseDetailId);
		const filteredCaseDetails = caseDetails.filter(
			(eachCase) => eachCase.id !== caseDetailId
		);
		setCaseDetails(filteredCaseDetails);
		dispatch(caseActions.getCaseDetailsById(caseDetailId)).then((data) => {
			const { case_id, year, month, man_days, epf_trans_no, esi_trans_no } =
				data.payload;
			console.log(case_id, year, month, man_days, epf_trans_no, esi_trans_no);
			const reqBody = {
				caseId: case_id,
				year: year,
				month: month,
				epfTransNo: epf_trans_no,
				esiTransNo: esi_trans_no,
				status: false,
			};
			dispatch(caseActions.updateCaseDetails({ caseDetailId, reqBody })).then(
				(responseData) => {
					console.log("response data...", responseData);
				}
			);
		});
	};

	const handleDelete = (id) => {
		console.log("handle delete id", id);
		SweetAlert.fire({
			title: "Are you sure?",
			text: "Once case details are deleted, you will not be able to revert this case details!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
			reverseButtons: false,
		}).then((result) => {
			if (result.value) {
				deleteCaseDetail(id);
				toast.success(`Your Case Details has been deleted`);
			}
		});
	};

	const handleSaveCase = () => {
		toast.success("Your case has been saved.");
		navigate(`${process.env.PUBLIC_URL}/case/viewAll`);
	};

	const handleEdit = (id) => {
		console.log("edit id...", id);
		setEditCaseId(id);
		setIsEditing(true);
	};

	const getYearDetails = async () => {
		await dispatch(caseActions.getYearDetails()).then((response) => {
			console.log("response........", response);
			const { payload } = response;
			setYearDetails(payload);
		});
	};

	const getCaseDetails = async () => {
		await dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
			console.log("caseDetails value  from redux------------>----", data);
			setCaseDetails(data.payload);
			console.log("caseDetails------------>", caseDetails);
		});
	};
	const getCase = async () => {
		await dispatch(caseActions.getCaseById(caseId)).then((response) => {
			setCase(response.payload);
		});
	};

	const handleBack = () => {
		console.log("Back Button is triggered....");
		navigate(`${process.env.PUBLIC_URL}/EditCase/${caseId}`);
	};

	useEffect(() => {
		getCaseDetails();
		getCase();
		getYearDetails();
	}, []);

	const getPaymentDetails = () => {
		setLoadingElement(true);
		console.log("I'm in get payment details method.....");
		dispatch(caseActions.getPaymentDetails(caseId)).then((data) => {
			console.log(
				"data IN GET PAYMENT details in edit case........",
				data.payload
			);
			const status = data.payload.status;
			console.log("status in get payment details.....", status);
			if (status) {
				updateCaseStatus();
				// toast.success("Man days are not changed");
			} else {
				setPaymentDetails(data.payload);
				setLoadingElement(false);
				toggle();
			}
		});
	};

	const getCaseDocuments = async (caseDetailsId) => {
		const caseDocuments = [];
		for (let caseDetailId of caseDetailsId) {
			const response = await dispatch(
				caseActions.getAllCaseDocuments(caseDetailId)
			).then((data) => {
				console.log("get Case documents.....", data);
				for (let eachDocument of data.payload) {
					caseDocuments.push(eachDocument);
				}
			});
		}
		console.log(caseDocuments);
		return caseDocuments;
	};

	const updateCaseStatus = () => {
		console.log("Update case status function call....");
		const reqBody = {
			svcProviderStatus: "UNDER REVIEW",
			auditorStatus: "PENDING",
		};
		dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
			console.log("data in caseactions......", data);
			setLoadingElement(false);
			toast.success("Your case has been submitted");
			navigate(`${process.env.PUBLIC_URL}/case/viewAll`);
		});
	};

	// const updateStatus = async () => {
	// 	return await dispatch(retriveCaseById(caseId)).then((response) => {
	// 		const {
	// 			acceptance_order_no,
	// 			agreement_no,
	// 			nature_work,
	// 			work_start_date,
	// 			work_end_date,
	// 		} = response;
	// 		const reqBody = {
	// 			acceptanceOrderNo: acceptance_order_no,
	// 			agreementNo: agreement_no,
	// 			natureOfWork: nature_work,
	// 			workStartDate: work_start_date,
	// 			workEndDate: work_end_date,
	// 			status: "Submitted",
	// 		};
	// 		dispatch(UpdateCase(caseId, reqBody)).then((response) => {
	// 			console.log("response data...", response);
	// 			// SweetAlert.fire(
	// 			// 	"Submitted!",
	// 			// 	"Your case has been submitted.",
	// 			// 	"success"
	// 			// );
	// 			toast.success("Your case has been submitted!");
	// 			/* Toast.fire({
	//         icon: "success",
	//         text: "Your case has been submitted!",
	//       }); */
	// 			navigate(`${process.env.PUBLIC_URL}/case/viewAll`);
	// 		});
	// 	});
	// };

	const statusChange = async () => {
		const isStatusChange = await dispatch(
			caseActions.getAllCaseDetails(caseId)
		).then(async (data) => {
			const caseDetails = data.payload;
			const totalCaseDetails = caseDetails.length;
			console.log("total case details.....", totalCaseDetails);
			if (totalCaseDetails !== 0) {
				const caseDetailsId = caseDetails.map((eachCase) => eachCase.id);
				console.log("case details id ----->", caseDetailsId);
				for (let i = 0; i < caseDetailsId.length; i++) {
					const caseDetailId = caseDetailsId[i];
					await dispatch(caseActions.getAllCaseDocuments(caseDetailId)).then(
						(data) => {
							console.log("data from status change redux", data);
							let file = data.payload;
							console.log(" file lemgth", file.length);
							tempDocument += file.length;
							console.log("first", tempDocument);
						}
					);
				}
				console.log("tempDocument", tempDocument);
				if (tempDocument >= totalCaseDetails * 9) {
					console.log("I'm in if block");
					// updateStatus();
					getPaymentDetails();
				} else {
					console.log("I'm in else");
					displayFailureAlert();
				}
			} else {
				displayDangerAlert();
			}
		});
	};

	const handleSubmitCase = async () => {
		displayWarningAlert();
	};

	// const handleAuditReturnSubmit = () => {
	// 	displayWarningAlert();
	// };

	function checkValue(event) {
		setCurrentValue(handleDecimalsOnValue(event.target.value));
	}

	function handleDecimalsOnValue(value) {
		const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
		return value.match(regex)[0];
	}

	const onSubmit = async (formData) => {
		if (formData !== "") {
			const reqBody = {
				caseId: caseId,
				year: formData.year,
				month: formData.month.toUpperCase(),
				manDays: formData.manDays,
				epfTransNo: formData.epfTransactionNumber.toUpperCase(),
				esiTransNo: formData.esiTransactionNumber.toUpperCase(),
				svcProviderId: Case.svc_provider_id,
				circleId: Case.org_location_id,
				acceptanceOrderNo: Case.acceptance_order_no,
				agreementNo: Case.agreement_no,
			};
			console.log("first", reqBody);
			dispatch(caseActions.createCaseDetails(reqBody)).then((data) => {
				console.log("edit case detail page redux ---->", data);

				if (data.payload.message === "Success") {
					getCaseDetails();
					reset({
						year: null,
						month: null,
						manDays: "",
						epfTransactionNumber: "",
						esiTransactionNumber: "",
					});
					setCurrentValue("");
				} else if (data.payload.message === "Already Exist") {
					toast.warn(
						`Agreement/Acceptance number already exists in Case Number ${data.payload.caseId}`
					);
					/* Toast.fire({
        		icon: "warning",
        		text: "Agreement/Acceptance number already exists",
        	}); */
				}
			});
		} else {
			errors.showMessages();
		}
	};
	console.log("editing...", isEditing);

	return isEditing ? (
		<CaseDetailsUpdateForm editCaseId={editCaseId} />
	) : (
		<Fragment>
			<Card style={{ marginTop: "40px" }}>
				<CardHeader
					className="text-center fs-3 text-dark"
					style={{ padding: "5px" }}
				>
					Case Details Page
				</CardHeader>
				{loadingElement === true ? (
					<CardBody
						className="d-flex justify-content-center align-items-center"
						style={{
							height: "100vh",
							fontSize: "20px",
						}}
					>
						<h6 className="f-30 m-r-10 font-dark">
							Payment Details are Loading. Please Wait...
						</h6>
						<div className="loader-box">
							<Spinner attrSpinner={{ className: "loader-2" }} />
						</div>
					</CardBody>
				) : (
					<CardBody>
						<Form
							className="needs-validation"
							noValidate=""
							onSubmit={handleSubmit(onSubmit)}
						>
							<Row>
								<Col md="2 mb-3">
									<Label className="form-label" for="year">
										Year <span className="text-danger">*</span>
									</Label>
									<select
										name="year"
										id="year"
										autoComplete="off"
										className="form-select bg-light text-dark"
										{...register("year", { required: true })}
									>
										<option value="">Select year</option>
										{yearDetails.map((eachYearDetails) => (
											<option
												key={eachYearDetails.id}
												value={eachYearDetails.year}
											>
												{eachYearDetails.year}
											</option>
										))}
									</select>
									<span className="d-block text-danger">
										{errors.year && "Year is required"}
									</span>
									<div className="valid-feedback">Looks good!</div>
								</Col>
								<Col md="2 mb-3">
									<Label className="form-label" for="month">
										Month <span className="text-danger">*</span>
									</Label>
									{/* <input
									className="form-control"
									id="month"
									type="text"
									placeholder="Month"
									name="month"
									{...register("month", { required: true })}
								/> */}
									<select
										name="month"
										id="month"
										autoComplete="off"
										className="form-select bg-light text-dark"
										{...register("month", { required: true })}
									>
										<option value="">Select Month</option>
										<option value="January">January</option>
										<option value="February">February</option>
										<option value="March">March</option>
										<option value="April">April</option>
										<option value="May">May</option>
										<option value="June">June</option>
										<option value="July">July</option>
										<option value="August">August</option>
										<option value="September">September</option>
										<option value="October">October</option>
										<option value="November">November</option>
										<option value="December">December</option>
									</select>
									<span className="d-block text-danger">
										{errors.month && "Month is required"}
									</span>
									<div className="valid-feedback">Looks good!</div>
								</Col>
								<Col md="2 mb-3">
									<Label className="form-label" for="manDays">
										Man Days <span className="text-danger">*</span>
									</Label>
									<input
										className="form-control bg-light text-dark"
										id="manDays"
										autoComplete="off"
										value={currentValue}
										type="text"
										placeholder="Man Days"
										name="manDays"
										{...register("manDays", { required: true })}
										onChange={(event) => checkValue(event, "change")}
									/>
									<span className="d-block text-danger">
										{errors.manDays && "Man Days is required"}
									</span>
									<div className="valid-feedback">Looks good!</div>
								</Col>
								<Col md="3 mb-3">
									<Label className="form-label" for="epfTransactionNumber">
										EPF Transaction Number{" "}
										<span className="text-danger">*</span>
									</Label>
									<input
										className="form-control bg-light text-dark"
										autoComplete="off"
										id="epfTransactionNumber"
										type="text"
										placeholder="EPF Transaction Number"
										name="epfTransactionNumber"
										{...register("epfTransactionNumber", { required: true })}
									/>
									<span className="d-block text-danger">
										{errors.epfTransactionNumber &&
											"EPF Transaction Number is required"}
									</span>
									<div className="valid-feedback">Looks good!</div>
								</Col>
								<Col md="3 mb-3">
									<Label className="form-label" for="esiTransactionNumber">
										ESI Transaction Number{" "}
										<span className="text-danger">*</span>
									</Label>
									<input
										className="form-control bg-light text-dark"
										autoComplete="off"
										id="esiTransactionNumber"
										type="text"
										placeholder="ESI Transaction Number"
										name="esiTransactionNumber"
										{...register("esiTransactionNumber", { required: true })}
									/>
									<span className="d-block text-danger">
										{errors.esiTransactionNumber &&
											"ESI Transaction Number is required"}
									</span>
									<div className="valid-feedback">Looks good!</div>
								</Col>
							</Row>
							<Button variant="primary" type="submit">
								Add
							</Button>
						</Form>
						<CaseDetailsTable
							caseDetails={caseDetails}
							handleEdit={handleEdit}
							handleDelete={handleDelete}
							caseId={caseId}
						/>
						<div className="d-flex flex-row justify-content-between">
							<Button variant="secondary" onClick={handleBack}>
								Back
							</Button>
							<div>
								<Button className="me-3" onClick={() => handleSaveCase()}>
									Save Case
								</Button>
								<Button variant="success" onClick={() => handleSubmitCase()}>
									Submit and Pay
								</Button>
							</div>
						</div>
						{modal && (
							<ModalForm
								mode="payment"
								modal={modal}
								toggle={toggle}
								modalTitle={modalTitle}
								paymentDetails={paymentDetails}
								size="lg"
							/>
						)}
					</CardBody>
				)}
			</Card>
		</Fragment>
	);
};

export default EditCaseDetails;
