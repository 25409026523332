import React, { useState } from "react";
import { Form, Label } from "reactstrap";
import { caseActions } from "../../_services";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SingleDocumentEdit = (props) => {
	const { toggle, documentId } = props;
	console.log("docuemt id....", documentId);
	const [fileInfo, setFileInfo] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleCancel = () => {
		toggle();
	};

	const handleFileChange = (event) => {
		const fileValue = event.target.files[0];
		setFileInfo(fileValue);
	};

	const handleFileUpload = async (event) => {
		event.preventDefault();
		console.log("file info...", fileInfo);
		const formData = new FormData();
		formData.append("file", fileInfo);
		const response = await dispatch(
			caseActions.updateSingleDocument({ documentId, formData })
		);
		console.log("response.......", response.payload);
		if (response.payload) {
			toast.success("Document uploaded successfully!");
			toggle();
			navigate(`${process.env.PUBLIC_URL}/case/details`);
		}
	};

	return (
		<Form onSubmit={(event) => handleFileUpload(event)}>
			<div>
				<Label for="fileUpload" className="form-label">
					Upload File
				</Label>
				<input
					type="file"
					className="form-control"
					id="fileUpload"
					onChange={(event) => handleFileChange(event)}
				/>
			</div>
			<div
				className="d-flex justify-content-between"
				style={{ marginTop: "20px" }}
			>
				<button className="btn btn-secondary" onClick={handleCancel}>
					Cancel
				</button>
				<button className="btn btn-primary" type="submit">
					Upload
				</button>
			</div>
		</Form>
	);
};

export default SingleDocumentEdit;
