import React from "react";
import { Tab } from "react-bootstrap";
import { Table } from "reactstrap";
import moment from "moment";

const InvoiceReportTable = (props) => {
  const { invoiceTableData } = props;
  console.log(
    "Invoice table data.... in invoice report table",
    invoiceTableData
  );
  return (
    <Table bordered hover responsive className="m-t-30 table">
      <thead className="table-secondary">
        <tr>
          <th>INVOICE Number</th>
          <th>INVOICE DATE</th>
          <th>CASE NUMBER</th>
          <th>ESTABLISHMENT NAME</th>
          <th>GST NUMBER</th>
          <th>PAYMENT RECIEVED DATE</th>
          <th>REFERENCE NUMBER</th>
          <th>TAXABLE</th>
          <th>CGST %</th>
          <th>SGST %</th>
          <th>IGST %</th>
          <th>TOTAL GST %</th>
          <th>CGST</th>
          <th>SGST</th>
          <th>IGST</th>
          <th>TOTAL TAX</th>
          <th>NET AMOUNT</th>
          <th>RETURN AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {invoiceTableData.map((invoiceData) => {
          const { cgstAmount, sgstAMount } = invoiceData;
          const gstAmount = cgstAmount + sgstAMount;
          return (
            <tr>
              <td>{invoiceData.invoiceNumber}</td>
              <td>{invoiceData.invoiceDate}</td>
              <td>{invoiceData.caseNumber}</td>
              <td>{invoiceData.establishmentName}</td>
              <td>{invoiceData.establishmentGstIn}</td>
              <td>{invoiceData.paymentReceivedDate}</td>
              <td>{invoiceData.referenceNumber}</td>
              <td>{invoiceData.grossAmount}</td>
              <td>{invoiceData.cgstPercentage}</td>
              <td>{invoiceData.sgstPercentage}</td>
              <td>{invoiceData.igstPercentage}</td>
              <td>{invoiceData.totalGstPercentage}</td>
              <td>{invoiceData.cgstAmount}</td>
              <td>{invoiceData.sgstAmount}</td>
              <td>{invoiceData.igstAmount}</td>
              <td>{invoiceData.totalTax}</td>
              <td>{invoiceData.netAmount}</td>
              <td>{invoiceData.returnAmount}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default InvoiceReportTable;
