import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Card, CardBody, CardHeader, Form, Label } from "reactstrap";
import Button from "react-bootstrap/Button";
import CaseDetailsForm from "./caseDetailsForm";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";

const CaseDetailsUpdateForm = ({ editCaseId }) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [cases, setCases] = useState([]);
  const caseId = localStorage.getItem("caseId");
  console.log("edit case id....", editCaseId);
  const dispatch = useDispatch();
  const caseDetailId = editCaseId;
  const [yearDetails, setYearDetails] = useState([]);
  const [currentValue, setCurrentValue] = useState(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    const getYearDetails = async () => {
      await dispatch(caseActions.getYearDetails()).then((response) => {
        console.log("response........", response);
        const { payload } = response;
        setYearDetails(payload);
      });
    };
    getYearDetails();
    dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
      console.log("response data from redux....", data);
      const filterCase = data.payload.filter((eachCase) => {
        if (eachCase.id === editCaseId) {
          return true;
        }
        return false;
      });
      console.log("filtered case .....->", filterCase);
      setValue("year", filterCase[0].year);
      setValue("month", filterCase[0].month);
      setValue("manDays", filterCase[0].man_days);
      setValue("epfTransactionNumber", filterCase[0].epf_trans_no);
      setValue("esiTransactionNumber", filterCase[0].esi_trans_no);
      console.log("filter case year...", filterCase);
    });
  }, [editCaseId]);

  function checkValue(event) {
    setCurrentValue(handleDecimalsOnValue(event.target.value));
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const onSubmit = async (formData) => {
    if (formData !== "") {
      const reqBody = {
        caseId: caseId,
        year: formData.year,
        month: formData.month,
        manDays: formData.manDays,
        epfTransNo: formData.epfTransactionNumber,
        esiTransNo: formData.esiTransactionNumber,
      };
      console.log("req body....", reqBody);
      dispatch(caseActions.updateCaseDetails({ caseDetailId, reqBody })).then(
        (data) => {
          console.log("casedetail updte redux---->", data);
          if (data.type == "/updateCaseDetails/fulfilled") {
            dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
              setCases(data.payload);
              setIsUpdated(true);
              reset();
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };
  return isUpdated ? (
    <CaseDetailsForm cases={cases} />
  ) : (
    <Fragment>
      <Card>
        <CardHeader className="text-center fs-3 text-dark">
          Case Details Update Page
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="2 mb-3">
                <Label className="form-label" for="year">
                  Year <span className="text-danger">*</span>
                </Label>
                <select
                  name="year"
                  id="year"
                  autoComplete="off"
                  className="form-select  bg-light text-dark"
                  {...register("year", { required: true })}
                >
                  <option value="">Select Year</option>
                  {yearDetails.map((eachYearDetails) => (
                    <option
                      key={eachYearDetails.id}
                      value={eachYearDetails.year}
                    >
                      {eachYearDetails.year}
                    </option>
                  ))}
                </select>
                <span className="d-block text-danger">
                  {errors.year && "Year is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="2 mb-3">
                <Label className="form-label" for="month">
                  Month <span className="text-danger">*</span>
                </Label>
                {/* <input
									className="form-control"
									id="month"
									type="text"
									placeholder="Month"
									name="month"
									{...register("month", { required: true })}
								/> */}
                <select
                  name="month"
                  id="month"
                  autoComplete="off"
                  className="form-select  bg-light text-dark"
                  {...register("month", { required: true })}
                >
                  <option value="" disabled>
                    Select Month
                  </option>
                  <option value="JANUARY">January</option>
                  <option value="FEBRUARY">February</option>
                  <option value="MARCH">March</option>
                  <option value="APRIL">April</option>
                  <option value="MAY">May</option>
                  <option value="JUNE">June</option>
                  <option value="JULY">July</option>
                  <option value="AUGUST">August</option>
                  <option value="SEPTEMBER">September</option>
                  <option value="OCTOBER">October</option>
                  <option value="NOVEMBER">November</option>
                  <option value="DECEMBER">December</option>
                </select>
                <span className="d-block text-danger">
                  {errors.month && "Month is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="2 mb-3">
                <Label className="form-label" for="manDays">
                  Man Days <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark"
                  id="manDays"
                  autoComplete="off"
                  value={currentValue}
                  type="text"
                  placeholder="Man Days"
                  name="manDays"
                  {...register("manDays", { required: true })}
                  onChange={(event) => checkValue(event)}
                />
                <span className="d-block text-danger">
                  {errors.manDays && "Man Days is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="3 mb-3">
                <Label className="form-label" for="epfTransactionNumber">
                  EPF Transaction Number <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark"
                  id="epfTransactionNumber"
                  type="text"
                  autoComplete="off"
                  placeholder="EPF Transaction Number"
                  name="epfTransactionNumber"
                  {...register("epfTransactionNumber", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.epfTransactionNumber &&
                    "EPF Transaction Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="3 mb-3">
                <Label className="form-label" for="esiTransactionNumber">
                  ESI Transaction Number <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control  bg-light text-dark"
                  id="esiTransactionNumber"
                  type="text"
                  autoComplete="off"
                  placeholder="ESI Transaction Number"
                  name="esiTransactionNumber"
                  {...register("esiTransactionNumber", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.esiTransactionNumber &&
                    "ESI Transaction Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CaseDetailsUpdateForm;
