import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import CaseViewModal from "./modals/CaseViewModal";
import { Card, CardBody, Table } from "reactstrap";
import CaseCreationDetails from "./CaseCreationDetails";
import CaseRemarksDetails from "./CaseRemarksDetails";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";

const CaseDetailsTable = (props) => {
	const {
		caseDetails,
		handleEdit,
		type,
		caseCreationDetails,
		isViewClicked,
		handleDelete,
		caseId,
		caseRemarks,
	} = props;
	console.log("casedetail table.", props);
	console.log("Case Details Table...", caseRemarks);
	//state variables
	const [modal, setModal] = useState(false);
	const [modalTitle, setModalTitle] = useState(" Upload Case Documents");
	const [uploadCaseId, setUploadCaseId] = useState(null);
	const [viewDocuments, setViewDocuments] = useState(false);
	const [caseViewModal, setCaseViewModal] = useState(false);
	const [viewCaseId, setViewCaseId] = useState(null);
	const [uploadedDocuments, setUploadedDocuments] = useState([]);
	const [size, setSize] = useState("");
	const dispatch = useDispatch();

	const toggle = () => setModal(!modal);

	const viewToggle = () => setCaseViewModal(!caseViewModal);

	const handleUpload = (id) => {
		console.log("case details id....", id);
		toggle();
		setUploadCaseId(id);
		setSize("xl");
		dispatch(caseActions.getAllCaseDocuments(id)).then((data) => {
			console.log("uploaded files from redux.....", data);
			setUploadedDocuments(data.payload);
		});
	};

	const handleViewDocument = (caseDetailsId) => {
		console.log("Case details for view documents page...", caseDetailsId);
		setViewCaseId(caseDetailsId);
		setViewDocuments(true);
		viewToggle();
		setModalTitle("View Case Documents");
	};

	return (
		<Card>
			<CardBody>
				{isViewClicked && (
					<CaseCreationDetails caseCreationDetails={caseCreationDetails} />
				)}
				<Table bordered hover responsive className="mt-3 table">
					<thead className="table-secondary">
						<tr>
							<th>Year</th>
							<th>Month</th>
							<th>Man Days</th>
							<th>EPF Transaction Number</th>
							<th>ESI Transaction Number</th>
							{type === "view" ? (
								<th>View Documents</th>
							) : (
								<>
									<th>Upload Case Documents</th>
									<th>Edit Case Details</th>
									<th>Delete Case Details</th>
								</>
							)}
						</tr>
					</thead>
					{/* <tbody>
					{cases.map((eachCase) => (
						<tr key={eachCase.id}>
							<td>{eachCase.year}</td>
							<td>{eachCase.month}</td>
							<td>{eachCase.man_days}</td>
							<td>{eachCase.epf_trans_no}</td>
							<td>{eachCase.esi_trans_no}</td>
							<td>
								<Button
									variant="primary"
									size="sm"
									onClick={() => openModal(eachCase.id)}
								>
									Upload
								</Button>
							</td>
							<td>
								<Button
									variant="secondary"
									size="sm"
									onClick={() => handleEdit(eachCase.id)}
								>
									Edit
								</Button>
							</td>
							<td>
								<Button variant="danger" size="sm">
									Delete
								</Button>
							</td>
						</tr>
					))}
				</tbody> */}
					<tbody>
						{caseDetails.map((eachCase) => (
							<tr key={eachCase.id}>
								<td>{eachCase.year}</td>
								<td>{eachCase.month}</td>
								<td>{eachCase.man_days}</td>
								<td>{eachCase.epf_trans_no}</td>
								<td>{eachCase.esi_trans_no}</td>
								{type === "view" ? (
									<>
										<td>
											<Button
												variant="transparent"
												onClick={() => handleViewDocument(eachCase.id)}
											>
												<i className="fa fa-eye" />
											</Button>
										</td>
									</>
								) : (
									<>
										<td>
											<Button
												variant="transparent"
												size="sm"
												onClick={() => handleUpload(eachCase.id)}
											>
												<i className="icofont icofont-ui-file f-20 font-primary"></i>
											</Button>
										</td>
										<td>
											<Button
												variant="transparent"
												onClick={() => handleEdit(eachCase.id)}
											>
												<i className="icofont icofont-ui-edit f-20 font-dark"></i>
											</Button>
										</td>
										<td>
											<Button
												variant="transparent"
												onClick={() => handleDelete(eachCase.id)}
												size="sm"
											>
												<i className="icofont icofont-ui-delete f-20 font-danger"></i>
											</Button>
										</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</Table>
				{isViewClicked && (
					<CaseRemarksDetails caseRemarksDetails={caseRemarks} />
				)}
				{modal && (
					<ModalForm
						modal={modal}
						modalTitle={modalTitle}
						toggle={toggle}
						uploadCaseId={uploadCaseId}
						uploadedDocuments={uploadedDocuments}
						size="lg"
					/>
				)}
				{viewDocuments && (
					<CaseViewModal
						caseViewModal={caseViewModal}
						modalTitle={modalTitle}
						viewToggle={viewToggle}
						viewCaseId={viewCaseId}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export default CaseDetailsTable;
