import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardBody, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import LoaderComponent from "../common/Spinner/LoaderComponent";
import {
  circleActions,
  contractorActions,
  userActions,
  validationActions,
} from "../../_services";
import Select from "react-select";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const CreateUser = ({ toggle, userCreationToggle }) => {
  let loader;
  let roleValuesArray = [];
  const orgId = localStorage.getItem("userId");
  const entitlementCode = localStorage.getItem("entitilement");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const password = watch("password");
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [loadingElement, setLoadingElement] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [option, setOption] = useState([]);
  const Displayalert = () => {
    toast.success("User created successfully!");
    /* Toast.fire({
      text: "User created successfully",
      icon: "success",
    }); */
  };

  const roleOptions = [
    { value: "Auditor", label: "Auditor" },
    { value: "Auditor Admin", label: "Auditor Admin" },
    { value: "Accountant", label: "Accountant" },
    { value: "Master Contractor", label: "Master Contractor" },
  ];

  useEffect(() => {
    const getLocation = async () => {
      await dispatch(circleActions.getCircleNames()).then((data) => {
        if (data.payload) {
          console.log("circle value in redux -->", data);
          setOptions(data.payload);
        }
      });
    };
    getLocation();
  }, []);

  const otherRoleVAlidation = (e) => {
    const filteredOptions = roleOptions.filter(
      (option) => option.value !== e.target.value
    );
    console.log(filteredOptions);
    setOption(filteredOptions);
  };

  const handleSelectChange = (selectedOptions) => {
    console.log("first...", selectedOptions);

    // console.log("tempArray..", tempArray);
    setSelectedOptions(selectedOptions); // Update the selected options state
  };
  console.log("selectedOptions", selectedOptions);

  const mailVAlidation = async (e) => {
    let mailId = e.target.value;
    await dispatch(validationActions.mailVAlidation(mailId)).then((res) => {
      if (res.payload === "Already exist") {
        setValue("emailId", "");
        toast.warn("Email Id already exists", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const mobileNumberValidation = async (e) => {
    const validation = e.target.value;
    await dispatch(contractorActions.contactDetailsValidation(validation)).then(
      (response) => {
        console.log(response);
        if (response.payload === "Already exist") {
          console.log("no value");
          setValue("mbNo", "");
          toast.warn("Mobile Number already exist");
          /* Toast.fire({
          text: "Mobile number already exists",
          icon: "error",
        }); */
        } else {
          console.log("something went wrong");
        }
      }
    );
  };

  const userNameValidation = async (e) => {
    let username = e.target.value;
    await dispatch(validationActions.usernameValidation(username)).then(
      (res) => {
        if (res.payload === "Already exist") {
          setValue("username", "");
          toast.warn("username already exists", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const onSubmit = (data) => {
    setLoadingElement(true);
    roleValuesArray = selectedOptions.map((item) => item.value);
    console.log("TYPES OF DATA", typeof data);
    console.log("roleValuesArray...", roleValuesArray);
    // data = { ...data, roleValuesArray };
    if (data) {
      const request = {
        first_name: data.fName.toUpperCase(),
        last_name: data.lName.toUpperCase(),
        email_id: data.emailId.toUpperCase(),
        mobile_number: data.mbNo,
        designation: data.Desigination.toUpperCase(),
        primaryRoles: data.role.toUpperCase(),
        otherRoles: roleValuesArray,
        user_name: data.username,
        password: data.password,
        is_primary: true,
      };
      console.log("user info -->", request);
      dispatch(userActions.createUser(request)).then((data) => {
        console.log(data);
        if (data.type === "/createUser/fulfilled") {
          userCreationToggle();
          Displayalert();
          toggle();
        }
      });
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom01">
                  First Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom01"
                  autoComplete="off"
                  type="text"
                  placeholder="First Name"
                  name="fName"
                  {...register("fName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.fName && "First name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom02">
                  Last Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom02"
                  autoComplete="off"
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  {...register("lName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.lName && "Last Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom03">
                  Email id<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom03"
                  autoComplete="off"
                  type="email"
                  placeholder="Email id"
                  name="emailId"
                  {...register("emailId", { required: true })}
                  onBlur={(e) => mailVAlidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.emailId && "Email Id  is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>

              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom04">
                  Mobile Number<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom04"
                  autoComplete="off"
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Mobile Number"
                  name="mbNo"
                  maxlength="10"
                  {...register("mbNo", { required: true })}
                  onBlur={(e) => mobileNumberValidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.mbNo && "Mobile number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom05">
                  Desigination<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom05"
                  autoComplete="off"
                  name="Desigination"
                  type="text"
                  placeholder="Desigination"
                  {...register("Desigination", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.Desigination && "Desigination is required."}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom06">
                  Primary Role<span className="text-danger">*</span>
                </Label>
                <select
                  className="form-select bg-light text-dark"
                  autoComplete="off"
                  id="validationCustom06"
                  name="role"
                  {...register("role", { required: true })}
                  onChange={(e) => {
                    otherRoleVAlidation(e);
                  }}
                >
                  {entitlementCode === "AA001" ? (
                    <>
                      <option value="">Choose Role </option>
                      <option value="Auditor">Auditor </option>
                    </>
                  ) : (
                    <>
                      <option value="">Choose Role </option>
                      <option value="Auditor Admin">Auditor Admin </option>
                      <option value="Auditor">Auditor </option>
                      <option value="Accountant">Accountant</option>
                    </>
                  )}
                </select>
                <span className="d-block text-danger">
                  {errors.role && "Role  is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom06">
                  Other Roles<span className="text-danger"></span>
                </Label>

                <Select
                  options={option}
                  id="validationCustom06"
                  autoComplete="off"
                  value={selectedOptions}
                  onChange={handleSelectChange}
                  className="basic-multi-select bg-light text-dark"
                  classNamePrefix="select"
                  isMulti
                />
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  User Name<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="text"
                  placeholder="User Name"
                  name="username"
                  {...register("username", { required: true })}
                  onBlur={(e) => userNameValidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.username && "User Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  Password<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="password"
                  placeholder="password"
                  name="password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  })}
                />
                <span className="d-block text-danger">
                  {errors.password && errors.password.message}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  Confirm Password<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="password"
                  placeholder="Confirm Password"
                  name="conpassword"
                  {...register("conpassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  })}
                />
                <span className="d-block text-danger">
                  {errors.conpassword && "Confirm password is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>

            {loadingElement ? (
              <LoaderComponent />
            ) : (
              <Button attrBtn={{ color: "primary" }}>{"Submit"}</Button>
            )}
            {/* <Button attrBtn={{ color: "primary" }}>{"Submit"}</Button> */}
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CreateUser;
