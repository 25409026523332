import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "Reports";
console.log("counterss");
function createInitialState() {
	console.log("1");
	return {
		Reports: [],
		error: null,
		loader: false,
	};
}

function createReducers() {
	function toggleAuthPopup(state, { payload }) {
		state.checkPopup = payload;
	}
	function updateAuthMessage(state, { payload }) {
		state.unAuthMessage = payload;
	}
	return {
		toggleAuthPopup,
		updateAuthMessage,
	};
}

function createExtraActions() {
	console.log("2");
	const baseUrl = `${process.env.REACT_APP_API_URL}`;

	function getInvoiceReport() {
		return createAsyncThunk(
			`/getInvoiceReport`,
			async ({ reqStartDateFormat, reqEndDateFormat, action }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/excelReports/invoiceReports?startDate=${reqStartDateFormat}&endDate=${reqEndDateFormat}&action=${action}`
				)
		);
	}

	function getDatewiseReports() {
		return createAsyncThunk(
			`/excelReports/dateWise`,
			async ({ startDateFormat, endDateFormat, circleId, svpStatus }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/excelReports/dateWise?startDate=${startDateFormat}&endDate=${endDateFormat}&circleId=${circleId}&svpStatus=${svpStatus}`
				)
		);
	}

	return {
		getInvoiceReport: getInvoiceReport(),
		getDatewiseReports: getDatewiseReports(),
	};
}

function createExtraReducers() {
	console.log("7");
	function getInvoiceReport() {
		let { pending, fulfilled, rejected } = extraActions.getInvoiceReport;

		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getInvoiceReport _service--> " + action.payload);
				state.Reports = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getDatewiseReports() {
		let { pending, fulfilled, rejected } = extraActions.getDatewiseReports;

		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getDatewiseReports_service--> " + action.payload);
				state.Reports = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}

	return {
		...getInvoiceReport(),
		...getDatewiseReports(),
	};
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const reportsActions = { ...slice.actions, ...extraActions };
export const reportsReducer = slice.reducer;
