import React, { useEffect, useState } from "react";
import myImage from "../assets/UPI_logo_PNG_lyp5s5.png";
import { Form, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../common/Spinner/LoaderComponent";
import { useDispatch } from "react-redux";
import { caseActions } from "../../../_services";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const CasePaymentModal = ({ toggle, paymentDetails }) => {
  console.log("Payment Details........", paymentDetails);
  const caseId = localStorage.getItem("caseId");
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [emailOrderBtnClickCount, setEmailOrderBtnClickCount] = useState(0);
  const disabledToggle = () => setDisabled(!disabled);
  const dispatch = useDispatch();

  const {
    transactionRefNumber,
    totalMandays,
    paymentToBeMade,
    paymentQr,
    payeeVPA,
    payeeName,
    cgstAmount,
    grossAmount,
    igstAmount,
    sgstAmount,
    submittedDate,
    overallMandays,
    svcProviderId,
  } = paymentDetails;
  console.log("Payment Details....", paymentDetails);
  console.log("Payment to be made.....", paymentToBeMade);
  console.log("total man days.....", totalMandays);
  console.log("overall man days......", overallMandays);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const updateCaseStatus = () => {
    const reqBody = {
      svcProviderStatus: "UNDER REVIEW",
      auditorStatus: "PENDING",
    };
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
      toast.success("Your case has been submitted");
      navigate(`${process.env.PUBLIC_URL}/masterCase/viewAll`);
    });
  };

  const onSubmit = (data) => {
    setDisabled(true);
    if (data !== "") {
      const { paymentRefNo, paymentDate } = data;
      const reqBody = {
        caseId: caseId,
        accountsStatus: "PENDING",
        svcProviderStatus: "SUBMITTED",
        svcProviderId: svcProviderId,
        submittedDate: submittedDate,
        paymentRefNo: paymentRefNo,
        paymentRefDate: paymentDate,
        grossAmount: grossAmount,
        cgstAmount: cgstAmount,
        sgstAmount: sgstAmount,
        igstAmount: igstAmount,
        netAmount: Math.round(
          grossAmount + cgstAmount + sgstAmount + igstAmount
        ),
        overallMandays: overallMandays,
      };
      dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then(
        (data) => {
          console.log(data);
          setDisabled(false);
          toggle();
          toast.success("Your case has been submitted!");
          navigate(`${process.env.PUBLIC_URL}/masterCase/viewAll`);
        }
      );
      reset();
    } else {
      errors.showMessages();
    }
  };

  const handlePrintOrderClick = (e) => {
    const documentType = "demandforpayment";
    dispatch(caseActions.getDocumentPath({ caseId, documentType })).then(
      (resData) => {
        const { document_path } = resData.payload;
        console.log("document_path.......->", document_path);
        //   downloadCertificate(document_path).then((response) => {
        // const { data } = resData;
        //   console.log("response...", data);
        //   const blob = new Blob([data], { type: "application/pdf" });
        //   const url = URL.createObjectURL(blob);
        //   const newTab = window.open();
        //   newTab.document.open();
        //   newTab.document.write(`
        // 			  <html>
        // 			    <body>
        // 			      <embed src="${url}" height="100%" width="100%" type="application/pdf"/>
        // 			    </body>
        // 			  </html>
        // 			`);
        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
											<html>
											  <body>
												<embed src="${document_path}" height="100%" width="100%" type="application/pdf"/>
											  </body>
											</html>
										  `);
        newTab.document.close();
        //   });
      }
    );
  };

  const handleEmailOrderClick = (e) => {
    // disabledToggle();
    setDisabled(true);
    const documentType = "demandforpayment";
    dispatch(caseActions.triggerEmailOrder({ caseId, documentType })).then(
      (data) => {
        console.log("data", data.payload);
        if (data.payload === true) {
          toast.success("Email sent successfully!");
          demandGenCount();
          setDisabled(false);
        } else if (data === false) {
          toast.error("Email not send");
          /* Toast.fire({
          text: "Email not sent.",
          icon: "error",
        }); */
          disabledToggle();
        } else {
          toast.error("Error occured in sending the mail");
          /* Toast.fire({
          text: "Error occured in sending the mail",
          icon: "error",
        }); */
          disabledToggle();
        }
      }
    );
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    toggle();
  };

  const handleNoPaymentChange = () => {
    console.log("Ok button clicked....");
    updateCaseStatus();
    toggle();
  };

  const handlePaymentToReturn = () => {
    console.log("Payment to be returned ok button clicked.");
    console.log(
      "Overall man Days ...... in payment to be returned",
      overallMandays
    );
    const reqBody = {
      accountsStatus: "PAYMENT PENDING",
      svcProviderStatus: "UNDER REVIEW",
      auditorStatus: "PENDING",
      returnAmount: -paymentToBeMade,
      manDays: overallMandays,
    };
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then(
      (response) => {
        console.log("response......", response);
        toggle();
        navigate(`${process.env.PUBLIC_URL}/case/viewAll`);
      }
    );
  };

  const demandGenCount = async () => {
    console.log("Case id ..............", caseId);
    console.log("I'm an in demandGenCount");
    const response = await dispatch(
      caseActions.getDemandGenCount(caseId)
    ).catch((error) => {
      console.log("Error...", error);
    });
    const { payload } = response;
    setEmailOrderBtnClickCount(payload);
  };

  useEffect(() => {
    demandGenCount();
  }, [emailOrderBtnClickCount]);

  return (
    <>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex justify-content-evenly">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td>TRANSACTION REF.NO.</td>
                <td>
                  <strong>:</strong>
                </td>
                <td>{transactionRefNumber}</td>
              </tr>
              <tr>
                <td>TOTAL MAN DAYS</td>
                <td>
                  <strong>:</strong>
                </td>
                <td>{+overallMandays}</td>
              </tr>
              <tr>
                {totalMandays !== null && paymentToBeMade >= 0 ? (
                  <td>PENDING PAYMENT TO BE MADE</td>
                ) : totalMandays !== null && paymentToBeMade < 0 ? (
                  <td>AMOUNT TO BE RETURNED</td>
                ) : (
                  <td>PAYMENT TO BE MADE</td>
                )}
                <td>
                  <strong>:</strong>
                </td>
                {paymentToBeMade >= 0 ? (
                  <td>{paymentToBeMade}</td>
                ) : (
                  <td>{-paymentToBeMade}</td>
                )}
              </tr>
              {paymentToBeMade > 0 ? (
                <>
                  <tr>
                    <td>PAYMENT REF.NO.</td>
                    <td>
                      <strong>:</strong>
                    </td>

                    <td>
                      {/* <Form
										className="needs-validation"
										noValidate=""
										onSubmit={handleSubmit(onSubmit)}
									> */}
                      <input
                        className="form-control border-dark form-control-sm rounded-2 bg-light text-dark"
                        id="validationCustom01"
                        autoComplete="off"
                        type="text"
                        name="paymentRefNo"
                        {...register("paymentRefNo", { required: true })}
                      />
                      <span className="d-block text-danger">
                        {errors.paymentRefNo && " Add Payment Ref.No."}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                      {/* <CaseSubmitButtons /> */}
                      {/* </Form> */}
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>PAYMENT REF.DATE.</td>
                    <td>
                      <strong>:</strong>
                    </td>

                    <td>
                      {/* <Form
										className="needs-validation"
										noValidate=""
										onSubmit={handleSubmit(onSubmit)}
									> */}
                      <input
                        className="form-control border-dark form-control-sm rounded-2 bg-light text-dark"
                        id="validationCustom02"
                        autoComplete="off"
                        type="date"
                        name="paymentDate"
                        {...register("paymentDate", { required: true })}
                      />
                      <span className="d-block text-danger">
                        {errors.paymentDate && " Add Payment Ref.Date."}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                      {/* <CaseSubmitButtons /> */}
                      {/* </Form> */}
                    </td>
                  </tr>
                </>
              ) : paymentToBeMade < 0 ? (
                <tr>
                  <td>
                    Your amount of{" "}
                    <span className="font-success f-16 f-w-700">
                      Rs.{-paymentToBeMade}
                    </span>{" "}
                    will be returned. Click{" "}
                    <span className="font-primary f-w-500 f-16">OK</span> to
                    proceed.
                  </td>
                </tr>
              ) : (
                <td>
                  No need to pay any extra amount. Click
                  <span className="font-primary f-w-500 f-16">OK</span> to
                  proceed.
                </td>
              )}
            </tbody>
          </table>
          {paymentToBeMade > 0 ? (
            <table className="table table-borderless">
              <tbody>
                <tr className="d-flex justify-content-center">
                  <td>
                    <div>
                      <div className="text-center">
                        <img src={myImage} alt="upi-pay-logo" width="65px" />
                        <figcaption>accepted here</figcaption>
                        <hr />
                      </div>
                      <div>
                        <h6>{payeeName}</h6>
                        <p className="text-center">{payeeVPA}</p>
                      </div>
                      <div className="text-center">
                        <img src={paymentQr} alt="sample-image" width="150px" />
                        <figcaption>
                          <strong>SCAN FOR MAKING PAYMENT</strong>
                        </figcaption>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
        <ModalFooter>
          {disabled === true ? (
            <LoaderComponent />
          ) : paymentToBeMade > 0 ? (
            <div className="d-flex flex-row justify-content-around">
              <Button
                onClick={(e) => handlePrintOrderClick(e)}
                size="sm"
                className="me-2"
              >
                Print Order
              </Button>
              {emailOrderBtnClickCount > 0 ? (
                <Button
                  onClick={(e) => handleEmailOrderClick(e)}
                  size="sm"
                  className="me-2"
                  variant="secondary"
                >
                  Email Sent
                  <span className="badge rounded-pill badge-light text-dark ms-1">
                    {emailOrderBtnClickCount}
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => handleEmailOrderClick(e)}
                  size="sm"
                  className="me-2"
                  disabled={disabled}
                >
                  Email Order
                </Button>
              )}
              <Button type="submit" size="sm" className="me-2">
                OK
              </Button>
              <Button
                onClick={(e) => handleCancelClick(e)}
                size="sm"
                className="me-2"
              >
                Cancel
              </Button>
            </div>
          ) : paymentToBeMade < 0 ? (
            <div className="d-flex flex-row justify-content-around">
              <Button
                onClick={handlePaymentToReturn}
                size="sm"
                className="me-2"
              >
                OK
              </Button>
              <Button
                onClick={(e) => handleCancelClick(e)}
                size="sm"
                className="me-2"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-around">
              <Button
                onClick={handleNoPaymentChange}
                size="sm"
                className="me-2"
              >
                OK
              </Button>
              <Button
                onClick={(e) => handleCancelClick(e)}
                size="sm"
                className="me-2"
              >
                Cancel
              </Button>
            </div>
          )}
        </ModalFooter>
      </Form>
    </>
  );
};

export default CasePaymentModal;
