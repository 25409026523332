import React from "react";
import Table from "react-bootstrap/Table";

const SeviceProviderDetails = ({ svcDetails }) => {
	console.log("Service Provider Details ...", svcDetails);
	const { establishmentName, epfNumber, esiNumber, labourNumber, emailId } =
		svcDetails;
	return (
		<>
			<h6 className="text-center">Contractor Details</h6>
			<Table bordered hover responsive className="mb-3 mt-2">
				<thead className="table-secondary">
					<tr>
						<th>Contractor Firm</th>
						<th>EPF Number</th>
						<th>ESI Number</th>
						<th>License Number</th>
						<th>Email Id</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{establishmentName}</td>
						<td>{epfNumber}</td>
						<td>{esiNumber}</td>
						<td>{labourNumber}</td>
						<td>{emailId}</td>
					</tr>
				</tbody>
			</Table>
		</>
	);
};

export default SeviceProviderDetails;
