import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
//import { Contractor } from "../../AbstractElements";
import { Button } from "reactstrap";
//import HeaderCard from '../../Components/Common/Component/HeaderCard'
import DataTableComponent from "./ViewUser";
import ModalForm from "./modals/modal";

const ViewContractor = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modalTitle = "User creation";
  const type = "add";
  const [searchText, setSearchText] = useState("");
  const [userCreation, setUserCreation] = useState(false);

  const userCreationToggle = () => {
    setUserCreation(!userCreation);
  };

  const handleAdd = () => {
    toggle();
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const entitlementCode = localStorage.getItem("entitilement");

  return (
    <Fragment>
      <Breadcrumbs parent="Master's" title="users" mainTitle="users" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex flex-row justify-content-end  mb-3">
                  {entitlementCode === "AG001" ||
                  entitlementCode === "SVP001" ||
                  entitlementCode === "AU001" ||
                  entitlementCode === "AC001" ||
                  entitlementCode === "CA001" ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      className="mb-3"
                      onClick={handleAdd}
                    >
                      Add New
                    </Button>
                  )}
                </div>
                {modal ? (
                  <ModalForm
                    modal={modal}
                    toggle={toggle}
                    type={type}
                    title={modalTitle}
                    userCreationToggle={userCreationToggle}
                  />
                ) : (
                  ""
                )}
                <DataTableComponent
                  searchText={searchText}
                  userCreation={userCreation}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ViewContractor;
