import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Card, CardBody, Form, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import SearchBar from "../common/SearchBar";
import { caseActions, circleActions, contractorActions } from "../../_services";
import { useDispatch } from "react-redux";
import { Spinner } from "../../AbstractElements";

const UserComponent = (props) => {
  const entitlementCode = localStorage.getItem("entitilement");
  const navigate = useNavigate();
  const [Case, setCase] = useState([]);
  const [caseDetail, setcaseDetail] = useState([]);
  const [caseCreationDetail, setCaseCreationDetail] = useState({});
  const [svcDetails, setSvcDetails] = useState({});
  const [caseRemarks, setCaseRemarks] = useState([]);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("view");
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const modalTitle = "Case Details";
  const { searchText } = props;
  const toggle = () => setModal(!modal);
  let filteredData = null;
  let labourNumber = null;
  let columns = null;
  const dispatch = useDispatch();
  const [loadingElement, setLoadingElement] = useState(false);
  let loadingContent;
  const loaderFunc = () => {
    // setTimeout(loaderFunc, 1000);
    setLoadingElement(false);
  };

  if (loadingElement) {
    loadingContent = (
      <div className="loader-box">
        <Spinner attrSpinner={{ className: "loader-2" }} />
      </div>
    );
  }
  const clearSearchFilter = () => {
    setClearFilter(!clearFilter);
  };
  const handleSearch = (startDate, endDate) => {
    const SearchbarRequest = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(caseActions.filteredCase(SearchbarRequest)).then(async (data) => {
      const casesWithCircleName = [];
      const casesWithContractorName = [];
      if (data.payload) {
        const initialCase = data.payload;
        console.log("initial case--------------------------->", initialCase);
        if (entitlementCode === "AU001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.auditor_status === "PENDING" ||
              caseObject.case_status.auditor_status === "ADMIN RETURN"
            ) {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "AC001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.Accounts_Status === "INVOICE GENERATED"
            ) {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "AA001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.auditor_admin_status === "PENDING" ||
              caseObject.case_status.auditor_status === "RETURN"
            ) {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "ORG001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.svc_provider_status === "UNDER REVIEW" ||
              caseObject.case_status.svc_provider_status === "AUDIT CLEARED" ||
              caseObject.case_status.svc_provider_status === "AUDIT FAILED"
            ) {
              return true;
            }
            return false;
          });
        }
        await dispatch(circleActions.getAllCircle()).then((data) => {
          for (let eachCase of filteredData) {
            const orgLocationId = eachCase.org_location_id;
            for (let eachCircle of data.payload) {
              if (orgLocationId === eachCircle.id) {
                const newCase = {
                  ...eachCase,
                  name: eachCircle.name,
                };
                casesWithCircleName.push(newCase);
                console.log("circle Names....", casesWithCircleName);
              }
            }
          }
        });
        await dispatch(contractorActions.getAllContractor()).then((data) => {
          console.log("svc details....", data);
          for (let eachCase of casesWithCircleName) {
            const serviceProviderId = eachCase.svc_provider_id;
            for (let eachServiceProvider of data.payload) {
              const { id, establishment_name } = eachServiceProvider;
              if (id === serviceProviderId) {
                const newCase = {
                  ...eachCase,
                  establishmentName: establishment_name,
                };
                casesWithContractorName.push(newCase);
              }
            }
          }
          console.log("Case......>", casesWithContractorName);
          setCase(casesWithContractorName);
        });
      }
    });
  };

  const handleDocumentDownload = async (caseId, caseid, docType) => {
    console.log("Row", caseId);
    console.log("caseId", caseid);
    const documentType = docType;
    try {
      await dispatch(
        caseActions.getDocumentPath({ caseId, documentType })
      ).then(async (data) => {
        console.log("response. redux....", data);
        const { document_path, document_name } = data.payload;
        console.log("document_path", document_path);
        const filePath = document_path;
        const documentName = document_name;

        const fetchResponse = await fetch(filePath);
        const blob = await fetchResponse.blob();

        // Create a temporary link and trigger download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // Set the correct Content-Type header for PDF
        link.type = "application/pdf";

        if (docType === "invoice") {
          link.download = `${caseid}_Invoice.pdf`;
        } else {
          link.download = `${caseid}_Certificate.pdf`;
        }
        link.click();
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    setLoadingElement(true);
    const casesWithCircleName = [];
    const casesWithContractorName = [];
    const getdata = async () => {
      await dispatch(caseActions.getAllCase()).then(async (data) => {
        if (data.payload) {
          const initialCase = data.payload;
          console.log(
            "initial case----------------------------->",
            initialCase
          );
          if (entitlementCode === "AU001") {
            filteredData = initialCase.filter((caseObject) => {
              if (caseObject.case_status.auditor_status === "RETURN") {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "AA001") {
            filteredData = initialCase.filter((caseObject) => {
              if (
                caseObject.case_status.svc_provider_status === "AUDIT Return" ||
                caseObject.case_status.auditor_admin_status === "RETURN"
              ) {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "AC001") {
            filteredData = initialCase.filter((caseObject) => {
              if (
                caseObject.case_status.Accounts_Status === "INVOICE GENERATED"
              ) {
                return true;
              }
              return false;
            });
          }
          console.log("filtered Data...", filteredData);
          await dispatch(circleActions.getAllCircle()).then((data) => {
            console.log("circles data from redux....", data);
            for (let eachCase of filteredData) {
              const orgLocationId = eachCase.org_location_id;
              for (let eachCircle of data.payload) {
                if (orgLocationId === eachCircle.id) {
                  const newCase = {
                    ...eachCase,
                    name: eachCircle.name,
                  };
                  casesWithCircleName.push(newCase);
                  console.log("circle Names....", casesWithCircleName);
                }
              }
            }
          });
          await dispatch(contractorActions.getAllContractor()).then((data) => {
            console.log("svc details from redux....", data);
            for (let eachCase of casesWithCircleName) {
              const serviceProviderId = eachCase.svc_provider_id;
              for (let eachServiceProvider of data.payload) {
                const { id, establishment_name } = eachServiceProvider;
                if (id === serviceProviderId) {
                  const newCase = {
                    ...eachCase,
                    establishmentName: establishment_name,
                  };
                  casesWithContractorName.push(newCase);
                }
              }
            }
            setCase(casesWithContractorName);
            if (data) {
              setTimeout(loaderFunc, 1000);
            }
          });
        }
      });
    };
    getdata();
  }, [clearFilter]);
  console.log("case -->", Case);

  const handleViewClick = async (caseId) => {
    console.log("caseId...", caseId);
    // e.preventDefault();
    console.log("caseId in view click...", caseId);
    setType("view");
    await dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
      if (data.payload) {
        console.log("case details response from redux....", data);
        setcaseDetail(data.payload);
      }
      dispatch(caseActions.getCaseById(caseId)).then((data) => {
        console.log("case creation response from redux....", data);
        const { svc_provider_id, org_location_id } = data.payload;
        console.log("svc id", svc_provider_id);
        dispatch(contractorActions.getContractorById(svc_provider_id)).then(
          (data) => {
            const { establishment_name, epf_number, esi_number } =
              data.payload[0];
            const { contact_detail } = data.payload[1][0];
            const orgSvcMap = data.payload[2];
            labourNumber = orgSvcMap.filter((labour_number) => {
              if (labour_number.org_location_id === org_location_id) {
                return true;
              }
              return false;
            });
            const svcData = {
              establishmentName: establishment_name,
              epfNumber: epf_number,
              esiNumber: esi_number,
              labourNumber: labourNumber[0].labour_number,
              emailId: contact_detail,
            };
            setSvcDetails(svcData);
          }
        );
        setCaseCreationDetail(data.payload);
      });
    });
    dispatch(caseActions.getCaseRemarks(caseId)).then((data) => {
      console.log("case remarks reducx", data);
      if (data.payload) {
        setCaseRemarks(data.payload);
      } else {
        console.log("error from remarks");
      }
    });
    toggle();
    setIsViewClicked(true);
  };

  const ShowFilteredCases = (e) => {
    e.preventDefault();
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;

    if (startDate == "" && endDate == "") {
      toast.error("please select the value");
      /* Toast.fire({
        text: "Please select the value",
        icon: "error",
      }); */
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with leading zeros if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month (months are zero-based) and pad with leading zeros if necessary
    const year = date.getFullYear().toString(); // Get the full year

    return `${day}/${month}/${year}`; // Format the date as "dd/mm/yyyy"
  };
  // const eyeIconFormatter = (id) => (
  // 	<span onClick={(e) => handleViewClick(e, id)}>
  // 		<i className="fa fa-eye"></i>
  // 	</span>
  // );

  const caseIdFormatter = (cell, row, rowIndex) => {
    return (
      <a
        style={{ color: "rgb(13,110,253)", textDecoration: "underline" }}
        onClick={() => handleViewClick(row.id)}
      >
        {row.case_id}
      </a>
    );
  };

  const rowEvents = {
    // onClick: (e, row, rowIndex) => {
    // 	handleViewClick(e, row.id);
    // },
    onMouseEnter: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "pointer";
    },
    onMouseLeave: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "default";
    },
  };

  if (entitlementCode === "AC001") {
    columns = [
      {
        text: "S.No",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
        formatter: caseIdFormatter,
      },
      {
        dataField: "createdAt",
        text: "Created Date",
        filter: dateFilter({
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "invoice_detail.payment_ref_no",
        text: "Pay Ref Number",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Pay Ref Number ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "15vw",
        },
      },
      {
        dataField: "case_status.auditor_admin_status",
        text: "Admin Status",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Status ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "9vw",
        },
        formatter: (cell, row) => {
          if (row.case_status.auditor_admin_status === "APPROVED") {
            return (
              <span className="badge bg-success">
                {row.case_status.auditor_admin_status}
              </span>
            );
          } else if (row.case_status.auditor_admin_status === "PENDING") {
            return (
              <span className="badge bg-light txt-dark">
                {row.case_status.auditor_admin_status}
              </span>
            );
          } else {
            return (
              <span className="badge bg-danger">
                {row.case_status.auditor_admin_status}
              </span>
            );
          }
        },
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (cell, row) => {
          if (
            row.case_status.Accounts_Status === "INVOICE GENERATED" &&
            entitlementCode === "AC001"
          ) {
            return (
              <>
                <Button
                  size="lg"
                  variant="transparent"
                  onClick={() =>
                    handleDocumentDownload(
                      row.id,
                      row.case_certificate_invoice_map_model.invoice_number,
                      "invoice"
                    )
                  }
                >
                  <i className="fa fa-download text-primary" />
                </Button>
              </>
            );
          }
        },
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
    ];
  } else {
    columns = [
      {
        text: "S.No",
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
          width: "3vw",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        formatter: caseIdFormatter,
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "20vw",
        },
      },
      {
        dataField: "createdAt",
        text: "Created Date",
        filter: dateFilter({
          //   style: { display: "inline-grid" },
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "18vw",
        },
      },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "35vw",
        },
      },
      {
        dataField: "name",
        text: "Circle",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Circle...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px",
          width: "18vw",
        },
      },

      // {
      // 	dataField: "id",
      // 	text: "View Case",
      // 	headerAlign: "center",
      // 	headerStyle: {
      // 		backgroundColor: "#7366ff",
      // 		color: "white",
      // 		padding: "15px 7px",
      // 	},
      // 	formatter: eyeIconFormatter, // Use the custom formatter for this column
      // },
    ];
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "All",
        value: Case.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  console.log("case----------------->", Case);
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={(e) => ShowFilteredCases(e)}>
        <Col sm="12">
          <Card>
            {/* <HeaderCard title={TaskDetails} /> */}
            <CardBody className="grid-showcase grid-align">
              <SearchBar
                clearSearchFilter={clearSearchFilter}
                handleSearch={handleSearch}
              />
              {/* <SearchButton /> */}
            </CardBody>
          </Card>
        </Col>
        <Row>
          <Col>
            {loadingElement ? (
              loadingContent
            ) : (
              <BootstrapTable
                keyField="id"
                data={Case}
                columns={columns}
                headerClasses="header-class"
                filter={filterFactory()}
                hover
                rowEvents={rowEvents}
                pagination={paginationFactory(options)}
              />
            )}
          </Col>
        </Row>
      </Form>
      {modal ? (
        <ModalForm
          modal={modal}
          type={type}
          modalTitle={modalTitle}
          value={caseDetail}
          toggle={toggle}
          caseDetail={caseDetail}
          caseCreationDetail={caseCreationDetail}
          caseRemarksDetails={caseRemarks}
          isViewClicked={isViewClicked}
          svcDetails={svcDetails}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default UserComponent;
