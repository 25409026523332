import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "Cases";
console.log("counterss");
function createInitialState() {
	console.log("1");
	return {
		Case: [],
		error: null,
		loader: false,
	};
}

function createReducers() {
	function toggleAuthPopup(state, { payload }) {
		state.checkPopup = payload;
	}
	function updateAuthMessage(state, { payload }) {
		state.unAuthMessage = payload;
	}
	return {
		toggleAuthPopup,
		updateAuthMessage,
	};
}

function createExtraActions() {
	console.log("2");
	const baseUrl = `${process.env.REACT_APP_API_URL}`;

	function createCase() {
		return createAsyncThunk(
			`/createCase`,
			async (reqBody) => await fetchAPIWapper.post(`${baseUrl}/case`, reqBody)
		);
	}

	function getAllCase() {
		console.log("3");
		return createAsyncThunk(
			`/getAllCase`,
			async () => await fetchAPIWapper.get(`${baseUrl}/case`)
		);
	}
	function getCaseById() {
		console.log("4");
		return createAsyncThunk(
			`/getCaseById`,
			async (caseId) => await fetchAPIWapper.get(`${baseUrl}/case/${caseId}`)
		);
	}
	function updateCaseById() {
		return createAsyncThunk(
			`/UpdateCaseById`,
			async ({ caseId, reqBody }) =>
				await fetchAPIWapper.put(`${baseUrl}/case/${caseId}`, reqBody)
		);
	}
	function filteredCase() {
		return createAsyncThunk(
			`/filteredCase`,
			async (reqBody) =>
				await fetchAPIWapper.post(`${baseUrl}/case/filterCase`, reqBody)
		);
	}
	function createCaseDetails() {
		return createAsyncThunk(
			`/createCaseDetails`,
			async (reqBody) =>
				await fetchAPIWapper.post(`${baseUrl}/caseDetails`, reqBody)
		);
	}
	function updateCaseDetails() {
		return createAsyncThunk(
			`/updateCaseDetails`,
			async ({ caseDetailId, reqBody }) =>
				await fetchAPIWapper.put(
					`${baseUrl}/caseDetails/${caseDetailId}`,
					reqBody
				)
		);
	}
	function getAllCaseDetails() {
		return createAsyncThunk(
			`/getAllCaseDetails`,
			async (caseId) =>
				await fetchAPIWapper.get(`${baseUrl}/caseDetails/${caseId}`)
		);
	}
	function getCaseDetailsById() {
		return createAsyncThunk(
			`/getCaseDetailsById`,
			async (caseDetailsId) =>
				await fetchAPIWapper.get(
					`${baseUrl}/caseDetails/getCaseDetail/${caseDetailsId}`
				)
		);
	}
	function getAllCaseDocuments() {
		return createAsyncThunk(
			`/getAllCaseDocuments`,
			async (caseDetailsId) =>
				await fetchAPIWapper.get(`${baseUrl}/caseDocuments/${caseDetailsId}`)
		);
	}
	function updateCaseDocumentById() {
		return createAsyncThunk(
			`/updateCaseDocumentById`,
			async ({ caseDetailsId, reqBody }) =>
				await fetchAPIWapper.put(
					`${baseUrl}/caseDocuments/${caseDetailsId}`,
					reqBody
				)
		);
	}
	function getCaseDocumentById() {
		return createAsyncThunk(
			`/getCaseDocumentById`,
			async ({ filePath }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/caseDocuments/view?path=${
						(encodeURIComponent(filePath),
						{
							responseType: "blob",
						})
					}`
				)
		);
	}
	function uploadCaseDocuments() {
		return createAsyncThunk(
			`/uploadCaseDocuments`,
			async (formData) =>
				// {
				//   console.table([...formData]);
				// }
				await fetchAPIWapper.post(`${baseUrl}/caseDocuments`, formData)
		);
	}

	function createCaseRemarks() {
		return createAsyncThunk(
			`/createCaseReamarks`,
			async (remarksInfo) =>
				await fetchAPIWapper.post(`${baseUrl}/remarks/create`, remarksInfo)
		);
	}

	function getCaseRemarks() {
		return createAsyncThunk(
			`/getCaseReamarks`,
			async (caseId) =>
				await fetchAPIWapper.get(`${baseUrl}/caseFlowHistory/remarks/${caseId}`)
		);
	}
	function getDocumentPath() {
		return createAsyncThunk(
			`/getDocumentPath`,
			async ({ caseId, documentType }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/certificateInvoice/${caseId}?documentType=${documentType}`
				)
		);
	}
	function caseStatusUpdate() {
		return createAsyncThunk(
			`/caseStatusUpdate`,
			async ({ caseId, reqBody }) =>
				await fetchAPIWapper.put(`${baseUrl}/caseStatus/${caseId}`, reqBody)
		);
	}
	function getPaymentDetails() {
		return createAsyncThunk(
			`/getPaymentDetails`,
			async (caseId) =>
				await fetchAPIWapper.get(`${baseUrl}/paymentCalculation/${caseId}`)
		);
	}
	function downloadUploadedDocument() {
		return createAsyncThunk(
			`/downloadUploadedDocument`,
			async (caseDetailsId) =>
				await fetchAPIWapper.get(`${baseUrl}/caseFileDownload/${caseDetailsId}`)
		);
	}
	function generateInvoiceDocument() {
		return createAsyncThunk(
			`/ generateInvoiceDocument`,
			async (reqBody) =>
				await fetchAPIWapper.post(
					`${baseUrl}/certificateInvoice/invoiceGen`,
					reqBody
				)
		);
	}
	function caseInvoiceNumber() {
		return createAsyncThunk(
			`/ caseInvoiceNumber`,
			async (caseId) =>
				await fetchAPIWapper.get(`${baseUrl}/caseTransInvoice/${caseId}`)
		);
	}
	function invoiceEmailTrigger() {
		return createAsyncThunk(
			`/ invoiceEmailTrigger`,
			async ({ caseId, documentType }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/emailAlerts/invoice/${caseId}?documentType=${documentType}`
				)
		);
	}
	function generateCertificate() {
		return createAsyncThunk(
			`/ generateCertificate`,
			async (reqBody) =>
				await fetchAPIWapper.post(
					`${baseUrl}/certificateInvoice/certificateGen`,
					reqBody
				)
		);
	}

	// to get year details in case details form
	function getYearDetails() {
		return createAsyncThunk(
			`/ caseDetailsYear`,
			async () => await fetchAPIWapper.get(`${baseUrl}/caseDetailsYear`)
		);
	}

	// To Post Payment Reference Number when Payment to be Returned
	function paymentToBeReturned() {
		return createAsyncThunk(
			`/ paymentTransaction`,
			async (reqBody) =>
				await fetchAPIWapper.post(`${baseUrl}/paymentTransaction`, reqBody)
		);
	}
	// To get case Flow Histrory
	function fetchCaseFlowHistory() {
		return createAsyncThunk(
			`/ caseFlowHistory`,
			async (caseId) =>
				await fetchAPIWapper.get(`${baseUrl}/caseFlowHistory/${caseId}`)
		);
	}

	function triggerEmailOrder() {
		return createAsyncThunk(
			`/triggerEmailOrder`,
			async ({ caseId, documentType }) =>
				await fetchAPIWapper.get(
					`${baseUrl}/emailAlerts/emailOrder/${caseId}?documentType=${documentType}`
				)
		);
	}

	function paymentFailedTransaction() {
		return createAsyncThunk(
			`/paymentFailedTransaction`,
			async (caseId) =>
				await fetchAPIWapper.get(`${baseUrl}/paymentTransaction/${caseId}`)
		);
	}

	function getDemandGenCount() {
		return createAsyncThunk(
			`/certificateInvoice/demandGenCount`,
			async (caseId) =>
				await fetchAPIWapper.get(
					`${baseUrl}/certificateInvoice/demandGenCount/${caseId}`
				)
		);
	}
	function updateSingleDocument() {
		return createAsyncThunk(
			`/single/documentId`,
			async ({ documentId, formData }) =>
				await fetchAPIWapper.put(
					`${baseUrl}/caseDocuments/single/${documentId}`,
					formData
				)
		);
	}
	return {
		createCase: createCase(),
		getAllCase: getAllCase(),
		getCaseById: getCaseById(),
		updateCaseById: updateCaseById(),
		filteredCase: filteredCase(),
		createCaseDetails: createCaseDetails(),
		updateCaseDetails: updateCaseDetails(),
		getAllCaseDetails: getAllCaseDetails(),
		getCaseDetailsById: getCaseDetailsById(),
		getAllCaseDocuments: getAllCaseDocuments(),
		updateCaseDocumentById: updateCaseDocumentById(),
		getCaseDocumentById: getCaseDocumentById(),
		uploadCaseDocuments: uploadCaseDocuments(),
		createCaseRemarks: createCaseRemarks(),
		getCaseRemarks: getCaseRemarks(),
		getDocumentPath: getDocumentPath(),
		caseStatusUpdate: caseStatusUpdate(),
		getPaymentDetails: getPaymentDetails(),
		downloadUploadedDocument: downloadUploadedDocument(),
		generateInvoiceDocument: generateInvoiceDocument(),
		caseInvoiceNumber: caseInvoiceNumber(),
		invoiceEmailTrigger: invoiceEmailTrigger(),
		generateCertificate: generateCertificate(),
		getYearDetails: getYearDetails(),
		paymentToBeReturned: paymentToBeReturned(),
		fetchCaseFlowHistory: fetchCaseFlowHistory(),
		triggerEmailOrder: triggerEmailOrder(),
		paymentFailedTransaction: paymentFailedTransaction(),
		getDemandGenCount: getDemandGenCount(),
		updateSingleDocument: updateSingleDocument(),
	};
}

function createExtraReducers() {
	console.log("7");
	function createCase() {
		let { pending, fulfilled, rejected } = extraActions.createCase;

		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("createCase _service--> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getAllCase() {
		console.log("8");
		let { pending, fulfilled, rejected } = extraActions.getAllCase;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getAllCase _service--> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getCaseById() {
		console.log("9");
		let { pending, fulfilled, rejected } = extraActions.getCaseById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getCAseById _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function updateCaseById() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.updateCaseById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("UpdeatCaseById _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function filteredCase() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.filteredCase;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("filteredCase _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function createCaseDetails() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.createCaseDetails;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("createCaseDetails _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				console.log("createCaseDetails _service --> " + action.error);
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function updateCaseDetails() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.updateCaseDetails;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("updateCaseDetails _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getAllCaseDetails() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getAllCaseDetails;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getAllCaseDetails _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getCaseDetailsById() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getCaseDetailsById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getCaseDetailsById _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getAllCaseDocuments() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getAllCaseDocuments;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getAllCaseDocuments _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function updateCaseDocumentById() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.updateCaseDocumentById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("updateCaseDocumentById _service --> " + action.payload);
				state.User = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getCaseDocumentById() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getCaseDocumentById;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getCaseDocumentById _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function uploadCaseDocuments() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.uploadCaseDocuments;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("uploadCaseDocuments _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function createCaseRemarks() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.createCaseRemarks;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("createCaseRemarks _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getCaseRemarks() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getCaseRemarks;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getCaseRemarks _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getDocumentPath() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getDocumentPath;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getDocumentPath _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function caseStatusUpdate() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.caseStatusUpdate;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("caseStatusUpdate _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getPaymentDetails() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getPaymentDetails;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getPaymentDetails _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function downloadUploadedDocument() {
		console.log("10");
		let { pending, fulfilled, rejected } =
			extraActions.downloadUploadedDocument;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("downloadUploadedDocument _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function generateInvoiceDocument() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.generateInvoiceDocument;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("generateInvoiceDocument _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function caseInvoiceNumber() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.caseInvoiceNumber;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("caseInvoiceNumber _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function invoiceEmailTrigger() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.invoiceEmailTrigger;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("invoiceEmailTrigger _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function generateCertificate() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.generateCertificate;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("generateCertificate _service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getYearDetails() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getYearDetails;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getYearDetails_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function paymentToBeReturned() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.paymentToBeReturned;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("paymentToBeReturned_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function fetchCaseFlowHistory() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.fetchCaseFlowHistory;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("fetchCaseFlowHistory_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function triggerEmailOrder() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.triggerEmailOrder;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("triggerEmailOrder_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function paymentFailedTransaction() {
		console.log("10");
		let { pending, fulfilled, rejected } =
			extraActions.paymentFailedTransaction;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("paymentFailedTransaction_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function paymentFailedTransaction() {
		console.log("10");
		let { pending, fulfilled, rejected } =
			extraActions.paymentFailedTransaction;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("paymentFailedTransaction_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function getDemandGenCount() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.getDemandGenCount;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("getDemandGenCount_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	function updateSingleDocument() {
		console.log("10");
		let { pending, fulfilled, rejected } = extraActions.updateSingleDocument;
		return {
			[pending]: (state) => {
				state.error = null;
				state.loader = true;
			},
			[fulfilled]: (state, action) => {
				console.log("updateSingleDocument_service --> " + action.payload);
				state.Case = action.payload ? action.payload : {};
				state.loader = false;
			},
			[rejected]: (state, action) => {
				state.error = action.error;
				state.loader = false;
			},
		};
	}
	return {
		...createCase,
		...getAllCase,
		...getCaseById,
		...updateCaseById,
		...filteredCase,
		...createCaseDetails,
		...updateCaseDetails,
		...getAllCaseDetails,
		...getCaseDetailsById,
		...getAllCaseDocuments,
		...updateCaseDocumentById,
		...getCaseDocumentById,
		...uploadCaseDocuments,
		...createCaseRemarks,
		...getCaseRemarks,
		...getDocumentPath,
		...caseStatusUpdate,
		...getPaymentDetails,
		...downloadUploadedDocument,
		...generateInvoiceDocument,
		...caseInvoiceNumber,
		...invoiceEmailTrigger,
		...generateCertificate,
		...getYearDetails,
		...paymentToBeReturned,
		...fetchCaseFlowHistory,
		...triggerEmailOrder,
		...paymentFailedTransaction,
		...getDemandGenCount,
		...updateSingleDocument,
	};
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const caseActions = { ...slice.actions, ...extraActions };
export const caseReducer = slice.reducer;
