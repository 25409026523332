import React, { Fragment, useState } from "react";
import CommonModal from "./commonModal";
import CaseDocumentViewPage from "../caseDocumentViewPage";

const CaseViewModal = (props) => {
  const { caseViewModal, viewToggle, modalTitle, viewCaseId } = props;
  return (
    <Fragment>
      <CommonModal
        isOpen={caseViewModal}
        title={modalTitle}
        toggler={viewToggle}
        size="lg"
      >
        <CaseDocumentViewPage viewCaseId={viewCaseId} />
      </CommonModal>
    </Fragment>
  );
};

export default CaseViewModal;
