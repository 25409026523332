import React, { Fragment, useEffect, useState } from "react";
import ModalForm from "./modals/modal";
import { useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { contractorActions } from "../../_services";
import { Spinner } from "../../AbstractElements";

const ContractorComponent = (props) => {
  const { contractorCreation } = props;
  const [serviceProvider, setServiceProvider] = useState([]);
  const [serviceProviderDetail, setServiceProviderDetail] = useState([]);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("view");
  const [modalTitle, setModalTitle] = useState("Contractor details");
  const [contractorUpdate, setContractorUpdate] = useState(false);
  const { searchText } = props;
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const [loadingElement, setLoadingElement] = useState(false);
  let loadingContent;
  const loaderFunc = () => {
    // setTimeout(loaderFunc, 1000);
    setLoadingElement(false);
  };
  if (loadingElement) {
    loadingContent = (
      <div className="loader-box">
        <Spinner attrSpinner={{ className: "loader-2" }} />
      </div>
    );
  }

  useEffect(() => {
    const getdata = () => {
      setLoadingElement(true);
      dispatch(contractorActions.getAllContractor()).then((data) => {
        if (data.payload) {
          console.log("getall contractor redux", data);
          setServiceProvider(data.payload);
          if (data) {
            setTimeout(loaderFunc, 1000);
          }
        }
      });
    };
    getdata();
  }, [contractorCreation, contractorUpdate]);

  const contractorUpdateToggle = () => {
    setContractorUpdate(!contractorUpdate);
  };

  const handleEdit = async (id) => {
    // e.preventDefault();
    setType("edit");
    setModalTitle("Edit Contractor Detail");
    await dispatch(contractorActions.getContractorById(id)).then((data) => {
      if (data.payload) {
        console.log("service details response from redux....", data.payload);
        setServiceProviderDetail(data.payload);
      }
    });
    toggle();
  };

  // const columns = [
  //   {
  //     name: "S.No",
  //     cell: (row, index) => index + 1,
  //   },
  //   {
  //     name: "Establishment Name",
  //     selector: (row) => `${row.establishment_name}`,
  //   },
  //   {
  //     name: "Proprietor Name",
  //     selector: (row) => row.proprietor_name,
  //   },
  //   {
  //     name: "Mobile Number",
  //     selector: (row) => row.contact_details[0].contact_detail,
  //   },
  //   {
  //     name: "E-mail",
  //     selector: (row) => row.contact_details[1].contact_detail,
  //   },
  //   {
  //     name: " Action",
  //     cell: (row) => (
  //       <div>
  //         <span
  //           color="transparent"
  //           onClick={(e) => handleButtonEdit(e, row.id)}
  //         >
  //           <i className="fa fa-eye"></i>
  //         </span>
  //       </div>
  //     ),
  //   },
  // ];

  // const eyeIconFormatter = (id) => (
  // 	<span onClick={(e) => handleEdit(e, id)}>
  // 		<i className="fa fa-eye"></i>
  // 	</span>
  // );

  const rowEvents = {
    // onClick: (e, row, rowIndex) => {
    // 	handleEdit(e, row.id);
    // },
    onMouseEnter: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "pointer";
    },
    onMouseLeave: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "default";
    },
  };

  const establishmentNameFormatter = (cell, row, rowIndex) => {
    return (
      <a
        style={{ textDecoration: "underline", color: "rgb(13,110,253)" }}
        onClick={() => handleEdit(row.id)}
      >
        {row.establishment_name}
      </a>
    );
  };

  const columns = [
    {
      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "15px 7px",
        width: "10%",
      },
    },
    {
      dataField: "establishment_name",
      text: "Establishment Name",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
        placeholder: "Establishment Name...",
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px 7px",
        width: "30%",
      },
      formatter: establishmentNameFormatter,
    },
    {
      dataField: "proprietor_name",
      text: "Proprietor Name",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
        placeholder: "Proprietor Name...",
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px 7px",
        width: "20%",
      },
    },
    {
      dataField: "contact_details[0].contact_detail",
      text: "Mobile Number",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
        placeholder: "Mobile Number...",
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px 7px",
        width: "20%",
      },
    },
    {
      dataField: "contact_details[1].contact_detail",
      text: "Email Id",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
        placeholder: "Email Id...",
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px 7px",
        width: "20%",
      },
    },
    // {
    // 	dataField: "id",
    // 	text: "Action",
    // 	headerStyle: {
    // 		backgroundColor: "#7366ff",
    // 		color: "white",
    // 		padding: "15px",
    // 	},
    // 	formatter: eyeIconFormatter, // Use the custom formatter for this column
    // },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "All",
        value: serviceProvider.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <Fragment>
      {loadingElement ? (
        loadingContent
      ) : (
        <BootstrapTable
          keyField="id"
          data={serviceProvider}
          columns={columns}
          headerClasses="header-class"
          filter={filterFactory()}
          hover
          // selectRow={selectRow}
          rowEvents={rowEvents}
          pagination={paginationFactory(options)}
        />
      )}
      {modal ? (
        <ModalForm
          modal={modal}
          type={type}
          title={modalTitle}
          value={serviceProviderDetail}
          toggle={toggle}
          contractorUpdateToggle={contractorUpdateToggle}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default ContractorComponent;
