import React from "react";
import { Col, Row, Container } from "reactstrap";
import Widgets1 from "../../Common/CommonWidgets/Widgets1";
import { useState, useEffect } from "react";
import { dashboardActions } from "../../../_services";
import { useDispatch } from "react-redux";

const WidgetsWrapper = () => {
  const [contractor, setContractor] = useState();
  const [circle, setCircle] = useState();
  const [totalCase, setTotalCase] = useState();
  const [approvedCase, setapprovedCase] = useState();
  const [rejectedCase, setRejectedCase] = useState();
  const [user, setUser] = useState();
  const [pendingCase, setpendingCase] = useState();
  const [auditorPendingCase, setAuditorPendingCase] = useState();
  const [accountsPendingCase, setAccountsPendingCase] = useState();
  const dispatch = useDispatch();

  const entitlementCode = localStorage.getItem("entitilement");

  const WidgetsData = {
    title: "Contractors",
    total: contractor,
    color: "secondary",
    icon: "customers",
  };
  const WidgetsData2 = {
    title: "Circles",
    gros: 70,
    total: circle,
    color: "warning",
    icon: "fill-user",
  };
  const WidgetsData3 = {
    title: "Cases",
    gros: 70,
    total: totalCase,
    color: "primary",
    icon: "fill-sample-page",
  };
  const WidgetsData4 = {
    title: "Approved Cases",
    gros: 70,
    total: approvedCase,
    color: "success",
    icon: "fill-sample-page",
  };
  const WidgetsData5 = {
    title: "Returned Cases",
    gros: 70,
    total: rejectedCase,
    color: "secondary",
    icon: "fill-sample-page",
  };
  const WidgetsData6 = {
    title: "Users",
    gros: 70,
    total: user,
    color: "warning",
    icon: "user-visitor",
  };
  const WidgetsData7 = {
    title: "Pending Cases",
    gros: 70,
    total: pendingCase,
    color: "warning",
    icon: "fill-sample-page",
  };
  const WidgetsData8 = {
    title: "Auditor Pending",
    gros: 70,
    total: auditorPendingCase,
    color: "warning",
    icon: "fill-sample-page",
  };
  const WidgetsData9 = {
    title: "Accounts Pending",
    gros: 70,
    total: accountsPendingCase,
    color: "warning",
    icon: "fill-sample-page",
  };
  let userId = localStorage.getItem("userId");
  let type = "";
  if (entitlementCode === "ORG001") {
    type = "ORG";
  } else if (entitlementCode === "AA001") {
    type = "AA";
  } else if (entitlementCode === "AU001") {
    type = "AU";
  } else if (entitlementCode === "CA001") {
    type = "CA";
  } else if (entitlementCode === "AC001") {
    type = "ACC";
  } else if (entitlementCode === "SVP001") {
    type = "SVP";
  } else if (entitlementCode === "MSVP001") {
    type = "MSVP";
  }
  useEffect(() => {
    const getTaskDetailCountByUserName = () => {
      console.log("type.....", type);
      console.log("UserID", userId);
      dispatch(dashboardActions.getDashboardDetail({ userId, type })).then(
        (data) => {
          console.log("dashboard redux", data);
          if (data.payload) {
            setContractor(data.payload.totalServiceProviders);
            setCircle(data.payload.totalCircles);
            setUser(data.payload.totalUsers);
            setTotalCase(data.payload.totalCases);
            setapprovedCase(data.payload.approvedCases);
            setRejectedCase(data.payload.rejectedCases);
            setpendingCase(data.payload.pendingCases);
            setAccountsPendingCase(data.payload.accountsPendingCases);
            setAuditorPendingCase(data.payload.auditorPendingCases);
          }
        }
      );
      // getDashboardValue(userId, type).then((response) => {
      //   console.log("dashboard", response);
      //   if (response.data) {
      //     setContractor(response.data.totalServiceProviders);
      //     setCircle(response.data.totalCircles);
      //     setUser(response.data.totalUsers);
      //     setTotalCase(response.data.totalCases);
      //     setapprovedCase(response.data.approvedCases);
      //     setRejectedCase(response.data.rejectedCases);
      //     setpendingCase(response.data.pendingCases);
      //   }
      // });
    };
    getTaskDetailCountByUserName();
  }, []);
  console.log("first", accountsPendingCase);
  console.log("second", auditorPendingCase);
  console.log("second", user);
  if (entitlementCode === "AU001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData5} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData7} />
          </Col>
        </Row>
      </Container>
    );
  } else if (entitlementCode === "ORG001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData6} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData9} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData8} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData5} />
          </Col>
        </Row>
      </Container>
    );
  } else if (entitlementCode === "CA001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData5} />
          </Col>
        </Row>
      </Container>
    );
  } else if (entitlementCode === "SVP001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData7} />
          </Col>
        </Row>
      </Container>
    );
  } else if (entitlementCode === "AC001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData5} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData7} />
          </Col>
        </Row>
      </Container>
    );
  } else if (entitlementCode === "AA001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData6} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData5} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData7} />
          </Col>
        </Row>
      </Container>
    );
  } else if (entitlementCode === "MSVP001") {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData2} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData4} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData5} />
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Widgets1 data={WidgetsData7} />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default WidgetsWrapper;
