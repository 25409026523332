import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "Circles";
console.log("counterss");
function createInitialState() {
  console.log("1");
  return {
    Circle: [],
    error: null,
    loader: false,
  };
}

function createReducers() {
  function toggleAuthPopup(state, { payload }) {
    state.checkPopup = payload;
  }
  function updateAuthMessage(state, { payload }) {
    state.unAuthMessage = payload;
  }
  return {
    toggleAuthPopup,
    updateAuthMessage,
  };
}

function createExtraActions() {
  console.log("2");
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  function getAllCircle() {
    return createAsyncThunk(
      `/getAllCircle`,
      async () => await fetchAPIWapper.get(`${baseUrl}/orgLocation/getAll`)
    );
  }

  function createCircle() {
    console.log("3");

    return createAsyncThunk(
      `/createCircle`,
      async (request) =>
        await fetchAPIWapper.post(`${baseUrl}/orgLocation/create`, request)
    );
  }
  function getCircleById() {
    console.log("4");
    return createAsyncThunk(
      `/getCircleById`,
      async (id) => await fetchAPIWapper.get(`${baseUrl}/orgLocation/get/${id}`)
    );
  }
  function updateCircleById() {
    return createAsyncThunk(
      `/UpdateCircleById`,
      async ({ id, reqBody }) =>
        await fetchAPIWapper.put(`${baseUrl}/orgLocation/update/${id}`, reqBody)
    );
  }
  function getCircleNames() {
    return createAsyncThunk(
      `/getCircleWithNameId`,
      async () => await fetchAPIWapper.get(`${baseUrl}/orgLocation/view`)
    );
  }

  return {
    getAllCircle: getAllCircle(),
    createCircle: createCircle(),
    getCircleById: getCircleById(),
    updateCircleById: updateCircleById(),
    getCircleNames: getCircleNames(),
  };
}

function createExtraReducers() {
  console.log("7");
  function getAllCircle() {
    let { pending, fulfilled, rejected } = extraActions.getAllCircle;

    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("getAllCircle--> " + action.payload);
        state.Circle = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function createCircle() {
    console.log("8");
    let { pending, fulfilled, rejected } = extraActions.createCircle;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("createCircle--> " + action.payload);
        state.Circle = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function getCircleById() {
    console.log("9");
    let { pending, fulfilled, rejected } = extraActions.getCircleById;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("getCircleById--> " + action.payload);
        state.Circle = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function updateCircleById() {
    console.log("10");
    let { pending, fulfilled, rejected } = extraActions.updateCircleById;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        state.Circle = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function getCircleNames() {
    let { pending, fulfilled, rejected } = extraActions.getCircleNames;

    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("getAllCircle--> " + action.payload);
        state.Circle = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }

  return {
    ...createCircle(),
    ...getAllCircle(),
    ...getCircleById(),
    ...updateCircleById(),
    ...getCircleNames(),
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const circleActions = { ...slice.actions, ...extraActions };
export const circleReducer = slice.reducer;
