import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardHeader, Form, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import CaseDetailsTable from "./CaseDetailsTable";
import CaseDetailsUpdateForm from "./caseDetailsUpdateForm";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ModalForm from "./modals/modal";
import { caseActions } from "../../_services";
import { Spinner } from "../../AbstractElements";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const CaseDetailsForm = ({ cases }) => {
  console.log("cases in caseDetailsFormPage...", cases);
  const caseId = localStorage.getItem("caseId");
  const navigate = useNavigate();
  // state variables
  const [editCaseId, setEditCaseId] = useState(null);
  const [caseDetails, setCaseDetails] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const [Case, setCase] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const modalTitle = "PAYMENT DETAILS";
  const [paymentDetails, setPaymentDetails] = useState({});
  const [yearDetails, setYearDetails] = useState([]);
  const [currentValue, setCurrentValue] = useState("");
  const [loadingELement, setLoadingElement] = useState(false);
  let tempDocument = 0;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const displayFailureAlert = () => {
    SweetAlert.fire({
      title: "Looking Not Good!",
      text: "Case not submitted! kindly upload pending documents",
      icon: "warning",
    });
  };

  const displaySuccessAlert = () => {
    SweetAlert.fire({
      title: "Great!",
      text: "Case Saved Successfully!",
      icon: "success",
    });
  };

  const displayDangerAlert = () => {
    SweetAlert.fire({
      title: "Oops!",
      text: "No cases to submit!",
      icon: "error",
    });
  };

  const updateCaseStatus = async () => {
    const reqBody = {
      svcProviderStatus: "UNDER REVIEW",
      auditorStatus: "PENDING",
    };
    await dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then(
      (data) => {
        if (data.type == "/caseStatusUpdate/fulfilled") {
          toast.success("Your case has been submitted");
          navigate(`${process.env.PUBLIC_URL}/case/viewAll`);
        }
      }
    );
  };

  const getPaymentDetails = async () => {
    console.log("case id.......", caseId);
    setLoadingElement(true);
    await dispatch(caseActions.getPaymentDetails(caseId)).then((data) => {
      console.log(
        "data from payment in case details form-------------------------->",
        data
      );
      const status = data.payload.status;
      console.log("status", status);
      if (status) {
        updateCaseStatus();
      } else {
        setPaymentDetails(data.payload);
        setLoadingElement(false);
        toggle();
      }
    });
  };

  const statusChange = async () => {
    const isStatusChange = await dispatch(
      caseActions.getAllCaseDetails(caseId)
    ).then(async (data) => {
      const caseDetails = data.payload;
      const totalCaseDetails = caseDetails.length;
      console.log("total case details.....", totalCaseDetails);
      if (totalCaseDetails !== 0) {
        const caseDetailsId = caseDetails.map((eachCase) => eachCase.id);
        console.log("case details id ----->", caseDetailsId);

        // caseDetailsId.map((eachCaseDetailsId) => {
        //   dispatch(caseActions.getAllCaseDocuments(eachCaseDetailsId)).then(
        //     (data) => {
        //       console.log("data from status change redux", data);
        //       let file = data.payload;
        //       console.log(" file lemgth", file.length);
        //       tempDocument += file.length;
        //       console.log("first", tempDocument);
        //     }
        //   );
        // });
        for (let i = 0; i < caseDetailsId.length; i++) {
          const caseDetailId = caseDetailsId[i];
          await dispatch(caseActions.getAllCaseDocuments(caseDetailId)).then(
            (data) => {
              console.log("data from status change redux", data);
              let file = data.payload;
              console.log(" file lemgth", file.length);
              tempDocument += file.length;
              console.log("first", tempDocument);
            }
          );
        }
        console.log("tempDocument", tempDocument);
        if (tempDocument >= totalCaseDetails * 9) {
          console.log("I'm in if block");
          // updateStatus();
          getPaymentDetails();
        } else {
          console.log("I'm in else");
          displayFailureAlert();
        }
      } else {
        displayDangerAlert();
      }
    });
  };

  const getYearDetails = async () => {
    await dispatch(caseActions.getYearDetails()).then((response) => {
      console.log("response........", response);
      const { payload } = response;
      setYearDetails(payload);
    });
  };

  const displayWarningAlert = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once case is submitted, you will not be able to edit this case!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        statusChange();
      }
    });
  };

  const handleSubmitCase = () => {
    displayWarningAlert(); // statusChange();
  };
  const handleSaveCase = () => {
    // toggle();
    navigate(`${process.env.PUBLIC_URL}/case/viewAll`);
  };

  const deleteCaseDetail = (caseDetailsId) => {
    console.log("case details id...", caseDetailsId);
    const filteredCaseDetails = caseDetails.filter(
      (eachCase) => eachCase.id !== caseDetailsId
    );
    setCaseDetails(filteredCaseDetails);
    dispatch(caseActions.getCaseDetailsById(caseDetailsId)).then((data) => {
      if (data.payload) {
        const { case_id, year, month, man_days, epf_trans_no, esi_trans_no } =
          data.payload;
        console.log(case_id, year, month, man_days, epf_trans_no, esi_trans_no);
        const reqBody = {
          caseId: case_id,
          year: year,
          month: month,
          epfTransNo: epf_trans_no,
          esiTransNo: esi_trans_no,
          status: false,
        };
        dispatch(caseActions.updateCaseDetails(caseDetailsId, reqBody)).then(
          (data) => {
            console.log("response data...", data);
          }
        );
      }
    });
  };

  const handleDelete = (id) => {
    console.log("handle delete id", id);
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once case details are deleted, you will not be able to revert this case details!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        deleteCaseDetail(id);
        // SweetAlert.fire(
        // 	"Deleted!",
        // 	"Your case details has been deleted.",
        // 	"success"
        // );
        toast.success("Your case details has been deleted!");
        /* Toast.fire({
          text: "Your case details has been deleted!",
          icon: "success",
        }); */
      }
    });
  };

  const handleEdit = (id) => {
    console.log("edit id...", id);
    setEditCaseId(id);
    setIsEditing(true);
  };

  const getCaseDetails = async () => {
    await dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
      if (data.payload) {
        setCaseDetails(data.payload);
      }
    });
  };
  const getCase = async () => {
    await dispatch(caseActions.getCaseById(caseId)).then((data) => {
      setCase(data.payload);
    });
  };

  function checkValue(event) {
    setCurrentValue(handleDecimalsOnValue(event.target.value));
  }

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  const handleBack = () => {
    console.log("Back Button is triggered....");
    navigate(`${process.env.PUBLIC_URL}/EditCase/${caseId}`);
  };
  useEffect(() => {
    getCaseDetails();
    getCase();
    getYearDetails();
  }, []);
  const onSubmit = async (formData) => {
    if (formData !== "") {
      const reqBody = {
        caseId: caseId,
        year: formData.year,
        month: formData.month.toUpperCase(),
        manDays: formData.manDays,
        epfTransNo: formData.epfTransactionNumber.toUpperCase(),
        esiTransNo: formData.esiTransactionNumber.toUpperCase(),
        svcProviderId: Case.svc_provider_id,
        circleId: Case.org_location_id,
        acceptanceOrderNo: Case.acceptance_order_no,
        agreementNo: Case.agreement_no,
      };
      console.log("first", reqBody);
      await dispatch(caseActions.createCaseDetails(reqBody)).then((data) => {
        console.log(
          "request from the  create case detail redux--------->",
          data
        );

        if (data.payload.message === "Success") {
          getCaseDetails();
          reset({
            year: null,
            month: null,
            manDays: "",
            epfTransactionNumber: "",
            esiTransactionNumber: "",
          });
          setCurrentValue("");
        } else if (data.payload.message === "Already Exist") {
          toast.warn(
            `Agreement/Acceptance number already exists in Case Number ${data.payload.caseId}`
          );
          /* Toast.fire({
						text: "Agreement/Acceptance number already exists",
						icon: "warning",
					}); */
          // handleBack();
        }
      });
    } else {
      errors.showMessages();
    }
  };
  console.log("editing...", isEditing);

  return isEditing ? (
    <CaseDetailsUpdateForm editCaseId={editCaseId} />
  ) : (
    <Fragment>
      <Card style={{ marginTop: "40px" }}>
        <CardHeader
          className="text-center fs-3 text-dark"
          style={{ padding: "5px" }}
        >
          Case Details Page
        </CardHeader>
        {loadingELement === true ? (
          <CardBody
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "100vh",
              fontSize: "20px",
            }}
          >
            <h6 className="f-30 m-r-10 font-dark">
              Payment Details are Loading. Please Wait...
            </h6>
            <div className="loader-box">
              <Spinner attrSpinner={{ className: "loader-15" }} />
            </div>
          </CardBody>
        ) : (
          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="2 mb-3">
                  <Label className="form-label" for="year">
                    Year <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="year"
                    id="year"
                    autoComplete="off"
                    className="form-select bg-light text-dark"
                    {...register("year", { required: true })}
                  >
                    <option value="">Select year</option>
                    {yearDetails.map((eachYearDetails) => (
                      <option
                        key={eachYearDetails.id}
                        value={eachYearDetails.year}
                      >
                        {eachYearDetails.year}
                      </option>
                    ))}
                  </select>
                  <span className="d-block text-danger">
                    {errors.year && "Year is required"}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </Col>
                <Col md="2 mb-3">
                  <Label className="form-label" for="month">
                    Month <span className="text-danger">*</span>
                  </Label>
                  <select
                    name="month"
                    id="month"
                    autoComplete="off"
                    className="form-select bg-light text-dark"
                    {...register("month", { required: true })}
                  >
                    <option value="">Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                  <span className="d-block text-danger">
                    {errors.month && "Month is required"}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </Col>
                <Col md="2 mb-3">
                  <Label className="form-label" for="manDays">
                    Man Days <span className="text-danger">*</span>
                  </Label>
                  <input
                    className="form-control bg-light text-dark"
                    id="manDays"
                    autoComplete="off"
                    value={currentValue}
                    type="text"
                    placeholder="Man Days"
                    name="manDays"
                    {...register("manDays", { required: true })}
                    onChange={(event) => checkValue(event)}
                  />
                  <span className="d-block text-danger">
                    {errors.manDays && "Man Days is required"}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </Col>
                <Col md="3 mb-3">
                  <Label className="form-label" for="epfTransactionNumber">
                    EPF Transaction Number{" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    className="form-control bg-light text-dark"
                    id="epfTransactionNumber"
                    autoComplete="off"
                    type="text"
                    placeholder="EPF Transaction Number"
                    name="epfTransactionNumber"
                    {...register("epfTransactionNumber", { required: true })}
                  />
                  <span className="d-block text-danger">
                    {errors.epfTransactionNumber &&
                      "EPF Transaction Number is required"}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </Col>
                <Col md="3 mb-3">
                  <Label className="form-label" for="esiTransactionNumber">
                    ESI Transaction Number{" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    className="form-control bg-light text-dark"
                    id="esiTransactionNumber"
                    autoComplete="off"
                    type="text"
                    placeholder="ESI Transaction Number"
                    name="esiTransactionNumber"
                    {...register("esiTransactionNumber", { required: true })}
                  />
                  <span className="d-block text-danger">
                    {errors.esiTransactionNumber &&
                      "ESI Transaction Number is required"}
                  </span>
                  <div className="valid-feedback">Looks good!</div>
                </Col>
              </Row>
              <Button variant="primary" type="submit">
                Add
              </Button>
            </Form>
            {cases !== undefined ? (
              <CaseDetailsTable
                caseDetails={cases}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            ) : (
              <CaseDetailsTable
                caseDetails={caseDetails}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            )}
            <div className="d-flex flex-row justify-content-between">
              <Button variant="secondary" onClick={handleBack}>
                Back
              </Button>
              <div>
                <Button className="me-3" onClick={handleSaveCase}>
                  Save Case
                </Button>
                <Button variant="success" onClick={handleSubmitCase}>
                  Submit and Pay
                </Button>
              </div>
            </div>
            {modal && (
              <ModalForm
                mode="payment"
                modal={modal}
                toggle={toggle}
                modalTitle={modalTitle}
                paymentDetails={paymentDetails}
                size="lg"
              />
            )}
          </CardBody>
        )}
      </Card>
    </Fragment>
  );
};

export default CaseDetailsForm;
