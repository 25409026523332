import React, { useState } from "react";
import {
	Container,
	Col,
	Row,
	Label,
	FormGroup,
	Button,
	Card,
	CardBody,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { getInvoiceReport } from "../../service/user-service";
import InvoiceReportTable from "./invoiceReportTable";
import moment from "moment";
import { useDispatch } from "react-redux";
import { reportsActions } from "../../_services";
// import moment from "moment/moment";

const InvoiceReport = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [showTable, setShowTable] = useState(false);
	const [invoiceTableData, setInvoiceTableData] = useState([]);
	const [displayMessage, setDisplayMessage] = useState();
	const [showMessage, setShowMessage] = useState(false);
	const showTableToggle = (showTable) => setShowTable(!showTable);
	const showMessageToggle = (showMessage) => setShowMessage(!showMessage);
	const dispatch = useDispatch();

	const startDateChange = (date) => {
		setStartDate(date);
	};

	const endDateChange = (date) => {
		setEndDate(date);
	};

	const dateFormat = (date) => {
		let currentDate = moment(date).format("YYYY-MM-DD");
		return currentDate;
	};

	const downloadInvoiceReport = (action) => {
		console.log("action..........", action);
		console.log("download Invoice report....... ok button clicked");
		const reqStartDateFormat = dateFormat(startDate);
		const reqEndDateFormat = dateFormat(endDate);
		dispatch(
			reportsActions.getInvoiceReport({
				reqStartDateFormat,
				reqEndDateFormat,
				action,
			})
		).then((data) => {
			console.log("response from redux......", data);
			if (data.payload === "NO INVOICE AVAILABLE") {
				setShowMessage(true);
				setShowTable(false);
				const message =
					action === "VIEW"
						? "NO INVOICE DETAILS AVAILABLE TO DISPLAY"
						: "NO INVOICE DETAILS AVAILABLE TO DOWNLOAD";
				setDisplayMessage(message);
			} else {
				setShowMessage(false);
				if (action === "VIEW") {
					setShowTable(true);
					setInvoiceTableData(data.payload);
				} else {
					console.log("response data....", data);
					const blob = new Blob([data.payload]);
					const url = URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					link.download = "Invoice_Reports.xlsx";
					link.click();
					URL.revokeObjectURL(url);
				}
			}
		});
	};

	return (
		<Card>
			<CardBody className="d-flex flex-column justify-content-start">
				<Container>
					<Row className="d-flex flex-row justify-content-between align-items-center">
						<Col className="col">
							<FormGroup>
								<Label>Choose Start Date</Label>
								<DatePicker
									className="form-control digits"
									selected={startDate}
									onChange={startDateChange}
									id="startDate"
									dateFormat="yyyy-MM-dd"
								/>
							</FormGroup>
						</Col>
						<Col className="col">
							<FormGroup>
								<Label>Choose End Date</Label>
								<DatePicker
									className="form-control digits"
									selected={endDate}
									onChange={endDateChange}
									id="endDate"
									dateFormat="yyyy-MM-dd"
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className="col-12">
							<button
								className="btn btn-primary m-r-10"
								onClick={() => downloadInvoiceReport("VIEW")}
							>
								View
							</button>
							<button
								className="btn btn-secondary"
								onClick={() => downloadInvoiceReport("DOWNLOAD")}
							>
								Download
							</button>
						</Col>
					</Row>
				</Container>
				{showTable && (
					<InvoiceReportTable invoiceTableData={invoiceTableData} />
				)}
				{showMessage && (
					<p className="d-flex justify-content-center m-t-30 font-danger f-w-700">
						*{displayMessage}
					</p>
				)}
			</CardBody>
		</Card>
	);
};

export default InvoiceReport;
