import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import SearchBar from "../common/SearchBar";
import { toast } from "react-toastify";
import { Col, Card, CardBody, Form, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch } from "react-redux";
import {
  caseActions,
  circleActions,
  contractorActions,
  userActions,
} from "../../_services";
import { Spinner } from "../../AbstractElements";

// const Toast = SweetAlert.fire({
//   toast: true,
//   position: "top-end",
//   showConfirmButton: false,
//   showCloseButton: true,
//   timer: 3000,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener("mouseenter", SweetAlert.stopTimer);
//     toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
//   },
// });

// import { Tooltip as ReactTooltip } from "react-tooltip";

const UserComponent = (props) => {
  const entitlementCode = localStorage.getItem("entitilement");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [Case, setCase] = useState([]);
  const [caseDetail, setcaseDetail] = useState([]);
  const [caseCreationDetail, setCaseCreationDetail] = useState({});
  const [caseRemarks, setCaseRemarks] = useState([]);
  const [svcDetails, setSvcDetails] = useState({});
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("view");
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [modalTitle, setModalTitle] = useState("Case Details");
  const [caseId, setCaseId] = useState();
  const { searchText } = props;
  const toggle = () => setModal(!modal);
  const [size, setSize] = useState("lg");
  const [failedTransaction, setFailedTransaction] = useState([]);
  const [caseFlowHistoryDetails, setCaseFlowHistoryDetails] = useState([]);

  let filteredData = null;
  let labourNumber = null;
  let columns = null;
  const [clearFilter, setClearFilter] = useState(false);
  // const caseId = localStorage.getItem("caseId");
  const dispatch = useDispatch();
  const [loadingElement, setLoadingElement] = useState(false);
  let loadingContent;
  const loaderFunc = () => {
    // setTimeout(loaderFunc, 1000);
    setLoadingElement(false);
  };
  if (loadingElement) {
    loadingContent = (
      <div className="loader-box">
        <Spinner attrSpinner={{ className: "loader-2" }} />
      </div>
    );
  }

  const clearSearchFilter = () => {
    setClearFilter(!clearFilter);
  };
  useEffect(() => {
    setLoadingElement(true);
    const casesWithCircleName = [];
    const casesWithContractorName = [];
    const getdata = async () => {
      await dispatch(caseActions.getAllCase()).then(async (data) => {
        console.log("data.............. in get all case....", data.payload);
        if (data.payload) {
          const initialCase = data.payload;
          console.log("initial case", initialCase);
          if (entitlementCode === "AU001") {
            filteredData = initialCase.filter((caseObject) => {
              if (
                caseObject.case_status.auditor_status === "PENDING" ||
                caseObject.case_status.auditor_status === "ADMIN RETURN"
              ) {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "AC001") {
            filteredData = initialCase.filter((caseObject) => {
              if (caseObject.case_status.Accounts_Status === "PENDING") {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "AA001") {
            filteredData = initialCase.filter((caseObject) => {
              if (caseObject.case_status.auditor_admin_status === "PENDING") {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "ORG001") {
            filteredData = initialCase.filter((caseObject) => {
              if (
                caseObject.case_status.svc_provider_status === "UNDER REVIEW" ||
                caseObject.case_status.svc_provider_status ===
                  "AUDIT CLEARED" ||
                caseObject.case_status.svc_provider_status === "AUDIT FAILED"
              ) {
                return true;
              }
              return false;
            });
          } else if (entitlementCode === "CA001") {
            let circleId = null;
            await dispatch(userActions.getUserById(userId)).then((data) => {
              console.log("response from circle admin ----->", data);
              circleId = data.payload[0].circle_id;
            });
            filteredData = initialCase.filter((caseObject) => {
              console.log("caseObject", caseObject);
              console.log("----->", circleId);
              if (
                (caseObject.case_status.svc_provider_status ===
                  "UNDER REVIEW" ||
                  caseObject.case_status.svc_provider_status ===
                    "AUDIT CLEARED" ||
                  caseObject.case_status.svc_provider_status ===
                    "AUDIT FAILED") &&
                caseObject.org_location_id === circleId
              ) {
                return true;
              }
              return false;
            });
          }
          await dispatch(circleActions.getAllCircle()).then((data) => {
            for (let eachCase of filteredData) {
              const orgLocationId = eachCase.org_location_id;
              for (let eachCircle of data.payload) {
                if (orgLocationId === eachCircle.id) {
                  const newCase = {
                    ...eachCase,
                    name: eachCircle.name,
                  };
                  casesWithCircleName.push(newCase);
                  console.log("circle Names....", casesWithCircleName);
                }
              }
            }
          });
          await dispatch(contractorActions.getAllContractor()).then((data) => {
            console.log("svc details....", data);
            for (let eachCase of casesWithCircleName) {
              const serviceProviderId = eachCase.svc_provider_id;
              for (let eachServiceProvider of data.payload) {
                const { id, establishment_name } = eachServiceProvider;
                if (id === serviceProviderId) {
                  const newCase = {
                    ...eachCase,
                    establishmentName: establishment_name,
                  };
                  casesWithContractorName.push(newCase);
                }
              }
            }
            console.log("Case......>", casesWithContractorName);
            setCase(casesWithContractorName);
            if (data) {
              setTimeout(loaderFunc, 1000);
            }
          });
        }
      });
    };
    getdata();
  }, [clearFilter, type, modalTitle, caseId]);

  const sendMessage = (message, caseId) => {
    let flagValue = null;
    if (entitlementCode === "AA001") {
      flagValue = "Admin";
    } else if (entitlementCode === "AU001") {
      flagValue = "Auditor";
    } else if (entitlementCode === "AC001") {
      flagValue = "Accountant Admin";
    }
    const remarksInfo = {
      caseId: caseId,
      messages: message,
      flagValue: flagValue,
    };
    dispatch(caseActions.createCaseRemarks(remarksInfo)).then((data) => {
      console.log("remarks response from redux...", data);

      if (entitlementCode === "AU001") {
        rejectStausChange(message, caseId);
      } else if (entitlementCode === "AA001") {
        rejectCaseAA(message, caseId);
      } else if (entitlementCode === "AC001") {
        accountsReject(message, caseId);
      }
    });
  };

  // const DisplaySuccessAlert = () => {
  // 	SweetAlert.fire({
  // 		title: "Great!",
  // 		text: "Case submitted successfully!",
  // 		icon: "success",
  // 	});
  // };

  // const DisplayFailureAlert = () => {
  // 	SweetAlert.fire({
  // 		title: "Looking Not Good!",
  // 		text: "Case is in Draft condition",
  // 		icon: "warning",
  // 	});
  // };

  const DisplayApprovalAlert = (checker) => {
    if (checker === "approval") {
      SweetAlert.fire({
        text: "Payment verified successfully!",
        icon: "success",
      });
    } else if (checker === "rejection-failure") {
      SweetAlert.fire({
        text: "Reason required for rejection",
        icon: "error",
      });
    } else if (checker === "generated") {
      SweetAlert.fire({
        text: "Case Invoice Generated successFully",
        icon: "success",
      });
    }
  };

  const displayWarningAlert = (caseId) => {
    SweetAlert.fire({
      title: "Do You want to reject the case?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: false,
      // preConfirm:(value) => {
      // 	if(!value){
      // 		SweetAlert.showValidationMessage("Reuired");
      // 	}
      // }
    }).then((result) => {
      if (result.value) {
        SweetAlert.fire({
          title: "Reason for Rejection:",
          input: "textarea",
        }).then((result) => {
          if (result.value) {
            sendMessage(result.value, caseId);
          } else {
            DisplayApprovalAlert("rejection-failure");
          }
        });
      }
    });
  };

  const handleViewClick = async (caseId) => {
    console.log("caseId...", caseId);
    // e.preventDefault();
    console.log("caseId in view click...", caseId);
    setType("view");
    setSize("xl");
    await dispatch(caseActions.getAllCaseDetails(caseId)).then(async (data) => {
      if (data.payload) {
        console.log("Case details response======---->", data);
        setcaseDetail(data.payload);
      }
      await dispatch(caseActions.getCaseById(caseId)).then((data) => {
        console.log("case creation response....", data);
        const { svc_provider_id, org_location_id } = data.payload;
        console.log("org id...........................", org_location_id);
        dispatch(contractorActions.getContractorById(svc_provider_id)).then(
          (data) => {
            console.log("svc details....", data);
            const { establishment_name, epf_number, esi_number } =
              data.payload[0];
            const { contact_detail } = data.payload[1][0];
            const orgSvcMap = data.payload[2];
            labourNumber = orgSvcMap.filter((labour_number) => {
              if (labour_number.org_location_id === org_location_id) {
                return true;
              }
              return false;
            });

            console.log("labour number...", labourNumber);

            const svcData = {
              establishmentName: establishment_name,
              epfNumber: epf_number,
              esiNumber: esi_number,
              emailId: contact_detail,
              labourNumber: labourNumber[0].labour_number,
            };
            setSvcDetails(svcData);
          }
        );
        setCaseCreationDetail(data.payload);
        console.log("CaseCreation Detail", caseCreationDetail);
      });
      dispatch(caseActions.getCaseRemarks(caseId)).then((data) => {
        setCaseRemarks(data.payload);
      });
      if (entitlementCode === "AC001") {
        dispatch(caseActions.paymentFailedTransaction(caseId)).then((data) => {
          console.log("case flow history", data);
          setFailedTransaction(data.payload);
        });
      }
    });
    toggle();
    setIsViewClicked(true);
  };

  //Auditor case approval
  const handleApproval = (e, caseId) => {
    const reqBody = {
      // accountsStatus: "SUCCESS",
      // svcProviderStatus: "UNDER REVIEW",
      auditorStatus: "SUCCESS",
      auditAdminstatus: "PENDING",
    };
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
      console.log("response data...", data);
      SweetAlert.fire("Request Sent!", "Case sent for approval.", "success");
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    });
  };

  //Accountant case accepted
  const handleAccept = (e, caseId) => {
    const reqBody = {
      accountsStatus: "SUCCESS",
      svcProviderStatus: "UNDER REVIEW",
      auditorStatus: "PENDING",
    };
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
      console.log("response data...", data);
      DisplayApprovalAlert("approval");
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    });
  };

  const handleInvoice = () => {
    DisplayApprovalAlert("generated");
  };

  //auditor admin approval
  const handleApprove = (e, caseId) => {
    console.log(".........id", caseId);
    console.log("Case Flow Button triggered......");
    setModalTitle("Select certificate type");
    setType("caseCsertificate");
    setCaseId(caseId);
    setSize("md");

    toggle();
  };

  // Auditor Admin Reject Case
  const rejectCaseAA = (message, caseId) => {
    const reqBody = {
      auditorStatus: "ADMIN RETURN",
      auditAdminstatus: "RETURN",
      returnRemarks: message,
    };
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
      console.log("response data...", data);
      SweetAlert.fire("Rejected!", "case has been Rejected.", "success");
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    });
  };
  //Accountant rejected the Case

  const accountsReject = (message, caseId) => {
    const reqBody = {
      accountsStatus: "FAILED",
      svcProviderStatus: "PAYMENT FAILED",
      caseId: caseId,
      returnRemarks: message,
    };
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
      console.log("response data...", data);
      SweetAlert.fire("Rejected!", "case has been Rejected.", "success");
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    });
  };
  // Auditor Reject
  const handleReject = (e, caseId) => {
    displayWarningAlert(caseId);
  };

  //accounts Reject
  const accountantReject = (e, caseId) => {
    displayWarningAlert(caseId);
  };

  const rejectStausChange = (message, caseId) => {
    const reqBody = {
      svcProviderStatus: "AUDIT RETURN",
      auditorStatus: "RETURN",
      returnRemarks: message,
    };
    SweetAlert.fire("Rejected!", "Case has been Rejected.", "success");
    dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then((data) => {
      console.log("response data...", data);
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    });
  };
  // Auditor Admin reject
  const handleRejectCase = (e, caseId) => {
    displayWarningAlert(caseId);
  };

  const ShowFilteredCases = (e) => {
    e.preventDefault();
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;

    if (startDate == "" && endDate == "") {
      toast.error("please select the value");
      /* Toast.fire({
        text: "Please select the value",
        icon: "error",
      }); */
    }
  };

  const handleSearch = (startDate, endDate) => {
    const SearchbarRequest = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(caseActions.filteredCase(SearchbarRequest)).then(async (data) => {
      const casesWithCircleName = [];
      const casesWithContractorName = [];
      if (data.payload) {
        const initialCase = data.payload;
        console.log("initial case", initialCase);
        if (entitlementCode === "AU001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.auditor_status === "PENDING" ||
              caseObject.case_status.auditor_status === "ADMIN RETURN"
            ) {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "AC001") {
          filteredData = initialCase.filter((caseObject) => {
            if (caseObject.case_status.Accounts_Status === "PENDING") {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "AA001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.auditor_admin_status === "PENDING" ||
              caseObject.case_status.auditor_status === "RETURN"
            ) {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "ORG001") {
          filteredData = initialCase.filter((caseObject) => {
            if (
              caseObject.case_status.svc_provider_status === "UNDER REVIEW" ||
              caseObject.case_status.svc_provider_status === "AUDIT CLEARED" ||
              caseObject.case_status.svc_provider_status === "AUDIT FAILED"
            ) {
              return true;
            }
            return false;
          });
        } else if (entitlementCode === "CA001") {
          let circleId = null;
          await dispatch(userActions.getUserById(userId)).then((data) => {
            console.log("response from circle admin ----->", data);
            circleId = data.payload.circle_id;
          });
          filteredData = initialCase.filter((caseObject) => {
            console.log("caseObject", caseObject);
            console.log("----->", circleId);
            if (
              (caseObject.case_status.svc_provider_status === "UNDER REVIEW" ||
                caseObject.case_status.svc_provider_status ===
                  "AUDIT CLEARED" ||
                caseObject.case_status.svc_provider_status ===
                  "AUDIT FAILED") &&
              caseObject.org_location_id === circleId
            ) {
              return true;
            }
            return false;
          });
        }
        await dispatch(circleActions.getAllCircle()).then((data) => {
          for (let eachCase of filteredData) {
            const orgLocationId = eachCase.org_location_id;
            for (let eachCircle of data.payload) {
              if (orgLocationId === eachCircle.id) {
                const newCase = {
                  ...eachCase,
                  name: eachCircle.name,
                };
                casesWithCircleName.push(newCase);
                console.log("circle Names....", casesWithCircleName);
              }
            }
          }
        });
        await dispatch(contractorActions.getAllContractor()).then((data) => {
          console.log("svc details....", data);
          for (let eachCase of casesWithCircleName) {
            const serviceProviderId = eachCase.svc_provider_id;
            for (let eachServiceProvider of data.payload) {
              const { id, establishment_name } = eachServiceProvider;
              if (id === serviceProviderId) {
                const newCase = {
                  ...eachCase,
                  establishmentName: establishment_name,
                };
                casesWithContractorName.push(newCase);
              }
            }
          }
          console.log("Case......>", casesWithContractorName);
          setCase(casesWithContractorName);
        });
      }
    });
  };

  console.log("first", Case);
  const handleDocumentDownload = async (caseId, case_id) => {
    // console.log("caseId");
    // const documentType = "certificate";
    // fetchDocumentPath(caseId, documentType).then((response) => {
    //   const { document_path } = response.data;
    //   downloadCertificate(document_path).then((response) => {
    //     const { data } = response;
    //     console.log("response...", data);
    //     const blob = new Blob([data], { type: "application/pdf" });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "certificate.pdf";
    //     link.click();
    //     URL.revokeObjectURL(url);
    //   });
    // });
    console.log("caseId");
    const documentType = "certificate";
    await dispatch(caseActions.getDocumentPath({ caseId, documentType })).then(
      async (data) => {
        if (data.payload) {
          console.log("data from redux", data);
          const { document_path } = data.payload;
          const fetchResponse = await fetch(document_path);
          const blob = await fetchResponse.blob();

          // Create a temporary link and trigger download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          // Set the correct Content-Type header for PDF
          link.type = "application/pdf";
          link.download = `${case_id}certificate.pdf`;
          link.click();
        }
      }
    );
  };
  // const eyeIconFormatter = (id) => (
  // 	<span onClick={(e) => handleViewClick(e, id)}>
  // 		<i className="fa fa-eye"></i>
  // 	</span>
  // );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with leading zeros if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month (months are zero-based) and pad with leading zeros if necessary
    const year = date.getFullYear().toString(); // Get the full year

    return `${day}/${month}/${year}`; // Format the date as "dd/mm/yyyy"
  };

  const rowEvents = {
    onMouseEnter: (e) => {
      e.currentTarget.style.cursor = "pointer";
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.cursor = "default";
    },
  };

  const caseIdFormatter = (cell, row) => {
    return (
      <a
        style={{ textDecoration: "underline", color: "rgb(13, 110, 253)" }}
        onClick={() => handleViewClick(row.id)}
      >
        {row.case_id}
      </a>
    );
  };

  const handleCaseFlow = (caseId) => {
    console.log(".........id", caseId);
    console.log("Case Flow Button triggered......");
    dispatch(caseActions.fetchCaseFlowHistory(caseId)).then((response) => {
      console.log("response................", response);
      // console.log("filtered data.................", Case);
      // let paymentCaseDetails = Case.filter(
      //   (eachCase) => eachCase.id === caseId
      // );
      // const paymentCaseDetailsObject = paymentCaseDetails[0];
      // const { invoice_detail } = paymentCaseDetailsObject;
      // const { return_amount } = invoice_detail;
      // console.log("return_amount...............", return_amount);
      const { payload } = response;
      console.log("Payload.........", payload);
      setCaseFlowHistoryDetails(payload);
      setModalTitle("Case Flow History");
      setType("caseFlow");
      setModal(true);
    });
  };

  if (entitlementCode === "ORG001" || entitlementCode === "CA001") {
    columns = [
      {
        text: "S.No",
        formatter: (cell, row, rowIndex) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
        },
        formatter: caseIdFormatter,
      },
      {
        dataField: "createdAt",
        text: "Created Date",
        filter: dateFilter({
          //   style: { display: "inline-grid" },
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "15vw",
        },
      },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
        },
      },
      {
        dataField: "name",
        text: "Circle",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Circle...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
        },
      },

      // {
      // 	dataField: "id",
      // 	text: "View Case",
      // 	headerStyle: {
      // 		backgroundColor: "#7366ff",
      // 		color: "white",
      // 		padding: "15px 7px",
      // 	},
      // 	formatter: eyeIconFormatter, // Use the custom formatter for this column
      // },
      {
        dataField: "case_status.svc_provider_status",
        text: "Status",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Status...",
        }),
        formatter: (cell, row) => {
          if (row.case_status.svc_provider_status === "AUDIT CLEARED") {
            return (
              <span className="badge bg-success">
                {row.case_status.svc_provider_status}
              </span>
            );
          } else if (row.case_status.svc_provider_status === "UNDER REVIEW") {
            return (
              <span className="badge bg-light txt-dark">
                {row.case_status.svc_provider_status}
              </span>
            );
          } else if (row.case_status.svc_provider_status === "AUDIT FAILED") {
            return (
              <span className="badge bg-danger">
                {row.case_status.svc_provider_status}
              </span>
            );
          }
        },
        style: {
          color: "#e35430",
        },
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
        },
      },
      {
        dataField: "status",
        text: "Action",
        formatter: (cell, row) =>
          row.case_status.svc_provider_status === "AUDIT CLEARED" ? (
            <>
              <div className="d-flex flex-row justify-content-between">
                <Button
                  size="lg"
                  variant="transparent"
                  onClick={() => handleDocumentDownload(row.id, row.case_id)}
                >
                  <i className="fa fa-download text-primary" />
                </Button>
                <Button
                  size="lg"
                  variant="transparent"
                  onClick={() => handleCaseFlow(row.id, row.case_id)}
                >
                  <i className="fa fa-eye font-dark"></i>
                </Button>
              </div>
            </>
          ) : (
            <Button
              size="lg"
              variant="transparent"
              onClick={() => handleCaseFlow(row.id, row.case_id)}
            >
              <i className="fa fa-eye font-dark"></i>
            </Button>
          ),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
    ];
  } else if (entitlementCode === "AU001" || entitlementCode === "AA001") {
    columns = [
      // Columns for entitlementCode 'AU001' or 'AA001'
      {
        text: "S.No",
        formatter: (cell, row, rowIndex) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "10vw",
        },
        formatter: caseIdFormatter,
      },
      {
        dataField: "createdAt",
        text: "Created Date",
        filter: dateFilter({
          //   style: { display: "inline-grid" },
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "18vw",
        },
      },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "15vw",
        },
      },
      {
        dataField: "name",
        text: "Circle",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Circle...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "10vw",
        },
      },

      // {
      // 	dataField: "id",
      // 	text: "View Case",
      // 	headerStyle: {
      // 		backgroundColor: "#7366ff",
      // 		color: "white",
      // 		padding: "15px 7px",
      // 	},
      // 	formatter: eyeIconFormatter, // Use the custom formatter for this column
      // },
      {
        text: "Action",
        formatter: (cell, row) => {
          if (
            row.case_status.auditor_admin_status === "PENDING" &&
            entitlementCode === "AA001"
          ) {
            return (
              <>
                <div className="d-flex flex-row justify-content-around">
                  <button
                    className="btn btn-success"
                    onClick={(e) => handleApprove(e, row.id)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={(e) => handleRejectCase(e, row.id)}
                  >
                    Reject
                  </button>
                </div>
              </>
            );
          } else if (
            row.case_status.auditor_status === "PENDING" &&
            entitlementCode === "AU001"
          ) {
            return (
              <div className="d-flex flex-row justify-content-around">
                <button
                  className="btn btn-success"
                  onClick={(e) => handleApproval(e, row.id)}
                >
                  Req Approval
                </button>
                <button
                  className="btn btn-danger"
                  onClick={(e) => handleReject(e, row.id)}
                >
                  Reject
                </button>
              </div>
            );
          } else if (
            row.case_status.auditor_status === "ADMIN RETURN" &&
            entitlementCode === "AU001"
          ) {
            return (
              <div className="d-flex flex-row justify-content-around">
                <button
                  className="btn btn-success"
                  onClick={(e) => handleApproval(e, row.id)}
                >
                  Req.Approval
                </button>
                <button
                  className="btn btn-danger"
                  onClick={(e) => handleReject(e, row.id)}
                >
                  Reject
                </button>
              </div>
            );
          } else if (
            row.case_status.Accounts_Status === "PENDING" &&
            entitlementCode === "AC001"
          ) {
            return (
              <div className="d-flex flex-row justify-content-around">
                <button
                  className="btn btn-success"
                  onClick={(e) => handleAccept(e, row.id)}
                >
                  Accept
                </button>
                <button
                  className="btn btn-danger"
                  onClick={(e) => accountantReject(e, row.id)}
                >
                  Reject
                </button>
              </div>
            );
          } else if (
            row.status === "Submitted" &&
            entitlementCode === "AC001"
          ) {
            return (
              <div className="d-flex flex-column justify-content-evenly">
                <Button
                  variant="success"
                  size="sm"
                  onClick={(e) => handleInvoice(e, row.id)}
                  className="mb-2"
                >
                  Invoice
                </Button>
              </div>
            );
          } else {
            return null; // Return null if there's no action to render
          }
        },
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
          width: "25vw",
        },
      },
    ];
  } else if (entitlementCode === "AC001") {
    columns = [
      // Columns for entitlementCode 'AU001' or 'AA001'
      {
        text: "S.No",
        formatter: (cell, row, rowIndex) => {
          return rowIndex + 1;
        },
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
        },
      },
      {
        dataField: "case_id",
        text: "Case Id",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Case Id...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          widt: "15vw",
        },
        formatter: caseIdFormatter,
      },
      {
        dataField: "invoice_detail.updatedAt",
        text: "Submitted Date",
        filter: dateFilter({
          //   style: { display: "inline-grid" },
          className: "d-flex flex-row justify-content-around",
          withoutEmptyComparatorOption: true,
          comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
          comparatorStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          dateStyle: {
            padding: "3px 10px",
            border: "none",
            borderRadius: "3px",
          },
          placeholder: "dd/mm/yyyy",
        }),
        formatter: (cell) => formatDate(cell),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "25vw",
        },
      },
      //   {
      //     dataField: "name",
      //     text: "Circle",
      //     filter: textFilter({
      //       style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
      //       placeholder: "Circle...",
      //     }),
      //     headerAlign: "center",
      //     headerStyle: {
      //       backgroundColor: "#7366ff",
      //       color: "white",
      //       padding: "5px",
      //       width: "13vw",
      //     },
      //   },
      {
        dataField: "establishmentName",
        text: "Contractor",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Contractor...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "15vw",
        },
      },
      {
        dataField: "invoice_detail.payment_ref_no",
        text: "Payment Ref.No.",
        filter: textFilter({
          style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
          placeholder: "Payment Ref.No. ...",
        }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "5px 7px",
          width: "15vw",
        },
      },
      {
        dataField: "invoice_detail.net_amount",
        text: "Total Amount",
        // filter: textFilter({
        //   style: { padding: "3px 10px", border: "none", borderRadius: "3px" },
        //   placeholder: "Pay Ref Number ...",
        // }),
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "10px 7px",
        },
      },
      {
        text: "Action",
        formatter: (cell, row) => {
          if (
            row.case_status.Accounts_Status === "PENDING" &&
            entitlementCode === "AC001"
          ) {
            return (
              <div className="d-flex flex-row justify-content-around">
                <button
                  className="btn btn-success"
                  onClick={(e) => handleAccept(e, row.id)}
                >
                  Accept
                </button>
                <button
                  className="btn btn-danger"
                  onClick={(e) => accountantReject(e, row.id)}
                >
                  Reject
                </button>
              </div>
            );
          } else if (
            row.status === "Submitted" &&
            entitlementCode === "AC001"
          ) {
            return (
              <div className="d-flex flex-column justify-content-evenly">
                <Button
                  variant="success"
                  size="sm"
                  onClick={(e) => handleInvoice(e, row.id)}
                  className="mb-2"
                >
                  Invoice
                </Button>
              </div>
            );
          }
        },
        headerAlign: "center",
        headerStyle: {
          backgroundColor: "#7366ff",
          color: "white",
          padding: "15px 7px",
          width: "17vw",
        },
      },
    ];
  }
  const getRowStyles = (row) => {
    // Define the condition to apply custom styles to specific rows
    if (
      row.case_status.auditor_status === "ADMIN RETURN" ||
      row.case_status.Accounts_Status === "PAYMENT PENDING"
    ) {
      return { backgroundColor: "#fcc03887" }; // Return the custom style object for matching rows
    }
    // Return null to apply no custom style to other rows
    return null;
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "All",
        value: Case.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={(e) => ShowFilteredCases(e)}>
        <Col sm="12">
          <Card>
            {/* <HeaderCard title={TaskDetails} /> */}
            <CardBody className="grid-showcase grid-align">
              <SearchBar
                clearSearchFilter={clearSearchFilter}
                handleSearch={handleSearch}
              />
              {/* <SearchButton /> */}
            </CardBody>
          </Card>
        </Col>
        <Row>
          <Col>
            {loadingElement ? (
              loadingContent
            ) : (
              <BootstrapTable
                keyField="id"
                data={Case}
                columns={columns}
                headerClasses="header-class"
                filter={filterFactory()}
                hover
                rowStyle={getRowStyles}
                rowEvents={rowEvents}
                pagination={paginationFactory(options)}
              />
            )}
          </Col>
        </Row>
      </Form>
      {console.log("type......", type)}
      {modal ? (
        <ModalForm
          modal={modal}
          type={type}
          modalTitle={modalTitle}
          value={caseDetail}
          toggle={toggle}
          caseDetail={caseDetail}
          caseCreationDetail={caseCreationDetail}
          caseRemarks={caseRemarks}
          failedTransaction={failedTransaction}
          caseFlowHistoryDetails={caseFlowHistoryDetails}
          isViewClicked={isViewClicked}
          svcDetails={svcDetails}
          caseId={caseId}
          caseData={Case}
          size={size}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default UserComponent;
