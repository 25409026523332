import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../AbstractElements";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { circleActions, validationActions } from "../../_services";

const CircleCreationFormModal = ({ toggler, circleCreationToggle }) => {
  console.log("displayCircles in circle creation", circleCreationToggle);

  const [circle, setCircle] = useState([]);
  const orgId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [prefix, setPrefix] = useState(undefined);
  const [circleCode, setCircleCode] = useState(undefined);

  useEffect(() => {
    console.log("ViewCircle Useffect..");
    dispatch(circleActions.getAllCircle()).then((data) => {
      console.log("redux fropm circle creation", data);
      setCircle(data.payload);
    });
  }, []);

  const circlePrefixCode = circle.map((val) => {
    return `${val.location_code}`;
  });

  function checkCircleCode(event) {
    setCircleCode(handleCircleCode(event.target.value));
  }

  function handleCircleCode(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  function checkPrefix(event) {
    setPrefix(handlePrefix(event.target.value));
  }

  function handlePrefix(value) {
    const regex = /([A-Za-z]{0,2})/s;
    return value.match(regex)[0];
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const mailVAlidation = async (e) => {
    let mailId = e.target.value;
    await dispatch(validationActions.mailVAlidation(mailId)).then((res) => {
      if (res.payload === "Already exist") {
        setValue("email", "");
        toast.warn("Email Id already exists", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const onSubmit = async (data) => {
    let checker = (data.prefix + data.circleCode).toUpperCase();
    if (circlePrefixCode.includes(checker)) {
      console.log("EXISTS");
      setValue("circleCode", "");
      setValue("prefix", "");
      setCircleCode("");
      setPrefix("");
      toast.warn("circle - Circle code already exists", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (data !== "") {
      const reqBody = {
        name: data.cName.toUpperCase(),
        prefix: data.prefix.toUpperCase(),
        circle_code: data.circleCode,
        location_code: (data.prefix + data.circleCode).toUpperCase(),
        description: data.description.toUpperCase(),
        email_id: data.email.toUpperCase(),
        organization_id: orgId,
      };

      console.log(reqBody);
      dispatch(circleActions.createCircle(reqBody)).then((data) => {
        if (data.payload) {
          circleCreationToggle();
          toast.success("Circle created successfully!");
          toggler();
        }
        console.log("create redux", data);
      });
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md="12 mb-3">
            <Label className="form-label" for="circleName">
              Circle Name <span className="text-danger">*</span>
            </Label>
            <input
              className="form-control bg-light text-dark"
              autoComplete="off"
              id="circleName"
              type="text"
              placeholder="Circle Name"
              name="cName"
              {...register("cName", { required: true })}
            />
            <span className="d-block text-danger">
              {errors.cName && "Circle name is required"}
            </span>
            <div className="valid-feedback">Looks good!</div>
          </Col>
        </Row>
        <Row>
          <Col md="12 mb-3">
            <Label className="form-label" for="prefix">
              Prefix <span className="text-danger">*</span>
            </Label>
            <input
              className="form-control bg-light text-dark"
              autoComplete="off"
              id="prefix"
              value={prefix}
              type="text"
              placeholder="Prefix"
              name="prefix"
              maxlength="2"
              {...register("prefix", { required: true })}
              onChange={(event) => checkPrefix(event)}
            />
            <span className="d-block text-danger">
              {errors.prefix && "Prefix is required"}
            </span>
            <div className="valid-feedback">Looks good!</div>
          </Col>
        </Row>
        <Row>
          <Col md="12 mb-3">
            <Label className="form-label" for="circleCode">
              Circle Code <span className="text-danger">*</span>
            </Label>
            <input
              className="form-control bg-light text-dark"
              id="circleCode"
              autoComplete="off"
              value={circleCode}
              type="text"
              placeholder="Circle Code"
              maxlength="2"
              name="circleCode"
              {...register("circleCode", { required: true })}
              onChange={(event) => checkCircleCode(event)}
            />
            <span className="d-block text-danger">
              {errors.circleCode && "Circle code is required"}
            </span>
            <div className="valid-feedback">Looks good!</div>
          </Col>
        </Row>
        <Row>
          <Col md="12 mb-3">
            <Label className="form-label" for="email">
              Email Id <span className="text-danger">*</span>
            </Label>
            <input
              className="form-control bg-light text-dark"
              id="emil"
              autoComplete="off"
              type="text"
              placeholder="E-mail Id"
              name="email"
              {...register("email", { required: true })}
              onBlur={(e) => mailVAlidation(e)}
            />
            <span className="d-block text-danger">
              {errors.email && "Email Id is required"}
            </span>
            <div className="valid-feedback">Looks good!</div>
          </Col>
        </Row>
        <Row>
          <Col md="12 mb-3">
            <Label className="form-label" for="description">
              Description
            </Label>
            <textarea
              className="form-control bg-light text-dark"
              id="description"
              autoComplete="off"
              type="textarea"
              placeholder="About circle..."
              name="description"
              rows={3}
              {...register("description")}
            />
            <div className="valid-feedback">Looks good!</div>
          </Col>
        </Row>
        <div></div>
        <Btn attrBtn={{ color: "primary" }}>{"Submit"}</Btn>
      </Form>
      {/* <ToastContainer /> */}
    </Fragment>
  );
};

export default CircleCreationFormModal;
