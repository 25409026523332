import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "Users";
console.log("counterss");
function createInitialState() {
  console.log("1");
  return {
    User: [],
    error: null,
    loader: false,
  };
}

function createReducers() {
  function toggleAuthPopup(state, { payload }) {
    state.checkPopup = payload;
  }
  function updateAuthMessage(state, { payload }) {
    state.unAuthMessage = payload;
  }
  return {
    toggleAuthPopup,
    updateAuthMessage,
  };
}

function createExtraActions() {
  console.log("2");
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  function getAllUser() {
    return createAsyncThunk(
      `/getAllUser`,
      async () => await fetchAPIWapper.get(`${baseUrl}/users/getAll`)
    );
  }

  function createUser() {
    console.log("3");
    return createAsyncThunk(
      `/createUser`,
      async (request) =>
        await fetchAPIWapper.post(`${baseUrl}/users/create`, request)
    );
  }
  function getUserById() {
    console.log("4");
    return createAsyncThunk(
      `/getUserById`,
      async (id) => await fetchAPIWapper.get(`${baseUrl}/users/get/${id}`)
    );
  }
  function updateUserById() {
    return createAsyncThunk(
      `/UpdateUserById`,
      async ({ id, reqBody }) =>
        await fetchAPIWapper.put(`${baseUrl}/users/update/${id}`, reqBody)
    );
  }

  return {
    getAllUser: getAllUser(),
    createUser: createUser(),
    getUserById: getUserById(),
    updateUserById: updateUserById(),
  };
}

function createExtraReducers() {
  console.log("7");
  function getAllUser() {
    let { pending, fulfilled, rejected } = extraActions.getAllUser;

    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("getAllUser _service--> " + action.payload);
        state.User = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function createUser() {
    console.log("8");
    let { pending, fulfilled, rejected } = extraActions.createUser;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("createUser _service--> " + action.payload);
        state.User = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function getUserById() {
    console.log("9");
    let { pending, fulfilled, rejected } = extraActions.getUserById;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        console.log("getUserById _service --> " + action.payload);
        state.User = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }
  function updateUserById() {
    console.log("10");
    let { pending, fulfilled, rejected } = extraActions.updateUserById;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        state.User = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }

  return {
    ...createUser(),
    ...getAllUser(),
    ...getUserById(),
    ...updateUserById(),
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;
