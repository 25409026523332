import React, { useState } from "react";
import { Col, Row, Label, FormGroup, Input, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";

const SearchBar = ({ clearSearchFilter, contractorOptions, handleSearch }) => {
	const entitlementCode = localStorage.getItem("entitilement");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [selectedOption, setSelectedOption] = useState([]);
	const animatedComponents = makeAnimated();
	let filterContractorOptions = [];
	if (contractorOptions) {
		console.log("Contractor option in search options", contractorOptions);
		filterContractorOptions = contractorOptions.map((eachContractor) => ({
			value: eachContractor.id,
			label: eachContractor.establishment_name,
		}));
	}

	console.log("filtered options.....", filterContractorOptions);

	const startDateChange = (date) => {
		setStartDate(date);
	};

	const endDateChange = (date) => {
		setEndDate(date);
	};

	const handleSearchClick = () => {
		const formatStartDate = moment(startDate).format("YYYY-MM-DD");
		const formatEndDate = moment(endDate).format("YYYY-MM-DD");
		console.log("selected option................", selectedOption);
		if (selectedOption.length !== 0) {
			const selectedContractorIds = selectedOption.map(
				(eachSelectedOption) => eachSelectedOption.value
			);
			handleSearch(formatStartDate, formatEndDate, selectedContractorIds);
		} else {
			handleSearch(startDate, endDate);
		}
	};

	const handleChange = (selectedOption) => {
		console.log("selected option.....", selectedOption);
		setSelectedOption(selectedOption);
	};
	// const clearFilter = () => {
	// 	window.location.reload();
	// };
	return (
		<Row className="d-flex flex-row justify-content-between align-items-center">
			<Col className="col">
				<FormGroup>
					<Label>Choose Start Date</Label>
					<DatePicker
						className="form-control digits"
						selected={startDate}
						onChange={startDateChange}
						id="startDate"
						dateFormat="yyyy-MM-dd"
					/>
				</FormGroup>
			</Col>

			<Col className="col">
				<FormGroup>
					<Label>Choose End Date</Label>
					<DatePicker
						className="form-control digits"
						selected={endDate}
						onChange={endDateChange}
						id="endDate"
						dateFormat="yyyy-MM-dd"
					/>
				</FormGroup>
			</Col>
			{entitlementCode === "MSVP001" && (
				<Col>
					<FormGroup>
						<Label>Select Contractor</Label>
						<Select
							className="basic-multi-select"
							classNamePrefix={"Select Contractor"}
							closeMenuOnSelect={false}
							options={filterContractorOptions}
							components={animatedComponents}
							isMulti
							value={selectedOption}
							onChange={handleChange}
							placeholder={"Select Contractor..."}
						/>
					</FormGroup>
				</Col>
			)}

			<Col className="col">
				<div className="d-flex flex-row justify-content-end">
					<Button
						className="me-3"
						color="primary"
						onClick={() => handleSearchClick()}
					>
						Search
					</Button>
					<Button onClick={() => clearSearchFilter()}>Clear</Button>
				</div>
			</Col>
		</Row>
	);
};
export default SearchBar;
