import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth.service";
import { dashboardReducer } from "./dashboard.service";
import { circleReducer } from "./circle.service";
import { userReducer } from "./user.service";
import { contractorReducer } from "./contractor.service";
import { caseReducer } from "./case.service";
import { reportsReducer } from "./report.service";
import { validationReducer } from "./validation.service";

export * from "./auth.service";
export * from "./dashboard.service";
export * from "./circle.service";
export * from "./user.service";
export * from "./contractor.service";
export * from "./case.service";
export * from "./report.service";
export * from "./validation.service";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    circle: circleReducer,
    user: userReducer,
    contractor: contractorReducer,
    caseReducer: caseReducer,
    reportsReducer: reportsReducer,
    validationReducer: validationReducer,
  },
});
