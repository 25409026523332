import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardBody, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import UserViewChildPage from "./UpdateCircleAndLabour";
import { useDispatch } from "react-redux";
import { contractorActions, validationActions } from "../../_services";

const EditContractor = ({ toggle, value, contractorUpdateToggle }) => {
	console.log("value", value);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const id = value[0].id;
	const circleData = value[2];
	const [isEditMode, setIsEditMode] = useState(false);
	const [rowsData, setRowsData] = useState([]);
	const [delData, SetDelData] = useState([]);
	const circleOptionVal = [];
	const orgId = localStorage.getItem("userId");
	const entitlementCode = localStorage.getItem("entitilement");
	const dispatch = useDispatch();
	console.log("circleOptionVal");
	console.log(circleOptionVal);
	let email = value[1][0].contact_detail;

	const handleEdit = () => {
		setIsEditMode(true);
	};
	const circleHandleChange = (index, evnt) => {
		console.log("evnt");
		console.log(evnt);
		// const { label, value } = evnt;
		const rowsInput = [...rowsData];
		rowsInput[index].circle = evnt.value;
		console.log("rowsInput ********************************");
		console.log(rowsInput);
		setRowsData(rowsInput);
	};
	const labourHandleChange = (index, evnt) => {
		console.log("evnt");
		console.log(evnt.target.value);
		// const { label, value } = evnt;
		const rowsInput = [...rowsData];
		rowsInput[index].labour_number = evnt.target.value.toUpperCase();
		console.log(rowsInput);
		setRowsData(rowsInput);
	};
	const addTableRows = () => {
		const rowsInput = {
			circle: 0,
			labour_number: "",
			// isActive: true,
		};
		setRowsData([...rowsData, rowsInput]);
		console.log("rowsData.map((data) => data)");
		console.log(rowsData.map((data) => data));
	};

	const deleteTableRows = (index) => {
		const rows = [...rowsData];
		console.log("IndeValue", index);
		// rows[index].isActive = false;
		// console.log("rows[index] :", rows[index]);

		// // delArray.push();
		// delData.push(rows[index]);
		// // SetDelData(rows[index]);
		// // console.log("delArray--->", delArray);
		console.log("delData--->", delData);
		rows.splice(index, 1);
		setRowsData(rows);
	};

	useEffect(() => {
		setValue("eName", value[0].establishment_name);
		setValue("pName", value[0].proprietor_name);
		setValue("gstNo", value[0].gst_number);
		setValue("esiNo", value[0].esi_number);
		setValue("epfNo", value[0].epf_number);
		setValue("LLNo", value[0].labour_number);
		setValue("mbNo", value[1][1].contact_detail);
		setValue("email", value[1][0].contact_detail);
	}, [value, setValue]);

	const Displayalert = () => {
		toast.success("Contractor Details Updated Successfully !", {
			position: toast.POSITION.TOP_RIGHT,
		});
		/* Toast.fire({
      text: "Contractor details updated successfully",
      icon: "success",
    }); */
	};
	const mailVAlidation = async (e) => {
		let mailId = e.target.value.toUpperCase();
		if (mailId != email) {
			await dispatch(validationActions.mailVAlidation(mailId)).then((res) => {
				if (res.payload === "Already exist") {
					setValue("email", "");
					toast.warn("Email Id already exists", {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			});
		}
	};

	const onSubmit = (data) => {
		if (rowsData.length === 0) {
			alert("Please select Circle and Fill Labor License Number");
			return;
		} else {
			for (let i = 0; i < rowsData.length; i++) {
				console.log(typeof rowsData[i].circle);
				if (typeof rowsData[i].circle === "object") {
					// console.log(`before : ${rowsData[i].circle}`);
					rowsData[i].circle = rowsData[i].circle.value;
					// console.log(`after : ${rowsData[i].circle}`);
				} else {
					if (rowsData[i].circle !== 0 && rowsData[i].labour_number !== "") {
					} else {
						alert("Please select Circle and Fill Labor License Number ");
						return;
					}
				}
			}
		}
		if (data) {
			const reqBody = {
				establishment_name: data.eName.toUpperCase(),
				proprietor_name: data.pName.toUpperCase(),
				gst_number: data.gstNo.toUpperCase(),
				esi_number: data.esiNo.toUpperCase(),
				epf_number: data.epfNo.toUpperCase(),
				contact: [
					{ contactType: "email", contactDetails: data.email.toUpperCase() },
					{ contactType: "mobile", contactDetails: data.mbNo },
				],
				circle: rowsData,
				user_name: data.mbNo,
				password: data.mbNo.slice(0, 4),
				role_id: 4,
				organization_id: orgId,
				is_primary: true,
			};
			console.log("reqBody", reqBody);
			dispatch(contractorActions.updateContractorById({ id, reqBody })).then(
				(data) => {
					console.log("update data from redux", data);
					if (
						data.payload.message === "Updated successfully" &&
						data.payload.success === true
					) {
						contractorUpdateToggle();
						Displayalert();
						toggle();
					} else if (data.payload.success === false) {
						// alert("something wrong");
						toast.error(data.payload.message);
					} else {
						toast.error("Error updating data");
					}
				}
			);
		} else {
			errors.showMessages();
		}
	};
	return (
		<Fragment>
			<Card>
				<CardBody>
					<Form
						autoComplete="off"
						className="needs-validation"
						noValidate=""
						onSubmit={handleSubmit(onSubmit)}
					>
						<Row>
							<Col md="6 mb-1">
								<Label className="form-label" for="validationCustom01">
									Establishment Name<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom01"
									autoComplete="off"
									type="text"
									placeholder="Establishment name"
									name="eName"
									disabled={!isEditMode}
									{...register("eName", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.eName && "Est.name is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
							<Col md="6 mb-1">
								<Label className="form-label" for="validationCustom02">
									Proprietor Name<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom02"
									autoComplete="off"
									type="text"
									placeholder="Proprietor Name"
									name="pName"
									disabled={!isEditMode}
									{...register("pName", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.pName && "Proprietor name is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<Row>
							<Col md="6 mb-1">
								<Label className="form-label" for="validationCustom03">
									GST Number<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom03"
									autoComplete="off"
									type="text"
									placeholder="GST Number"
									name="gstNo"
									disabled={!isEditMode}
									{...register("gstNo", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.gstNo && "GST number is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>

							<Col md="6 mb-1">
								<Label className="form-label" for="validationCustom04">
									ESI Number<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom04"
									autoComplete="off"
									type="text"
									placeholder="ESI Number"
									name="esiNo"
									disabled={!isEditMode}
									{...register("esiNo", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.esiNo && "ESI number is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<Row>
							<Col md="6 mb-1">
								<Label className="form-label" for="validationCustom05">
									EPF Number<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom05"
									autoComplete="off"
									name="epfNo"
									type="text"
									placeholder="EPF Number"
									disabled={!isEditMode}
									{...register("epfNo", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.epfNo && "EPF number is required."}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
							<Col md="6 mb-1">
								<Label className="form-label" for="validationCustom07">
									Mobile Number(user_id)<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control b-secondary"
									id="validationCustom07"
									autoComplete="off"
									type="text"
									pattern="[0-9]*"
									placeholder="mobile Number"
									disabled="true"
									maxlength="10"
									{...register("mbNo", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.mbNo && "Mobile number is required."}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Label className="form-label" for="validationCustom08">
									Email ID<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom08"
									autoComplete="off"
									type="email"
									placeholder="Email ID"
									name="email"
									disabled={!isEditMode}
									{...register("email", { required: true })}
									onBlur={(e) => mailVAlidation(e)}
								/>
								<span className="d-block text-danger">
									{errors.email && "Email is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<div className="col-sm-12">
							<table className="table">
								<thead>
									<tr>
										<th>Circle</th>
										<th>Labour License Number</th>
										<th>
											<button
												className="btn btn-outline-success"
												onClick={addTableRows}
												type="button"
												disabled={!isEditMode}
											>
												+
											</button>
										</th>
									</tr>
								</thead>
								<tbody>
									<UserViewChildPage
										value={value}
										circleHandleChange={circleHandleChange}
										labourHandleChange={labourHandleChange}
										deleteTableRows={deleteTableRows}
										rowsData={rowsData}
										EditCondition={!isEditMode}
									/>
								</tbody>
							</table>
						</div>

						{isEditMode &&
						(entitlementCode === "AG001" ||
							entitlementCode === "ORG001" ||
							entitlementCode === "CA001") ? (
							<>
								<Button
									color="secondary"
									className="me-3"
									onClick={() => setIsEditMode(false)}
								>
									Cancel
								</Button>
								<Button color="primary">{"Update"}</Button>
							</>
						) : entitlementCode === "AG001" ||
						  entitlementCode === "ORG001" ||
						  entitlementCode === "CA001" ? (
							<Button color="primary" onClick={handleEdit}>
								Edit
							</Button>
						) : (
							""
						)}
					</Form>
				</CardBody>
			</Card>
		</Fragment>
	);
};

export default EditContractor;
