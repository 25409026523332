import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
//import HeaderCard from '../../Components/Common/Component/HeaderCard'
import UserComponent from "./viewCase";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import { useNavigate } from "react-router-dom";

const Cases = () => {
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const modalTitle = "Case Creation Form";
	const type = "add";
	//search input element
	const [searchText, setSearchText] = useState("");

	const handleAdd = () => {
		navigate(`${process.env.PUBLIC_URL}/case/creation`);
	};

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
	};

	const entitlementCode = localStorage.getItem("entitilement");

	return (
		<Fragment>
			<Breadcrumbs parent="Master's" title="Cases" mainTitle="Cases" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								{/* <div className="d-flex flex-row justify-content-end mb-3">
									{entitlementCode === "AG001" ||
									entitlementCode === "ORG001" ||
									entitlementCode === "CA001" ||
									entitlementCode === "AA001" ||
									entitlementCode === "AU001" ||
									entitlementCode === "AC001" ? (
										""
									) : (
										<Button
											color="primary"
											className="mb-3"
											onClick={handleAdd}
										>
											Add New
										</Button>
									)}
								</div>
								{modal ? (
									<ModalForm
										modal={modal}
										toggle={toggle}
										modalTitle={modalTitle}
										type={type}
									/>
								) : (
									""
								)} */}
								<UserComponent searchText={searchText} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Cases;
