import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";

const CaseDocumentViewPage = (props) => {
  const { viewCaseId } = props;
  console.log("view documents...", viewCaseId);
  console.log("view Case Id...", viewCaseId);
  const [documentDetails, setDocumentDetails] = useState([]);
  const documentTypes = [
    "Application",
    "Labour License Copy",
    "Attendance Register",
    "Wages Register",
    "EPF Payment Confirmation Receipt",
    "EPF Combined Challan",
    "EPF ECR Electronic Challan cum Return",
    "ESI Transaction Details Challan",
    "ESI Monthly Contribution Detail",
    "Other Documents",
  ];
  const caseDetailsId = viewCaseId;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(caseActions.getAllCaseDocuments(caseDetailsId)).then((data) => {
      console.log("caseDocument redux ----->", data);
      if (data.payload) {
        setDocumentDetails(data.payload);
      }
    });
  }, []);

  const handleViewDocument = (filePath) => {
    console.log("file Path....", filePath);
    // fetchCaseDocument(filePath).then((response) => {
    try {
      // const fileURL = URL.createObjectURL(response.data);
      // // window.open(fileURL);
      // const newTab = window.open();
      // newTab.document.open();
      // newTab.document.write(`
      //   <html>
      //     <body>
      //       <embed src="${fileURL}" height="100%" width="100%" type="application/pdf"/>
      //     </body>
      //   </html>
      // `);
      // newTab.document.close();
      const newTab = window.open();
      newTab.document.open();
      newTab.document.write(`
						  <html>
							<body>
							  <embed src="${filePath}" height="100%" width="100%" type="application/pdf"/>
							</body>
						  </html>
						`);
      newTab.document.close();
    } catch (error) {
      console.log("error...", error);
    }
    // });
  };

  return (
    <Card>
      <CardBody>
        <ul>
          {documentDetails.map((documents, index) => (
            <li
              key={documents.id}
              className="d-flex flex-row justify-content-between mb-3"
            >
              <h6 className="w-50">{documentTypes[index]}</h6>
              <p className="w-35">{documents.document_name}</p>
              <Button
                size="md"
                onClick={() => handleViewDocument(documents.document_path)}
                // 				onClick={
                // 					() =>
                // 						window.open(`
                // 					<html>
                // 					<body>
                // 						<embed src="${absoluteFileDirectory}${documents.document_path}" height="100%" width="100%" type="application/pdf"/>
                // 					</body>
                // 					</html>
                // `)
                // `${absoluteFileDirectory}${documents.document_path}`
                // 	}
              >
                View
              </Button>
            </li>
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default CaseDocumentViewPage;
