import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { caseActions, circleActions, contractorActions } from "../../_services";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const EditCase = () => {
  const navigate = useNavigate();
  const { caseId } = useParams();
  const svcProviderId = localStorage.getItem("userId");
  const [selectCircles, setSelectCircles] = useState([]);
  const [orgSvcDetails, setOrgSvcDetails] = useState([]);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(contractorActions.getContractorById(svcProviderId)).then(
      async (data) => {
        console.log("service provider data edit case from redux....", data);
        const svcDetails = data.payload[0];
        const emailId = data.payload[1][0].contact_detail;
        const orgSvcMap = data.payload[2];
        setOrgSvcDetails(orgSvcMap);
        console.log("org svc map....", orgSvcMap);
        const orglocationsId = orgSvcMap.map(
          (eachOrgSvcMap) => eachOrgSvcMap.org_location_id
        );
        await dispatch(circleActions.getAllCircle()).then((data) => {
          console.log("circles data from editcase redux...", data);
          const filteredCircleNames = [];
          for (let orgLocationId of orglocationsId) {
            const filterCircles = data.payload.filter(
              (eachCircle) => eachCircle.id === orgLocationId
            );
            filteredCircleNames.push({
              name: filterCircles[0].name,
              circleId: filterCircles[0].id,
            });
          }
          setSelectCircles(filteredCircleNames);
        });
        await dispatch(caseActions.getCaseById(caseId)).then((data) => {
          console.log("case creation Details", data);
          const {
            acceptance_order_no,
            agreement_no,
            nature_work,
            work_start_date,
            work_end_date,
            org_location_id,
          } = data.payload;

          // console.log("circle Id...", org_location_id);
          // fetchCircle(org_location_id).then((response) => {
          // 	const { name } = response.data;
          // 	setCircle(name);
          // 	const circleDropdown = selectCircles.filter((eachCircle) => {
          // 		if (eachCircle.circleId !== org_location_id) {
          // 			return true;
          // 		}
          // 		return false;
          // 	});
          // 	setSelectCircles(circleDropdown);
          // });

          setValue("acceptanceOrderNo", acceptance_order_no);
          setValue("agreementNo", agreement_no);
          setValue("workStartDate", work_start_date);
          setValue("workEndDate", work_end_date);
          setValue("natureOfWork", nature_work);
          setValue("natureOfWork", nature_work);
          setValue("circleId", org_location_id);

          const filteredData = orgSvcMap.filter((labour_number) => {
            if (labour_number.org_location_id === org_location_id) {
              return true;
            }
            return false;
          });
          console.log("filteredData", filteredData);
          setValue("labourLicense", filteredData[0].labour_number);
        });
        setValue("contractorFirm", svcDetails.establishment_name);
        setValue("email", emailId);
        setValue("epfRegistration", svcDetails.epf_number);
        setValue("esiRegistration", svcDetails.esi_number);
      }
    );
  }, []);

  const handleCircleChange = (event) => {
    // console.log("circle id", event.target.value);
    if (event.target.value) {
      const filteredData = orgSvcDetails.filter((labour_number) => {
        if (labour_number.org_location_id == event.target.value) {
          return true;
        }
        return false;
      });
      console.log("filteredData", filteredData);
      setValue("labourLicense", filteredData[0].labour_number);
    } else {
      toast.error("Please select the circle");
      /*       Toast.fire({
        icon: "error",
        text: "Please choose circle",
      }); */
    }
  };

  const onSubmit = async (formData) => {
    if (formData !== "") {
      // console.log("data...",data)
      const reqBody = {
        organizationId: 1,
        auditAgencyId: 1,
        svcProviderId: svcProviderId,
        orgLocationId: formData.circleId,
        subServiceProviderId: svcProviderId,
        acceptanceOrderNo: formData.acceptanceOrderNo.toUpperCase(),
        agreementNo: formData.agreementNo.toUpperCase(),
        workStartDate: formData.workStartDate,
        workEndDate: formData.workEndDate,
        natureOfWork: formData.natureOfWork.toUpperCase(),
        svcProviderStatus: "DRAFT",
      };
      await dispatch(caseActions.updateCaseById({ caseId, reqBody })).then(
        (data) => {
          console.log("upsate case response redux ", data);
          if (
            data.payload == "success" &&
            data.type == "/UpdateCaseById/fulfilled"
          ) {
            navigate(`${process.env.PUBLIC_URL}/EditCaseDetails/${caseId}`);
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardHeader
          className="text-center text-dark fs-3"
          style={{ padding: "5px" }}
        >
          Case Creation Form
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="contractorFirm">
                  Name of the Contractor Firm{" "}
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="contractorFirm"
                  autoComplete="off"
                  type="text"
                  placeholder="Contractor Firm"
                  name="contractorFirm"
                  disabled
                  {...register("contractorFirm", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.contractorFirm && "Contractor Firm is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="email">
                  Email Id
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="prefix"
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                  name="email"
                  disabled
                  {...register("email", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.email && "Email is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="epfRegistration">
                  EPF Registration Number
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="epfRegistration"
                  autoComplete="off"
                  type="text"
                  placeholder="EPF Registration Number"
                  name="epfRegistration"
                  disabled
                  {...register("epfRegistration", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.epfRegistration &&
                    "EPF Registration Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="esiRegistration">
                  ESI Registration Number
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="esiRegistration"
                  autoComplete="off"
                  type="text"
                  placeholder="ESI Registration Number"
                  name="esiRegistration"
                  disabled
                  {...register("esiRegistration", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.esiRegistration &&
                    "ESI Registration Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="circleName">
                  Name of the Circle <span className="text-danger">*</span>
                </Label>
                {/* <input
									className="form-control"
									id="circleName"
									type="text"
									placeholder="Circle Name"
									name="circleName"
									{...register("circleName", { required: true })}
								/> */}
                <select
                  className="form-select bg-light text-dark"
                  placeholder="Select circle Name"
                  autoComplete="off"
                  name="circleId"
                  {...register("circleId", { required: true })}
                  onChange={(e) => handleCircleChange(e)}
                >
                  {selectCircles.map((eachCircle) => (
                    <option
                      key={eachCircle.circleId}
                      value={eachCircle.circleId}
                    >
                      {eachCircle.name}
                    </option>
                  ))}
                </select>
                <span className="d-block text-danger">
                  {errors.circleName && "Circle Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="labourLicense">
                  Labour License Number
                </Label>
                <input
                  className="form-control bg-light text-dark b-secondary"
                  id="labourLicense"
                  autoComplete="off"
                  type="text"
                  placeholder="Labour License Number"
                  name="labourLicense"
                  disabled
                  {...register("labourLicense", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.labourLicense && "Labour License Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="acceptanceNo">
                  Acceptance/ Formal Order No{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="acceptanceNo"
                  autoComplete="off"
                  type="text"
                  placeholder="Acceptance Number"
                  name="acceptanceOrderNo"
                  {...register("acceptanceOrderNo", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.acceptanceOrderNo && "Acceptance Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="agreementNumber">
                  Agreement Number <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="agreementNumber"
                  autoComplete="off"
                  type="text"
                  placeholder="Agreement Number"
                  name="agreementNo"
                  {...register("agreementNo", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.agreementNo && "Agreement Number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="workStartDate">
                  Period of work start date{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="workStartDate"
                  type="date"
                  autoComplete="off"
                  placeholder="Work start date"
                  name="workStartDate"
                  {...register("workStartDate", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.workStartDate && "Work start date is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="workEndDate">
                  Period of work end date <span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="workEndDate"
                  type="date"
                  autoComplete="off"
                  placeholder="Work end date"
                  name="workEndDate"
                  {...register("workEndDate", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.workEndDate && "Work end date is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="12 mb-3">
                <Label className="form-label" for="workNature">
                  Nature of work <span className="text-danger">*</span>
                </Label>
                <textarea
                  className="form-control bg-light text-dark"
                  id="workNature"
                  autoComplete="off"
                  type="textarea"
                  placeholder="Nature of work"
                  name="natureOfWork"
                  rows="5"
                  {...register("natureOfWork", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.natureOfWork && "Nature of work is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button variant="primary" type="submit">
                  Update and Next
                </Button>
              </Col>
            </Row>
            {/* <Row>
                <Col md='2 mb-3'>
                <Label className='form-label' for='year'>
                    Year <span className="text-danger">*</span>
                  </Label>
                  <input className='form-control' id='year' type='text' placeholder='Year' name='year' {...register('year', { required: true })} />
                  <span className='d-block text-danger'>{errors.year&& 'Year is required'}</span>
                  <div className='valid-feedback'>Looks good!</div>
                </Col>
                <Col md='2 mb-3'>
                <Label className='form-label' for='month'>
                    Month <span className="text-danger">*</span>
                  </Label>
                  <input className='form-control' id='month' type='text' placeholder='Month' name='month' {...register('month', { required: true })} />
                  <span className='d-block text-danger'>{errors.month&& 'Month is required'}</span>
                  <div className='valid-feedback'>Looks good!</div>
                </Col>
                <Col md='2 mb-3'>
                <Label className='form-label' for='manDays'>
                    Man Days <span className="text-danger">*</span>
                  </Label>
                  <input className='form-control' id='manDays' type='text' placeholder='Man Days' name='manDays' {...register('manDays', { required: true })} />
                  <span className='d-block text-danger'>{errors.manDays&& 'Man Days is required'}</span>
                  <div className='valid-feedback'>Looks good!</div>
                </Col>
                <Col md='3 mb-3'>
                <Label className='form-label' for='epfTransactionNumber'>
                    EPF Transaction Number <span className="text-danger">*</span>
                  </Label>
                  <input className='form-control' id='epfTransactionNumber' type='text' placeholder='EPF Transaction Number' name='epfTransactionNumber' {...register('epfTransactionNumber', { required: true })} />
                  <span className='d-block text-danger'>{errors.epfTransactionNumber&& 'EPF Transaction Number is required'}</span>
                  <div className='valid-feedback'>Looks good!</div>
                </Col>
                <Col md='3 mb-3'>
                <Label className='form-label' for='esiTransactionNumber'>
                    ESI Transaction Number <span className="text-danger">*</span>
                  </Label>
                  <input className='form-control' id='esiTransactionNumber' type='text' placeholder='ESI Transaction Number' name='esiTransactionNumber' {...register('esiTransactionNumber', { required: true })} />
                  <span className='d-block text-danger'>{errors.esiTransactionNumber&& 'ESI Transaction Number is required'}</span>
                  <div className='valid-feedback'>Looks good!</div>
                </Col>
              </Row> */}
            {/* <Btn attrBtn={{ color: 'primary' }}>{'Submit'}</Btn> */}
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EditCase;
