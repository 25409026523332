import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";
console.log("counterss");
const name = "dashboard";
function createInitialState() {
  return {
    dashboard: {},
    error: null,
    loader: false,
  };
}

function createReducers() {
  function toggleAuthPopup(state, { payload }) {
    state.checkPopup = payload;
  }
  function updateAuthMessage(state, { payload }) {
    state.unAuthMessage = payload;
  }
  return {
    toggleAuthPopup,
    updateAuthMessage,
  };
}

function createExtraActions() {
  console.log("2");
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  function getDashboardDetail() {
    return createAsyncThunk(
      `/dashboardDetails`,
      async ({ userId, type }) =>
        await fetchAPIWapper.get(
          `${baseUrl}/dashboard/${userId}?userType=${type}`
        )
    );
  }

  return {
    getDashboardDetail: getDashboardDetail(),
  };
}

function createExtraReducers() {
  console.log("7");
  function getDashboardDetail() {
    let { pending, fulfilled, rejected } = extraActions.getDashboardDetail;

    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
      },
      [fulfilled]: (state, action) => {
        state.dashboard = action.payload ? action.payload : {};
        state.loader = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
      },
    };
  }

  return {
    ...getDashboardDetail(),
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const dashboardActions = { ...slice.actions, ...extraActions };
export const dashboardReducer = slice.reducer;
