import Select from "react-select";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { labourNoValidation } from "../../service/user-service";
import { toast } from "react-toastify";
import { circleActions, contractorActions, userActions } from "../../_services";

const CircleAndLabourValue = ({
  circleHandleChange,
  labourHandleChange,
  deleteTableRows,
  rowsData,
}) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const entitlementCode = localStorage.getItem("entitilement");
  const userId = localStorage.getItem("userId");
  console.log("Entitlement Code......", entitlementCode);

  const labourNumberValidation = async (event) => {
    console.log("................", event.target.value);
    const labourNumber = event.target.value;
    dispatch(contractorActions.labourNoValidation(labourNumber)).then(
      (data) => {
        if (data.payload !== null) {
          event.target.value = "";
          toast.warning("Labour Number already exists");
        }
      }
    );
  };

  useEffect(() => {
    const getLocation = async () => {
      await dispatch(circleActions.getCircleNames()).then((data) => {
        const cirlcesArray = data.payload;
        console.log("Circles Array from redux payload.....", cirlcesArray);
        if (entitlementCode === "CA001") {
          if (cirlcesArray) {
            dispatch(userActions.getUserById(userId)).then((data) => {
              console.log("response form user detetail redux", data);
              const { circle_id } = data.payload[0];
              const filterCircle = cirlcesArray.filter(
                (circle) => circle.value === circle_id
              );
              console.log("filterCircle.......", filterCircle);
              setOptions(filterCircle);
            });
          }
        } else {
          if (data.payload) {
            console.log("circle data from redux", data);
            setOptions(data.payload);
          }
        }
      });
    };
    getLocation();
  }, []);
  return rowsData.map((data, index) => {
    return (
      <>
        <tr key={index}>
          <td>
            <Select
              options={options}
              autoComplete="off"
              onChange={(event) => circleHandleChange(index, event)}
              className="basic-multi-select  bg-light text-dark"
              classNamePrefix="select"
            />
          </td>
          <td>
            <input
              type="text"
              autoComplete="off"
              // onBlur={(event) => labourNumberValidation(event)}
              onChange={(evnt) => labourHandleChange(index, evnt)}
              className="form-control bg-light text-dark"
            />{" "}
          </td>
          <td>
            <button
              className="btn btn-outline-danger"
              onClick={() => deleteTableRows(index)}
              type="button"
            >
              x
            </button>
          </td>
        </tr>
      </>
    );
  });
};
export default CircleAndLabourValue;
