import React, { Fragment, useState } from "react";
import CommonModal from "./commonModal";
import CaseDetailsTable from "../CaseDetailsTable";
import PaymentRefForm from "../PaymentRefForm";
import CertiticateselectionForm from "../certificateSelectionForm";
import CaseFlowHistoryDetails from "../CaseFlowHistoryDetails";

const ModalForm = ({
  modal,
  toggle,
  modalTitle,
  type,
  uploadCaseId,
  caseDetail,
  viewCaseId,
  changeStatus,
  caseCreationDetail,
  caseRemarks,
  isViewClicked,
  svcDetails,
  size,
  caseId,
  failedTransaction,
  caseFlowHistoryDetails,
  refundAmount,
  caseData,
}) => {
  console.log("caseStaus...", caseId);
  console.log("caseStaus...", size);
  console.log("failedTransaction", failedTransaction);
  console.log("caseData", caseData);
  console.log("refundAmount", refundAmount);

  return (
    <Fragment>
      {/* <Btn attrBtn={{ color: 'primary', onClick: toggle }} >{VerticallyCentered}</Btn> */}
      <CommonModal
        isOpen={modal}
        title={modalTitle}
        toggler={toggle}
        size={size}
        type={type}
        caseDetail={caseDetail}
      >
        {type === "view" && (
          <CaseDetailsTable
            caseDetails={caseDetail}
            type={type}
            caseCreationDetails={caseCreationDetail}
            caseRemarks={caseRemarks}
            failedTransaction={failedTransaction}
            caseId={caseId}
            svcDetails={svcDetails}
            isViewClicked={isViewClicked}
          />
        )}
        {/* {type !== "view" && (
					<CaseUploadForm
						toggle={toggle}
						caseDetailsId={uploadCaseId}
						changeStatus={changeStatus}
					/>
				)} */}
        {type === "makePayment" && (
          <PaymentRefForm
            caseId={caseId}
            toggle={toggle}
            refundAmount={refundAmount}
          />
        )}
        {type === "caseCsertificate" && (
          <CertiticateselectionForm caseId={caseId} toggle={toggle} />
        )}
        {type === "caseFlow" && (
          <CaseFlowHistoryDetails
            caseFlowHistoryDetails={caseFlowHistoryDetails}
          />
        )}
      </CommonModal>
    </Fragment>
  );
};

export default ModalForm;
