import Select from "react-select";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { circleActions } from "../../_services";

const UserViewChildPage = ({
  circleHandleChange,
  labourHandleChange,
  deleteTableRows,
  rowsData,
  value,
  EditCondition,
}) => {
  const [options, setOptions] = useState([]);
  const updateCircle = value[3];
  const laborNumber = value[2];
  console.log("value");
  console.log(value);
  console.log("value");
  console.log("updateCircle-->", updateCircle);
  console.log("lavour", laborNumber);
  console.log("rowdata---->", rowsData);
  const dispatch = useDispatch();
  useEffect(() => {
    const getLocation = async () => {
      await dispatch(circleActions.getCircleNames()).then((data) => {
        if (data.payload) {
          console.log(data.payload);
          setOptions(data.payload);
        }
      });
    };
    getLocation();
    userSelectedValueSet();
  }, []);

  const userSelectedValueSet = () => {
    for (let i = 0; i < laborNumber.length; i++) {
      const dataSet = {
        circle: {},
        labour_number: "",
      };
      dataSet.circle = updateCircle[i];
      dataSet.labour_number = laborNumber[i].labour_number;
      rowsData.push(dataSet);
      console.log("dataSet");
      console.log(dataSet);
    }
  };

  console.log("rowsData Values : ");
  console.log(rowsData);
  // dataSet.provider.push(element)
  return rowsData.map((data, index) => {
    // const { role, provider, workgroup } = data;
    // console.log("From Map Loop " + rowsData[index].provider.length);
    console.log(
      rowsData[index].circle != null
        ? rowsData[index].circle
        : "Circle Value null"
    );
    return (
      <>
        <tr key={index}>
          <td>
            <Select
              options={options}
              autoComplete="off"
              defaultValue={
                rowsData[index].circle != null
                  ? rowsData[index].circle
                  : "Circle Value null"
              }
              onChange={(evnt) => circleHandleChange(index, evnt)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Circle"
              isDisabled={EditCondition}
              // isDisabled="true"
            />
          </td>
          <td>
            <input
              type="text"
              onChange={(evnt) => labourHandleChange(index, evnt)}
              className="form-control bg-light text-dark"
              autoComplete="off"
              disabled={EditCondition}
              placeholder="Select Labour Number"
              value={
                rowsData[index].labour_number != ""
                  ? rowsData[index].labour_number
                  : console.log("labourNumber value null")
              }
            />{" "}
          </td>
          <td>
            <button
              className="btn btn-outline-danger"
              onClick={() => deleteTableRows(index)}
              type="button"
              disabled={EditCondition}
            >
              x
            </button>
          </td>
        </tr>
      </>
    );
  });
};
export default UserViewChildPage;
