import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { circleActions, validationActions } from "../../_services";

const EditCircle = ({ toggler, circleDetails, formUpdatedToggle }) => {
	const id = circleDetails.id;
	const [isEditMode, setIsEditMode] = useState(false);
	const dispathch = useDispatch();
	const [circle, setCircle] = useState([]);
	const entitlementCode = localStorage.getItem("entitilement");
	const orgId = localStorage.getItem("userId");
	const [prefix, setPrefix] = useState(undefined);
	const [circleCode, setCircleCode] = useState(undefined);
	const existingCircleLocationCode = circleDetails.location_code;
	let email = circleDetails.email_id;

	const handleEdit = () => {
		setIsEditMode(true);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const Displayalert = () => {
		toast.success("Circle updated successfully !");
	};

	function checkCircleCode(event) {
		setCircleCode(handleCircleCode(event.target.value));
	}

	function handleCircleCode(value) {
		const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
		return value.match(regex)[0];
	}

	function checkPrefix(event) {
		setPrefix(handlePrefix(event.target.value));
	}

	function handlePrefix(value) {
		const regex = /([A-Za-z]{0,2})/s;
		return value.match(regex)[0];
	}

	useEffect(() => {
		setValue("cName", circleDetails.name);
		setValue("prefix", circleDetails.prefix);
		setValue("circleCode", circleDetails.circle_code);
		setValue("email", circleDetails.email_id);
		setValue("description", circleDetails.description);

		dispathch(circleActions.getAllCircle()).then((data) => {
			setCircle(data.payload);
		});
	}, [circleDetails, setValue]);
	console.log(`circle ------>:${JSON.stringify(circle)}`);

	const circlePrefixCode = circle.map((val) => {
		return `${val.location_code}`;
	});

	console.log(`circlePrefixCode:${circlePrefixCode}`);

	console.log(`circleDetails:${circleDetails.location_code}`);
	const mailVAlidation = async (e) => {
		let mailId = e.target.value.toUpperCase();
		if (mailId != email) {
			await dispathch(validationActions.mailVAlidation(mailId)).then((res) => {
				console.log("res", res);
				if (res.payload === "Already exist") {
					setValue("email", "");
					toast.warn("Email Id already exists", {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			});
		}
	};

	const onSubmit = async (data) => {
		if (data !== "") {
			const reqBody = {
				name: data.cName.toUpperCase(),
				prefix: data.prefix.toUpperCase(),
				circle_code: data.circleCode,
				location_code: (data.prefix + data.circleCode).toUpperCase(),
				description: data.description.toUpperCase(),
				email_id: data.email.toUpperCase(),
				organization_id: orgId,
			};
			console.log(reqBody);
			dispathch(circleActions.updateCircleById({ id, reqBody })).then(
				(data) => {
					console.log("update circle response response -->", data);
					if (data.type === "/UpdateCircleById/fulfilled") {
						formUpdatedToggle();
						Displayalert();
						toggler();
					}
				}
			);
		}
	};
	return (
		<Fragment>
			<Form
				className="needs-validation"
				noValidate=""
				onSubmit={handleSubmit(onSubmit)}
			>
				<Row>
					<Col md="12 mb-3">
						<Label className="form-label" for="circleName">
							Circle Name <span className="text-danger">*</span>
						</Label>
						<input
							className="form-control bg-light text-dark"
							id="circleName"
							autoComplete="off"
							type="text"
							placeholder="Circle Name"
							name="cName"
							{...register("cName", { required: true })}
							disabled={!isEditMode}
						/>
						<span className="d-block text-danger">
							{errors.cName && "Circle name is required"}
						</span>
						<div className="valid-feedback">Looks good!</div>
					</Col>
				</Row>
				<Row>
					<Col md="12 mb-3">
						<Label className="form-label" for="prefix">
							Prefix <span className="text-danger">*</span>
						</Label>
						<input
							className="form-control bg-light text-dark b-secondary"
							id="prefix"
							value={prefix}
							type="text"
							autoComplete="off"
							placeholder="Prefix"
							name="prefix"
							{...register("prefix", { required: true })}
							onChange={(event) => checkPrefix(event)}
							disabled="true"
						/>
						<span className="d-block text-danger">
							{errors.prefix && "Prefix is required"}
						</span>
						<div className="valid-feedback">Looks good!</div>
					</Col>
				</Row>
				<Row>
					<Col md="12 mb-3">
						<Label className="form-label" for="circleCode">
							Circle Code <span className="text-danger">*</span>
						</Label>
						<input
							className="form-control bg-light text-dark b-secondary"
							id="circleCode"
							value={circleCode}
							type="text"
							autoComplete="off"
							placeholder="Circle Code"
							name="circleCode"
							{...register("circleCode", { required: true })}
							onChange={(event) => checkCircleCode(event)}
							disabled="true"
						/>
						<span className="d-block text-danger">
							{errors.circleCode && "Circle code is required"}
						</span>
						<div className="valid-feedback">Looks good!</div>
					</Col>
				</Row>
				<Row>
					<Col md="12 mb-3">
						<Label className="form-label" for="email">
							Email Id <span className="text-danger">*</span>
						</Label>
						<input
							className="form-control bg-light text-dark"
							id="email"
							type="email"
							autoComplete="off"
							placeholder="Email Id"
							name="email"
							{...register("email", { required: true })}
							disabled={!isEditMode}
							onBlur={(e) => mailVAlidation(e)}
						/>
						<span className="d-block text-danger">
							{errors.email && "Email Id is required"}
						</span>
						<div className="valid-feedback">Looks good!</div>
					</Col>
				</Row>
				<Row>
					<Col md="12 mb-3">
						<Label className="form-label" for="description">
							Description
						</Label>
						<textarea
							className="form-control bg-light text-dark"
							id="description"
							autoComplete="off"
							type="textarea"
							placeholder="Description"
							name="description"
							rows={3}
							{...register("description")}
							disabled={!isEditMode}
						/>
						<div className="valid-feedback">Looks good!</div>
					</Col>
				</Row>

				{isEditMode &&
				(entitlementCode === "AG001" || entitlementCode === "ORG001") ? (
					<>
						<Button
							color="secondary"
							className="me-3"
							onClick={() => setIsEditMode(false)}
						>
							Cancel
						</Button>
						<Button color="primary">{"Update"}</Button>
					</>
				) : entitlementCode === "AG001" || entitlementCode === "ORG001" ? (
					<Button color="primary" onClick={handleEdit}>
						Edit
					</Button>
				) : (
					""
				)}
			</Form>
			{/* <ToastContainer /> */}
		</Fragment>
	);
};

export default EditCircle;
