import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPIWapper } from "../_helper/Fetchwrapper";

const name = "auth";

function createInitialState() {
  return {
    token: window.sessionStorage.getItem("Token"),
    error: null,
    checkPopup: false,
    loader: false,
  };
}
console.log();
function createReducers() {
  function logout(state) {
    state.token = null;
    state.loader = false;
    state.unAuthMessage = "";
    state.checkPopup = false;
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }
  function toggleAuthPopup(state, { payload }) {
    state.checkPopup = payload;
  }
  function updateAuthMessage(state, { payload }) {
    state.unAuthMessage = payload;
  }
  return {
    logout,
    toggleAuthPopup,
    updateAuthMessage,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  function login() {
    return createAsyncThunk(
      "/login",
      async ({ username, password }) =>
        await fetchAPIWapper.post(`${baseUrl}/login`, {
          username,
          password,
        })
    );
  }
  // function register() {
  //   return createAsyncThunk(
  //     "/login",
  //     async (registerData) =>
  //       await fetchAPIWapper.post(`${baseUrl}/register`, registerData)
  //   );
  // }
  function refreshTokenForSwitchUser() {
    return createAsyncThunk(
      "/login/refreshToken",
      async (info) =>
        await fetchAPIWapper.post(`${baseUrl}/login/refreshToken`, {
          info,
        })
    );
  }
  function fetchSecondaryRoles() {
    return createAsyncThunk(
      "/roles/secondaryRoles",
      async ({ searchKey, searchValues }) =>
        await fetchAPIWapper.get(
          `${baseUrl}/roles/getSecondaryRoles?${searchKey}=${searchValues}`
        )
    );
  }
  return {
    login: login(),
    refreshTokenForSwitchUser: refreshTokenForSwitchUser(),
    fetchSecondaryRoles: fetchSecondaryRoles(),
    //   register: register(),
  };
}

function createExtraReducers() {
  function login() {
    let { pending, fulfilled, rejected } = extraActions.login;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
        state.checkPopup = false;
      },
      [fulfilled]: (state, action) => {
        console.log("action.payload ---------->", action.payload);
        if (action.payload.message != "Invalid username or password") {
          if (action.payload.userDetail.secondary_role) {
            let secondaryRoles = action.payload.userDetail.secondary_role.map(
              (item) => item.roleId
            );
            localStorage.setItem(
              "secondaryRoleIds",
              JSON.stringify(secondaryRoles)
            );
          }
          if (action.payload.token) {
            localStorage.setItem("data", JSON.stringify(action.payload));
            localStorage.setItem("Token", action.payload.token);
            localStorage.setItem("userId", action.payload.userDetail.entity_id);
            localStorage.setItem(
              "userName",
              action.payload.userDetail.user_name
            );
            localStorage.setItem(
              "userLoginId",
              action.payload.userDetail.user_login_id
            );

            localStorage.setItem(
              "entitilement",
              action.payload.userDetail.entitlement_code
            );
            localStorage.setItem("login", JSON.stringify(true));
          }
        }

        state.token = action.payload.token;
        state.loader = false;
        state.checkPopup = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
        state.checkPopup = false;
      },
    };
  }
  function refreshTokenForSwitchUser() {
    let { pending, fulfilled, rejected } =
      extraActions.refreshTokenForSwitchUser;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
        state.checkPopup = false;
      },
      [fulfilled]: (state, action) => {
        console.log("action.payload ---------->", action.payload);
        if (action.payload.userDetail.secondary_role) {
          let secondaryRoles = action.payload.userDetail.secondary_role.map(
            (item) => item.roleId
          );
          localStorage.setItem(
            "secondaryRoleIds",
            JSON.stringify(secondaryRoles)
          );
        }
        if (action.payload.token) {
          localStorage.setItem("data", JSON.stringify(action.payload));
          localStorage.setItem("Token", action.payload.token);
          localStorage.setItem("userId", action.payload.userDetail.entity_id);
          localStorage.setItem(
            "userLoginId",
            action.payload.userDetail.user_login_id
          );

          localStorage.setItem(
            "entitilement",
            action.payload.userDetail.entitlement_code
          );
          localStorage.setItem("login", JSON.stringify(true));
        }

        state.token = action.payload.token;
        state.loader = false;
        state.checkPopup = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
        state.checkPopup = false;
      },
    };
  }
  function fetchSecondaryRoles() {
    let { pending, fulfilled, rejected } = extraActions.fetchSecondaryRoles;
    return {
      [pending]: (state) => {
        state.error = null;
        state.loader = true;
        state.checkPopup = false;
      },
      [fulfilled]: (state, action) => {
        console.log("action.payload ---------->", action.payload);
        state.token = action.payload.token;
        state.loader = false;
        state.checkPopup = false;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
        state.loader = false;
        state.checkPopup = false;
      },
    };
  }
  return {
    ...login(),
    ...refreshTokenForSwitchUser(),
    ...fetchSecondaryRoles(),
  };
}

const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;
