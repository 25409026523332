import React, { Fragment, useState } from "react";
import { Col, Card, CardBody, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CircleAndLabourValue from "./CircleAndLabourValue";
import { useDispatch } from "react-redux";
import LoaderComponent from "../common/Spinner/LoaderComponent";
import { contractorActions, validationActions } from "../../_services";

const CreateContractor = ({ toggle, contractorCreationToggle }) => {
  const orgId = localStorage.getItem("userId");
  const [loadingElement, setLoadingElement] = useState(false);
  console.log(orgId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const password = watch("password");

  const [rowsData, setRowsData] = useState([]);
  const dispatch = useDispatch();

  const Displayalert = () => {
    toast.success("Contractor Created Successfully !");
    /* Toast.fire({
      text: "Contractor created successfully!",
      icon: "success",
    }); */
  };
  const circleHandleChange = (index, event) => {
    console.log("event");
    console.log(event);
    // const { label, value } = evnt;
    const rowsInput = [...rowsData];
    rowsInput[index].circle = event.value;
    console.log(rowsInput);
    setRowsData(rowsInput);
  };
  const labourHandleChange = (index, evnt) => {
    console.log("evnt");
    console.log(evnt.target.value);
    // const { label, value } = evnt;
    const rowsInput = [...rowsData];
    rowsInput[index].labour_number = evnt.target.value.toUpperCase();
    console.log(rowsInput);
    setRowsData(rowsInput);
  };
  const addTableRows = () => {
    const rowsInput = {
      circle: 0,
      labour_number: "",
    };
    setRowsData([...rowsData, rowsInput]);
    console.log(rowsData.map((data) => data));
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };
  const mobileNumberValidation = async (e) => {
    const validation = e.target.value;
    await dispatch(contractorActions.contactDetailsValidation(validation)).then(
      (response) => {
        console.log(response);
        if (response.payload === "Already exist") {
          console.log("no value");
          setValue("mbNo", "");
          toast.warn("Mobile Number already exist");
          /* Toast.fire({
          text: "Mobile number already exists",
          icon: "error",
        }); */
        } else {
          console.log("something went wrong");
        }
      }
    );
  };
  const mailVAlidation = async (e) => {
    let mailId = e.target.value;
    await dispatch(validationActions.mailVAlidation(mailId)).then((res) => {
      if (res.payload === "Already exist") {
        setValue("email", "");
        toast.warn("Email Id already exists", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const userNameValidation = async (e) => {
    let username = e.target.value;
    await dispatch(validationActions.usernameValidation(username)).then(
      (res) => {
        if (res.payload === "Already exist") {
          setValue("username", "");
          toast.warn("username already exists", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const onSubmit = async (data) => {
    if (rowsData.length === 0) {
      // alert("Please select Circle and Fill Labor License Number");
      toast.error("Please select Circle and enter  Labor License Number");
      /* Toast.fire({
        text: "Please select circle and enter Labour License Number",
        icon: "error",
      }); */
      return;
    } else {
      for (let i = 0; i < rowsData.length; i++) {
        if (rowsData[i].circle !== 0 && rowsData[i].labour_number !== "") {
        } else {
          // alert("Please select Circle and Fill Labor License Number ");
          toast.error("Please select Circle and enter  Labor License Number");
          /* Toast.fire({
            text: "Please select circle and enter Labour License Number",
            icon: "error",
          }); */
          return;
        }
      }
    }
    if (data !== "") {
      setLoadingElement(true);
      const reqBody = {
        establishment_name: data.eName.toUpperCase(),
        proprietor_name: data.pName.toUpperCase(),
        gst_number: data.gstNo.toUpperCase(),
        esi_number: data.esiNo.toUpperCase(),
        epf_number: data.epfNo.toUpperCase(),
        contact: [
          {
            contactType: "email",
            contactDetails: data.email.toUpperCase(),
          },
          { contactType: "mobile", contactDetails: data.mbNo },
        ],
        circle: rowsData,
        user_name: data.username,
        password: data.password,
        role_id: 4,
        organization_id: orgId,
        is_primary: true,
      };
      console.log("reqBody", reqBody);
      dispatch(contractorActions.createContractor(reqBody)).then((data) => {
        console.log("contractor creation data from redux", data);
        if (data.type === "/createContractor/fulfilled") {
          setLoadingElement(false);
          contractorCreationToggle();
          Displayalert();
          toggle();
        } else {
          // alert("something wrong");
          toast.error("something wrong");
          /*           Toast.fire({
            text: "Something went wrong.!",
            icon: "error",
          }); */
        }
      });
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form
            className="needs-validation"
            autoComplete="off"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom01">
                  Establishment Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom01"
                  autoComplete="off"
                  type="text"
                  placeholder="Establishment name"
                  name="eName"
                  {...register("eName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.eName && "Est.name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom02">
                  Proprietor Name<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom02"
                  autoComplete="off"
                  type="text"
                  placeholder="Proprietor Name"
                  name="pName"
                  {...register("pName", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.pName && "Proprietor name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom03">
                  GST Number<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom03"
                  autoComplete="off"
                  type="text"
                  placeholder="GST Number"
                  name="gstNo"
                  {...register("gstNo", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.gstNo && "GST number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>

              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom04">
                  ESI Number<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom04"
                  autoComplete="off"
                  type="text"
                  placeholder="ESI Number"
                  name="esiNo"
                  {...register("esiNo", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.esiNo && "ESI number is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom05">
                  EPF Number<span className="text-danger">*</span>
                </Label>
                <input
                  className="form-control bg-light text-dark"
                  id="validationCustom05"
                  autoComplete="off"
                  name="epfNo"
                  type="text"
                  placeholder="EPF Number"
                  {...register("epfNo", { required: true })}
                />
                <span className="d-block text-danger">
                  {errors.epfNo && "EPF number is required."}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom07">
                  Mobile Number<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom07"
                  name="mbNo"
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Mobile Number"
                  maxlength="10"
                  {...register("mbNo", { required: true })}
                  onBlur={(e) => mobileNumberValidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.mbNo && "Mobile number is required."}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  Email ID<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="email"
                  placeholder="Email ID"
                  name="email"
                  {...register("email", { required: true })}
                  onBlur={(e) => mailVAlidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.email && "Email is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  User Name<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="text"
                  placeholder="User Name"
                  name="username"
                  {...register("username", { required: true })}
                  onBlur={(e) => userNameValidation(e)}
                />
                <span className="d-block text-danger">
                  {errors.username && "User Name is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  Password<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="password"
                  placeholder="password"
                  name="password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  })}
                />
                <span className="d-block text-danger">
                  {errors.password && errors.password.message}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="validationCustom08">
                  Confirm Password<span className="text-danger">*</span>
                </Label>
                <input
                  autoComplete="off"
                  className="form-control bg-light text-dark"
                  id="validationCustom08"
                  type="password"
                  placeholder="Confirm Password"
                  name="conpassword"
                  {...register("conpassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  })}
                />
                <span className="d-block text-danger">
                  {errors.conpassword && "Confirm password is required"}
                </span>
                <div className="valid-feedback">Looks good!</div>
              </Col>
            </Row>
            <div className="col-sm-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>Circle</th>
                    <th>Labour License Number</th>
                    <th>
                      <button
                        className="btn btn-outline-success"
                        onClick={addTableRows}
                        type="button"
                      >
                        +
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <CircleAndLabourValue
                    circleHandleChange={circleHandleChange}
                    labourHandleChange={labourHandleChange}
                    deleteTableRows={deleteTableRows}
                    rowsData={rowsData}
                  />
                </tbody>
              </table>
            </div>
            {loadingElement === true ? (
              <LoaderComponent />
            ) : (
              <Button type="submit" color="primary">
                {"Submit"}
              </Button>
            )}
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default CreateContractor;
