// dashbaord

import Default from "../Components/Dashboard/Default";

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

//Iaudit APi
import Contractor from "../Pages/Contractor";
import Circle from "../Pages/Circle";
import Cases from "../Pages/Case";
import CaseCreationFormModal from "../Pages/Case/CaseCreationForm";
import CaseDetailsForm from "../Pages/Case/caseDetailsForm";
import User from "../Pages/user";
import EditCase from "../Pages/Case/EditCase";
import EditCaseDetails from "../Pages/Case/EditCaseDetails";
import SubmittedCases from "../Pages/submitted case";
import ApprovedCases from "../Pages/approved case";
import RejectedCases from "../Pages/rejectedCases";
import InvoiceReport from "../Pages/InvoiceReport";
import CircleUser from "../Pages/circleUser";
import CaseFlowComponent from "../Pages/caseFlow";
import DatewiseReport from "../Pages/DatewiseReport";
import MakePaymentCasePage from "../Pages/makePayment case page";
import MasterCase from "../Pages/masterContractor";
import MasterEditCase from "../Pages/masterContractor/EditCase";
import MasterEditCaseDetails from "../Pages/masterContractor/EditCaseDetails";
import MasterCaseDetailsForm from "../Pages/masterContractor/caseDetailsForm";

// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'

export const routes = [
  //dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Default />,
  },

  // //page
  {
    path: `${process.env.PUBLIC_URL}/pages/sample-page`,
    Component: <SamplePage />,
  },

  //Iaudit routes and path
  {
    path: `${process.env.PUBLIC_URL}/circle/creation`,
    Component: <Circle />,
  },

  {
    path: `${process.env.PUBLIC_URL}/contractor/viewAll`,
    Component: <Contractor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/viewAll`,
    Component: <Cases />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/creation`,
    Component: <CaseCreationFormModal />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/details`,
    Component: <CaseDetailsForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/EditCase/:caseId`,
    Component: <EditCase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/EditCaseDetails/:caseId`,
    Component: <EditCaseDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/internalUsers/viewAll`,
    Component: <User />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/submittedCase`,
    Component: <SubmittedCases />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/approvedCases`,
    Component: <ApprovedCases />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/rejectedCases`,
    Component: <RejectedCases />,
  },
  {
    path: `${process.env.PUBLIC_URL}/case/invoiceReports`,
    Component: <InvoiceReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/User/viewAll`,
    Component: <CircleUser />,
  },
  {
    path: `${process.env.PUBLIC_URL}/caseFlow`,
    Component: <CaseFlowComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/datewiseReports`,
    Component: <DatewiseReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/makePayments`,
    Component: <MakePaymentCasePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masterCase/viewAll`,
    Component: <MasterCase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masterEditCase/:caseId`,
    Component: <MasterEditCase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masterEditCaseDetails/:caseId`,
    Component: <MasterEditCaseDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/masterCase/details`,
    Component: <MasterCaseDetailsForm />,
  },

  // accounts page
  {
    path: `${process.env.PUBLIC_URL}/invoice/verification`,
    Component: <SubmittedCases />,
  },
  {
    path: `${process.env.PUBLIC_URL}/invoice/generation`,
    Component: <ApprovedCases />,
  },
  {
    path: `${process.env.PUBLIC_URL}/invoice/downloads`,
    Component: <RejectedCases />,
  },
];
