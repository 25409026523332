import React, { Fragment, useState } from "react";
import { ButtonGroup, Label, Input, Button, Form, Col } from "reactstrap";
import { ToolTip } from "../../AbstractElements";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { caseActions } from "../../_services";
import { toast } from "react-toastify";

const RadioBtnClass = (props) => {
  const [basictooltip, setBasicTooltip] = useState(false);
  const toggle1 = () => setBasicTooltip(!basictooltip);
  const [basictooltip2, setBasicTooltip2] = useState(false);
  const toggle2 = () => setBasicTooltip2(!basictooltip2);
  const [basictooltip3, setBasicTooltip3] = useState(false);
  const toggle3 = () => setBasicTooltip3(!basictooltip3);
  const { caseId, toggle } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  let certificateName = null;
  const onSubmit = (data) => {
    console.log("certificate type ---->", data.radio1);
    if (data.radio1) {
      const reqBody = {
        accountsStatus: "SUCCESS",
        svcProviderStatus: "AUDIT CLEARED",
        auditorStatus: "SUCCESS",
        auditAdminstatus: "APPROVED",
      };
      dispatch(caseActions.caseStatusUpdate({ caseId, reqBody })).then(
        (data) => {
          console.log("response data...", data);
          // DisplayApprovalAlert("approval");
          navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
        }
      );
      const req = {
        caseId: caseId,
        pdfType: data.radio1,
      };
      dispatch(caseActions.generateCertificate(req)).then((data) => {
        console.log("response from certificate....", data);
      });
      if (data.radio1 === "type1Certificate") {
        certificateName = "Type 1";
      } else if (data.radio1 === "type2Certificate") {
        certificateName = "Type 2";
      } else {
        certificateName = "Type 3";
      }
      toast.success(`${certificateName} Certificate issued`);
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    } else {
      toast.error("something wemt wrong");
      navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
    }
  };

  const handleCancel = () => {
    toggle();
    navigate(`${process.env.PUBLIC_URL}/case/submittedCase`);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fragment>
        <div className="d-flex flex-row justify-content-between mb-3">
          {/* <ButtonGroup> */}
          {/* <Button color="primary" id="type1"> */}
          <div>
            <input
              type="radio"
              name="radio1"
              value="type1Certificate"
              className="me-3"
              id="type_1"
              {...register("radio1", { required: true })}
            />
            <Label for="type_1" id="type1" size="lg">
              Type 1
            </Label>
          </div>
          {/* </Button> */}
          <ToolTip
            attrToolTip={{
              placement: "top",
              isOpen: basictooltip,
              target: "type1",
              toggle: toggle1,
            }}
          >
            GENERAL
          </ToolTip>
          {/* </ButtonGroup> */}
          {/* <ButtonGroup>
            <Button color="primary" id="type2"> */}
          <div>
            <input
              type="radio"
              name="radio1"
              value="type2Certificate"
              className="me-3"
              id="type_2"
              {...register("radio1", { required: true })}
            />
            <Label for="type_2" id="type2" size="lg">
              Type 2
            </Label>
          </div>
          {/* </Button> */}
          <ToolTip
            attrToolTip={{
              placement: "top",
              isOpen: basictooltip2,
              target: "type2",
              toggle: toggle2,
            }}
          >
            ESI NON-APPLICABLE
          </ToolTip>
          {/* </ButtonGroup> */}
          {/* <ButtonGroup> */}
          {/* <Button color="primary" id="type3"> */}
          <div className="d-flex justify-content-around">
            <div className="d-flex justify-content-start align-items-center">
              <input
                type="radio"
                name="radio1"
                value="type3Certificate"
                className="me-3"
                id="type_3"
                {...register("radio1", { required: true })}
              />
              <Label htmlFor="type_3" id="type3" size="lg">
                Type 3
              </Label>
            </div>
          </div>
          {/* </Button> */}
          <ToolTip
            attrToolTip={{
              placement: "top",
              isOpen: basictooltip3,
              target: "type3",
              toggle: toggle3,
            }}
          >
            PARTIAL WAGE
          </ToolTip>
          {/* </ButtonGroup> */}
        </div>
        <Col className="d-flex flex-row justify-content-center">
          <Button color="primary" size="sm" type="submit" className="me-3">
            OK
          </Button>

          <Button color="secondary" size="sm" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Col>
      </Fragment>
    </Form>
  );
};

export default RadioBtnClass;
