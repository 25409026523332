import React from "react";

const LoaderComponent = () => {
	return (
		<div
			className="spinner-border text-primary d-flex justify-content-center"
			role="status"
		>
			<span className="sr-only">Loading...</span>
		</div>
	);
};

export default LoaderComponent;
