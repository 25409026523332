import React, { Fragment, useEffect, useState } from "react";
import ModalForm from "./modals/modal";
import { useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { userActions } from "../../_services";
import { Spinner } from "../../AbstractElements";

const UserComponent = (props) => {
  const { userCreation } = props;
  const [user, setUser] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("view");
  const [modalTitle, setModalTitle] = useState("Contractor details");
  const [userUpdated, setUserupdated] = useState(false);
  const toggle = () => setModal(!modal);
  const userUpdatedToggle = () => setUserupdated(!userUpdated);
  const dispatch = useDispatch();
  const [loadingElement, setLoadingElement] = useState(false);
  let loadingContent;
  const loaderFunc = () => {
    // setTimeout(loaderFunc, 1000);
    setLoadingElement(false);
  };
  if (loadingElement) {
    loadingContent = (
      <div className="loader-box">
        <Spinner attrSpinner={{ className: "loader-2" }} />
      </div>
    );
  }

  useEffect(() => {
    setLoadingElement(true);
    dispatch(userActions.getAllUser()).then((data) => {
      console.log("User data from redux viewuser ---->", data);
      const circleUser = data.payload.filter((eachCase) => {
        if (eachCase.role === "CIRCLE ADMIN") {
          console.log("i'm in if condition");
          return true;
        }
        return false;
      });
      setUser(circleUser);
      if (data) {
        setTimeout(loaderFunc, 1000);
      }
    });
  }, [userCreation, userUpdated]);
  console.log("user -->", user);

  const handleEdit = async (id) => {
    setType("edit");
    setModalTitle("Edit User Detail");
    await dispatch(userActions.getUserById(id)).then((data) => {
      if (data.payload) {
        console.log("user details   redux....", data);
        setUserDetail(data.payload);
      }
    });

    toggle();
  };
  console.log("setuserdetails value", userDetail);
  const userFirstnameFormatter = (cell, row, rowIndex) => {
    return (
      <a
        style={{ textDecoration: "underline", color: "rgb(13,110,253)" }}
        onClick={() => handleEdit(row.id)}
      >
        {row.first_name}
      </a>
    );
  };
  const columns = [
    {
      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "15px",
      },
    },
    {
      dataField: "first_name",
      text: "First Name",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px",
      },
      formatter: userFirstnameFormatter,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px",
      },
    },
    {
      dataField: "role",
      text: "Role",
      filter: textFilter({
        style: { padding: "2px 10px ", border: "none", borderRadius: "3px" },
      }),
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#7366ff",
        color: "white",
        padding: "5px",
      },
    },
    // {
    // 	dataField: "id",
    // 	text: "Action",
    // 	headerStyle: {
    // 		backgroundColor: "#7366ff",
    // 		color: "white",
    // 		padding: "15px",
    // 	},
    // 	formatter: eyeIconFormatter, // Use the custom formatter for this column
    // },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const rowEvents = {
    // onClick: (e, row, rowIndex) => {
    // 	handleEdit(e, row.id);
    // },
    onMouseEnter: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "pointer";
    },
    onMouseLeave: (e, row, rowIndex) => {
      e.currentTarget.style.cursor = "default";
    },
  };

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "40",
        value: 40,
      },
      {
        text: "All",
        value: user.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <Fragment>
      {loadingElement ? (
        loadingContent
      ) : (
        <BootstrapTable
          keyField="id"
          data={user}
          columns={columns}
          headerClasses="header-class"
          filter={filterFactory()}
          hover
          // selectRow={selectRow}
          pagination={paginationFactory(options)}
          rowEvents={rowEvents}
        />
      )}
      {modal ? (
        <ModalForm
          modal={modal}
          type={type}
          title={modalTitle}
          value={userDetail}
          toggle={toggle}
          userUpdatedToggle={userUpdatedToggle}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default UserComponent;
