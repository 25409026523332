import React, { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalForm from "./modals/modal";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Col, Card, CardBody, Form, Row } from "reactstrap";
import SearchBar from "../common/SearchBar";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
	textFilter,
	dateFilter,
	Comparator,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { caseActions, circleActions } from "../../_services";
import { Spinner } from "../../AbstractElements";

/* const Toast = SweetAlert.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", SweetAlert.stopTimer);
    toast.addEventListener("mouseleave", SweetAlert.resumeTimer);
  },
}); */

const UserComponent = (props) => {
	const serviceProviderId = parseInt(localStorage.getItem("userId"));
	const navigate = useNavigate();
	const [Case, setCase] = useState([]);
	const [caseDetail, setcaseDetail] = useState([]);
	const [caseCreationDetail, setCaseCreationDetail] = useState({});
	const [caseRemarks, setCaseRemarks] = useState([]);
	const [modal, setModal] = useState(false);
	const [type, setType] = useState("view");
	const [isViewClicked, setIsViewClicked] = useState(false);
	const modalTitle = "Case Details";
	const { searchText } = props;
	const toggle = () => setModal(!modal);
	const dispatch = useDispatch();
	const [clearFilter, setClearFilter] = useState(false);
	const [loadingElement, setLoadingElement] = useState(false);
	let loadingContent;
	const loaderFunc = () => {
		// setTimeout(loaderFunc, 1000);
		setLoadingElement(false);
	};
	if (loadingElement) {
		loadingContent = (
			<div className="loader-box">
				<Spinner attrSpinner={{ className: "loader-2" }} />
			</div>
		);
	}

	useEffect(() => {
		const casesWithCircleName = [];
		const getdata = async () => {
			setLoadingElement(true);
			await dispatch(caseActions.getAllCase()).then(async (data) => {
				if (data.payload) {
					console.log("casefrom redux ---->", data);
					const svcProviderCases = data.payload.filter((eachCase) => {
						if (eachCase.svc_provider_id === serviceProviderId) {
							console.log("i'm in if condition");
							return true;
						}
						return false;
					});
					await dispatch(circleActions.getAllCircle()).then((data) => {
						const circleVal = data.payload;
						console.log("circles data from redux....", circleVal);
						for (let eachCase of svcProviderCases) {
							const orgLocationId = eachCase.org_location_id;
							for (let eachCircle of circleVal) {
								if (orgLocationId === eachCircle.id) {
									const newCase = {
										...eachCase,
										name: eachCircle.name,
									};
									casesWithCircleName.push(newCase);
									console.log("circle Names....", casesWithCircleName);
								}
							}
						}
						for (let i = 0; i < casesWithCircleName.length; i++) {
							if (casesWithCircleName[i].status === "Draft Return") {
								casesWithCircleName[i].status = "Returned with Remarks";
							}
						}
						setCase(casesWithCircleName);
						console.log("Full case data: ");
						console.log(casesWithCircleName.length);
						console.log(casesWithCircleName);
						if (data) {
							setTimeout(loaderFunc, 1000);
						}
					});
				}
			});
		};
		getdata();
	}, [clearFilter]);
	console.log("case -->", Case);

	const clearSearchFilter = () => {
		setClearFilter(!clearFilter);
	};

	const DisplaySuccessToast = () => {
		/* Toast.fire({
      text: "Case submitted successfully!",
      icon: "success",
    }); */
		toast.success("Case submitted successfully!");
	};

	const DisplayFailureAlert = () => {
		SweetAlert.fire({
			title: "Looking Not Good!",
			text: "Case is in Draft condition",
			icon: "warning",
		});
	};

	const handleViewClick = (caseId) => {
		// e.preventDefault();
		console.log("caseId in view click...", caseId);
		setType("view");
		dispatch(caseActions.getAllCaseDetails(caseId)).then((data) => {
			if (data.payload) {
				console.log("user details response redux....", data);
				setcaseDetail(data.payload);
			}
			dispatch(caseActions.getCaseById(caseId)).then((data) => {
				console.log("case creation response from redux....", data);
				setCaseCreationDetail(data.payload);
			});
			dispatch(caseActions.getCaseRemarks(caseId)).then((data) => {
				console.log("case remarks redux", data);
				if (data.payload) {
					setCaseRemarks(data.payload);
				} else {
					console.log("error from remarks");
				}
			});
		});
		toggle();
		setIsViewClicked(true);
	};

	const ShowFilteredCases = (e) => {
		e.preventDefault();
		const startDate = document.getElementById("startDate").value;
		const endDate = document.getElementById("endDate").value;

		if (startDate == "" && endDate == "") {
			toast.error("please select the value");
			/* Toast.fire({
        text: "Please select the value",
        icon: "error",
      }); */
		}
		const reqBody = {
			startDate: startDate,
			endDate: endDate,
		};

		dispatch(caseActions.filteredCase(reqBody)).then((data) => {
			if (data.payload) {
				console.log("filtered data from redux", data);
				const svcProviderCases = data.payload.filter((eachCase) => {
					if (eachCase.svc_provider_id === serviceProviderId) {
						console.log("i'm in if condition");
						return true;
					}
					return false;
				});
				const filterCaseWithCircleName = [];
				dispatch(circleActions.getAllCircle()).then((data) => {
					const circleVal = data.payload;
					console.log("circles data....", circleVal);
					for (let eachCase of svcProviderCases) {
						const orgLocationId = eachCase.org_location_id;
						for (let eachCircle of circleVal) {
							if (orgLocationId === eachCircle.id) {
								const newCase = {
									...eachCase,
									name: eachCircle.name,
								};
								filterCaseWithCircleName.push(newCase);
								console.log("circle Names....", filterCaseWithCircleName);
							}
						}
					}
					setCase(filterCaseWithCircleName);
				});
			}
		});
	};
	// const auditorCaseReturned = [
	//   {
	//     when: (row) => row.status === "Returned with Remarks",
	//     style: {
	//       backgroundColor: "#fcc03887",
	//     },
	//   },
	// ];
	const handleSaveCase = (e, caseId, status) => {
		localStorage.setItem("caseId", caseId);
		navigate(`${process.env.PUBLIC_URL}/EditCase/${caseId}`);
	};

	const handleDocumentDownload = async (caseId, caseid, docType) => {
		console.log("Row", caseId);
		console.log("caseId", caseid);
		const documentType = docType;
		try {
			await dispatch(
				caseActions.getDocumentPath({ caseId, documentType })
			).then(async (data) => {
				console.log("response. redux....", data);
				const { document_path, document_name } = data.payload;
				console.log("document_path", document_path);
				const filePath = document_path;
				const documentName = document_name;

				const fetchResponse = await fetch(filePath);
				const blob = await fetchResponse.blob();

				// Create a temporary link and trigger download
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);

				// Set the correct Content-Type header for PDF
				link.type = "application/pdf";

				if (docType === "invoice") {
					link.download = `${caseid}_Invoice.pdf`;
				} else {
					link.download = `${caseid}_Certificate.pdf`;
				}
				link.click();
			});
		} catch (error) {
			console.log("Error:", error);
		}
	};
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with leading zeros if necessary
		const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month (months are zero-based) and pad with leading zeros if necessary
		const year = date.getFullYear().toString(); // Get the full year

		return `${day}/${month}/${year}`; // Format the date as "dd/mm/yyyy"
	};

	const getRowStyles = (row, rowIndex) => {
		// Define the condition to apply custom styles to specific
		if (row.case_status.svc_provider_status === "AUDIT RETURN") {
			return { backgroundColor: "#fcc03887" }; // Return the custom style object for matching rows
		}
		// Return null to apply no custom style to other rows
		return null;
	};

	// const filterColumnFormatter = (column, colIndex, { filterElement }) => {
	// 	return (
	// 		<div style={{ display: "flex", flexDirection: "column-reverse" }}>
	// 			{filterElement}
	// 			{column.text}
	// 		</div>
	// 	);
	// };
	const caseIdFormatter = (cell, row, rowIndex) => {
		return (
			<a
				style={{ textDecoration: "underline", color: "rgb(13, 110, 253)" }}
				onClick={() => handleViewClick(row.id)}
			>
				{row.case_id}
			</a>
		);
	};

	const a = { backgroundColor: "#fcc03887" };

	// const svcProviderStatus = Case[0].case_status.svc_provider_status;
	// console.log("Case.case_status", Case[0].case_status.svc_provider_status);
	// console.log(svcProviderStatus);
	const columns = [
		{
			text: "S.No",
			formatter: (cell, row, rowIndex, formatExtraData) => {
				return rowIndex + 1;
			},
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "15px 7px",
			},
		},
		{
			dataField: "case_id",
			text: "Case Id",
			filter: textFilter({
				style: { padding: "3px 10px ", border: "none", borderRadius: "3px" },
				placeholder: "Case Id....",
			}),
			headerAlign: "center",
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "5px 7px",
			},
			formatter: caseIdFormatter,
		},
		{
			dataField: "createdAt",
			text: "Created Date",
			filter: dateFilter({
				className: "d-flex flex-row justify-content-around",
				withoutEmptyComparatorOption: true,
				comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
				comparatorStyle: {
					padding: "3px 10px",
					border: "none",
					borderRadius: "3px",
				},
				dateStyle: {
					padding: "3px 10px",
					border: "none",
					borderRadius: "3px",
				},
			}),
			formatter: (cell) => formatDate(cell),
			headerAlign: "center",
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "5px 7px",
				width: "15vw",
			},
		},
		{
			dataField: "name",
			text: "Circle",
			filter: textFilter({
				style: { padding: "3px 10px ", borderRadius: "3px", border: "none" },
				placeholder: "Circle...",
			}),
			headerAlign: "center",
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "5px 7px",
			},
		},
		// {
		// 	dataField: "id",
		// 	text: "View Case",
		// 	headerAlign: "center",
		// 	headerStyle: {
		// 		backgroundColor: "#7366ff",
		// 		color: "white",
		// 		padding: "14px",
		// 	},
		// 	formatter: eyeIconFormatter, // Use the custom formatter for this column
		// },
		{
			dataField: "case_status.svc_provider_status",
			text: "Status",
			filter: textFilter({
				style: { padding: "3px 10px ", borderRadius: "3px", border: "none" },
				placeholder: "Status...",
			}),
			formatter: (cell, row) => {
				if (row.case_status.svc_provider_status === "DRAFT") {
					return (
						<span className="badge bg-dark tag-pills-sm-mb">
							{row.case_status.svc_provider_status}
						</span>
					);
				} else if (row.case_status.svc_provider_status === "SUBMITTED") {
					return (
						<span className="badge bg-secondary">
							{row.case_status.svc_provider_status}
						</span>
					);
				} else if (row.case_status.svc_provider_status === "UNDER REVIEW") {
					return (
						<span className="badge bg-light txt-dark">
							{row.case_status.svc_provider_status}
						</span>
					);
				} else if (row.case_status.svc_provider_status === "AUDIT CLEARED") {
					return (
						<span className="badge bg-success">
							{row.case_status.svc_provider_status}
						</span>
					);
				} else if (row.case_status.svc_provider_status === "AUDIT RETURN") {
					return (
						<span className="badge bg-warning">
							{row.case_status.svc_provider_status}
						</span>
					);
				} else {
					return (
						<span className="badge bg-danger">
							{row.case_status.svc_provider_status}
						</span>
					);
				}
			},
			//   formatter: () => svcProviderStatus,
			style: {
				color: "#e35430",
			},
			headerAlign: "center",
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "5px 7px",
			},
		},
		{
			text: "Actions",
			formatter: (cell, row) => {
				const status = row.case_status.svc_provider_status; // Destructure the "status" property from the row data
				console.log("status", status);
				if (
					status === "DRAFT" ||
					status === "AUDIT RETURN" ||
					status === "PAYMENT FAILED"
				) {
					return (
						<Button
							variant="transparent"
							size="lg"
							onClick={(e) =>
								handleSaveCase(e, row.id, row.case_status.svc_provider_status)
							}
						>
							<i className="icofont icofont-ui-edit text-secondary"></i>
						</Button>
					);
				} else if (status === "Submitted") {
					return (
						<Button
							variant="secondary"
							size="sm"
							style={{
								paddingLeft: "13px",
								paddingRight: "13px",
								opacity: "0.4",
							}}
							disabled
						>
							Submitted
						</Button>
					);
				} else if (status === "AUDIT CLEARED") {
					return (
						<Button
							size="lg"
							variant="transparent"
							onClick={() =>
								handleDocumentDownload(row.id, row.case_id, "certificate")
							}
						>
							<i className="fa fa-download text-primary" />
						</Button>
					);
				}

				return null; // Return null for other cases or handle default case if needed
			},
			headerAlign: "center",
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "15px 7px",
			},
		},
		{
			text: "Invoice Download",
			formatter: (cell, row) => {
				const status = row.case_status.Accounts_Status; // Destructure the "status" property from the row data
				console.log("status", status);
				if (status === "INVOICE GENERATED") {
					return (
						<Button
							size="lg"
							variant="transparent"
							onClick={() =>
								handleDocumentDownload(
									row.id,
									row.case_certificate_invoice_map_model.invoice_number,
									"invoice"
								)
							}
						>
							<i className="fa fa-download text-primary" />
						</Button>
					);
				}
			},
			headerAlign: "center",
			headerStyle: {
				backgroundColor: "#7366ff",
				color: "white",
				padding: "15px 7px",
			},
		},
	];

	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} Results
		</span>
	);

	const rowEvents = {
		// onClick: (e, row, rowIndex) => {
		// 	handleViewClick(e, row.id);
		// },
		onMouseEnter: (e, row, rowIndex) => {
			e.currentTarget.style.cursor = "pointer";
		},
		onMouseLeave: (e, row, rowIndex) => {
			e.currentTarget.style.cursor = "default";
		},
	};

	const options = {
		paginationSize: 4,
		pageStartIndex: 1,
		alwaysShowAllBtns: true, // Always show next and previous button
		// withFirstAndLast: false, // Hide the going to First and Last page button
		// hideSizePerPage: true, // Hide the sizePerPage dropdown always
		// hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
		firstPageText: "First",
		prePageText: "Back",
		nextPageText: "Next",
		lastPageText: "Last",
		nextPageTitle: "First page",
		prePageTitle: "Pre page",
		firstPageTitle: "Next page",
		lastPageTitle: "Last page",
		showTotal: true,
		paginationTotalRenderer: customTotal,
		disablePageTitle: true,
		sizePerPageList: [
			{
				text: "10",
				value: 10,
			},
			{
				text: "20",
				value: 20,
			},
			{
				text: "40",
				value: 40,
			},
			{
				text: "All",
				value: Case.length,
			},
		], // A numeric array is also available. the purpose of above example is custom the text
	};

	return (
		<Fragment>
			<Form className="theme-form" onSubmit={(e) => ShowFilteredCases(e)}>
				<Col sm="12">
					<Card>
						{/* <HeaderCard title={TaskDetails} /> */}
						<CardBody className="grid-showcase grid-align">
							<SearchBar clearSearchFilter={clearSearchFilter} />
							{/* <SearchButton /> */}
						</CardBody>
					</Card>
				</Col>
				<Row>
					<Col>
						{/* <DataTable
              data={filteredRows}
              columns={columns}
              center={true}
              striped={true}
              pagination
              highlightOnHover={true}
              pointerOnHover={true}
              SearchBar
              conditionalRowStyles={auditorCaseReturned}
            /> */}
						{loadingElement ? (
							loadingContent
						) : (
							<BootstrapTable
								keyField="id"
								data={Case}
								columns={columns}
								headerClasses="header-class"
								filter={filterFactory()}
								hover
								rowStyle={getRowStyles}
								pagination={paginationFactory(options)}
								rowEvents={rowEvents}
							/>
						)}
					</Col>
				</Row>
			</Form>

			{modal ? (
				<ModalForm
					modal={modal}
					type={type}
					modalTitle={modalTitle}
					value={caseDetail}
					toggle={toggle}
					caseDetail={caseDetail}
					caseCreationDetail={caseCreationDetail}
					caseRemarks={caseRemarks}
					isViewClicked={isViewClicked}
					size="lg"
				/>
			) : (
				""
			)}
		</Fragment>
	);
};
export default UserComponent;
