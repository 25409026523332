import React, { Fragment, useState } from "react";
import CommonModal from "./commonModal";
import CaseCreationFormModal from "../CaseCreationForm";
import CaseUploadForm from "../caseUploadForm";
import CaseDetailsTable from "../CaseDetailsTable";
import CaseDocumentViewPage from "../caseDocumentViewPage";
import CasePaymentModal from "./CasePaymentModal";
import SingleDocumentEdit from "../SingleEditDocument";

const ModalForm = ({
	modal,
	toggle,
	modalTitle,
	type,
	circleDetails,
	uploadCaseId,
	caseDetail,
	viewCaseId,
	changeStatus,
	caseCreationDetail,
	caseRemarks,
	isViewClicked,
	uploadedDocuments,
	caseDetailsId,
	isEditDocuments,
	mode,
	size,
	paymentDetails,
	editDocument,
	documentId,
}) => {
	console.log("caseStaus...", changeStatus);
	console.log(`type......${type}`);

	return (
		<Fragment>
			{/* <Btn attrBtn={{ color: 'primary', onClick: toggle }} >{VerticallyCentered}</Btn> */}
			<CommonModal
				isOpen={modal}
				title={modalTitle}
				toggler={toggle}
				size={size}
				type={type}
				caseDetail={caseDetail}
				mode={mode}
			>
				{type === "view" && (
					<CaseDetailsTable
						caseDetails={caseDetail}
						type={type}
						caseCreationDetails={caseCreationDetail}
						caseRemarks={caseRemarks}
						isViewClicked={isViewClicked}
					/>
				)}
				{type !== "view" &&
					mode === undefined &&
					(editDocument ? (
						<SingleDocumentEdit toggle={toggle} documentId={documentId} />
					) : (
						<CaseUploadForm
							toggle={toggle}
							caseDetailsId={
								uploadCaseId === undefined ? caseDetailsId : uploadCaseId
							}
							changeStatus={changeStatus}
							uploadedDocuments={uploadedDocuments}
							isEditDocuments={isEditDocuments}
						/>
					))}
				{mode === "payment" && (
					<CasePaymentModal toggle={toggle} paymentDetails={paymentDetails} />
				)}
			</CommonModal>
		</Fragment>
	);
};

export default ModalForm;
