import React from "react";
import Table from "react-bootstrap/Table";
import * as moment from "moment";

const CaseCreationDetails = ({ caseCreationDetails }) => {
	console.log("case creation Detail ...", caseCreationDetails);
	const {
		acceptance_order_no,
		agreement_no,
		nature_work,
		work_start_date,
		work_end_date,
	} = caseCreationDetails;
	const workStartDate = moment(work_start_date).format("DD/MM/YYYY");
	const workEndDate = moment(work_end_date).format("DD/MM/YYYY");
	return (
		<>
			<h6 className="text-center mb-3">Case Details</h6>
			<Table bordered hover responsive className="mt-3">
				<thead className="table-secondary">
					<tr>
						<th>Acceptance Number</th>
						<th>Agreement Number</th>
						<th>Nature of work</th>
						<th>Work Start Date</th>
						<th>Work End Date</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{acceptance_order_no}</td>
						<td>{agreement_no}</td>
						<td>{nature_work}</td>
						<td>{workStartDate}</td>
						<td>{workEndDate}</td>
					</tr>
				</tbody>
			</Table>
		</>
	);
};

export default CaseCreationDetails;
