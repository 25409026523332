import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";

const CaseHistoryTable = ({ failedTransaction }) => {
  const entitlementCode = localStorage.getItem("entitilement");
  console.log("entitlementCode", entitlementCode);
  console.log("failedTransaction ...", failedTransaction);

  return entitlementCode === "AC001" ? (
    <>
      <h6 className="mt-3 text-center">Failed Transaction</h6>
      <Table bordered hover responsive className="mb-3 mt-2">
        <thead className="table-secondary">
          <tr>
            <th className="w-25">Date of Rejection</th>
            <th>Net Amount</th>
            <th>Pay Ref Number</th>
            <th>Return Amount</th>
            <th>Transaction Number</th>
          </tr>
        </thead>
        <tbody>
          {failedTransaction.map((eachFailedTransaction) => {
            return (
              <tr key={eachFailedTransaction.id}>
                <td>
                  {eachFailedTransaction.updatedAt
                    .slice(0, 10)
                    .split("-")
                    .reverse()
                    .join("/")}
                </td>
                <td>{eachFailedTransaction.net_amount}</td>
                <td>{eachFailedTransaction.payment_reference_number}</td>
                <td>{eachFailedTransaction.return_amount}</td>
                <td>{eachFailedTransaction.transaction_number}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  ) : (
    ""
  );
};

export default CaseHistoryTable;
