import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardBody, Form, Label, Row, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userActions, validationActions } from "../../_services";
import Select from "react-select";

const EditUser = ({ toggle, value, userUpdatedToggle }) => {
	console.log("value", value);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		resetOptions: {
			keepDirtyValues: true, // user-interacted input will be retained
			keepErrors: true, // input errors will be retained with value update
		},
	});

	const id = value[0].id;
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [loadingElement, setLoadingElement] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [option, setOption] = useState([]);
	const dispatch = useDispatch();
	let roleValuesArray = [];
	let email = value[0].email_id;

	const entitlementCode = localStorage.getItem("entitilement");

	console.log("selectedOptions", selectedOptions);

	const handleEdit = () => {
		setIsEditMode(true);
	};

	useEffect(() => {
		setValue("fName", value[0].first_name);
		setValue("lName", value[0].last_name);
		setValue("emailId", value[0].email_id);
		setValue("mbNo", value[0].mobile_number);
		setValue("Desigination", value[0].designation);
		setValue("role", value[0].role);
		setSelectedOptions(value[1]);
		optionFilter();
	}, [value, setValue]);

	const handleSelectChange = (selectedOptions) => {
		console.log("first...", selectedOptions);

		// console.log("tempArray..", tempArray);
		setSelectedOptions(selectedOptions); // Update the selected options state
	};
	console.log("selectedOptions", selectedOptions);

	const roleOptions = [
		{ value: "Auditor", label: "Auditor" },
		{ value: "Auditor Admin", label: "Auditor Admin" },
		{ value: "Accountant", label: "Accountant" },
		{ value: "Master Contractor", label: "Master Contractor" },
	];

	const optionFilter = () => {
		let role = value[0].role.toLowerCase();
		const filteredOptions1 = roleOptions.filter(
			(option) => option.value.toLowerCase() !== role
		);
		console.log(filteredOptions1);
		setOption(filteredOptions1);
	};

	const otherRoleVAlidation = (e) => {
		const filteredOptions = roleOptions.filter(
			(option) => option.value.toLocaleUpperCase() !== e.target.value
		);
		console.log(filteredOptions);
		setOption(filteredOptions);
	};
	const Displayalert = () => {
		toast.success("User updated successfully!");
		/* Toast.fire({
      text: "User updated successfully",
      icon: "success",
    }); */
	};

	const mailVAlidation = async (e) => {
		let mailId = e.target.value.toUpperCase();
		if (mailId != email) {
			await dispatch(validationActions.mailVAlidation(mailId)).then((res) => {
				if (res.payload === "Already exist") {
					setValue("emailId", "");
					toast.warn("Email Id already exists", {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			});
		}
	};

	const onSubmit = (data) => {
		setLoadingElement(true);
		roleValuesArray = selectedOptions.map((item) => item.value);
		console.log("TYPES OF DATA", typeof data);
		console.log("roleValuesArray...", roleValuesArray);
		// data = { ...data, roleValuesArray };
		if (data) {
			const reqBody = {
				first_name: data.fName.toUpperCase(),
				last_name: data.lName.toUpperCase(),
				email_id: data.emailId.toUpperCase(),
				mobile_number: data.mbNo,
				designation: data.Desigination.toUpperCase(),
				primaryRoles: data.role.toUpperCase(),
				otherRoles: roleValuesArray,
				user_name: data.mbNo,
				password: data.mbNo.slice(0, 4),
			};
			console.log("user info -->", reqBody);
			dispatch(userActions.updateUserById({ id, reqBody })).then((data) => {
				console.log("update user from redux", data);
				if (data.type === "/UpdateUserById/fulfilled") {
					userUpdatedToggle();
					Displayalert();
					toggle();
				}
			});
		} else {
			errors.showMessages();
		}
	};
	return (
		<Fragment>
			<Card>
				<CardBody>
					<Form
						className="needs-validation"
						noValidate=""
						onSubmit={handleSubmit(onSubmit)}
					>
						<Row>
							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom01">
									First Name<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom01"
									autoComplete="off"
									type="text"
									placeholder="First Name"
									name="fName"
									disabled={!isEditMode}
									{...register("fName", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.fName && "First name is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom02">
									Last Name<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom02"
									autoComplete="off"
									type="text"
									placeholder="Last Name"
									name="lName"
									disabled={!isEditMode}
									{...register("lName", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.lName && "Last Name is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<Row>
							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom03">
									Email id<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom03"
									autoComplete="off"
									type="email"
									placeholder="Email id"
									name="emailId"
									disabled={!isEditMode}
									{...register("emailId", { required: true })}
									onBlur={(e) => mailVAlidation(e)}
								/>
								<span className="d-block text-danger">
									{errors.emailId && "Email Id  is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>

							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom04">
									Mobile Number(User ID)<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark b-secondary"
									id="validationCustom04"
									autoComplete="off"
									type="text"
									pattern="[0-9]*"
									placeholder="Mobile Number"
									name="mbNo"
									maxlength="10"
									disabled="true"
									{...register("mbNo", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.mbNo && "Mobile number is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<Row>
							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom05">
									Desigination<span className="text-danger">*</span>
								</Label>
								<input
									className="form-control bg-light text-dark"
									id="validationCustom05"
									autoComplete="off"
									name="Desigination"
									type="text"
									placeholder="Desigination"
									disabled={!isEditMode}
									{...register("Desigination", { required: true })}
								/>
								<span className="d-block text-danger">
									{errors.Desigination && "Desigination is required."}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom06">
									Primary Role<span className="text-danger">*</span>
								</Label>
								<select
									className="form-select"
									autoComplete="off"
									id="validationCustom06"
									name="role"
									disabled={!isEditMode}
									{...register("role", { required: true })}
									onChange={(e) => {
										otherRoleVAlidation(e);
									}}
								>
									{entitlementCode === "AA001" ? (
										<>
											<option value="">Choose Role </option>
											<option value="Auditor">Auditor </option>
										</>
									) : (
										<>
											<option value="">Choose Role </option>
											<option value="AUDITOR ADMIN">Auditor Admin </option>
											<option value="AUDITOR">Auditor </option>
											<option value="ACCOUNTANT">Accountant</option>
										</>
									)}
								</select>
								<span className="d-block text-danger">
									{errors.role && "Role  is required"}
								</span>
								<div className="valid-feedback">Looks good!</div>
							</Col>
						</Row>
						<Row>
							<Col md="6 mb-3">
								<Label className="form-label" for="validationCustom06">
									other Roles<span className="text-danger"></span>
								</Label>

								<Select
									options={option}
									id="validationCustom06"
									autoComplete="off"
									value={selectedOptions}
									onChange={handleSelectChange}
									className="basic-multi-select bg-light text-dark"
									classNamePrefix="select"
									isMulti
									isDisabled={!isEditMode}
								/>
							</Col>
						</Row>
						{isEditMode &&
						(entitlementCode === "AG001" ||
							entitlementCode === "ORG001" ||
							entitlementCode === "AA001") ? (
							<>
								<Button
									color="secondary"
									className="me-3"
									onClick={() => setIsEditMode(false)}
								>
									Cancel
								</Button>
								<Button color="primary">{"Update"}</Button>
							</>
						) : entitlementCode === "AG001" ||
						  entitlementCode === "ORG001" ||
						  entitlementCode === "AA001" ? (
							<Button color="primary" onClick={handleEdit}>
								Edit
							</Button>
						) : (
							""
						)}
					</Form>
				</CardBody>
			</Card>
		</Fragment>
	);
};

export default EditUser;
