import React, { Fragment, useState } from "react";
import CommonModal from "./commonModal";
import CreateUser from "../CreationForm";
import EditUser from "../Editform";

const ModalForm = ({
  modal,
  toggle,
  type,
  title,
  value,
  userCreationToggle,
  userUpdatedToggle,
}) => {
  return (
    <Fragment>
      {/* <Btn attrBtn={{ color: 'primary', onClick: toggle }} >{VerticallyCentered}</Btn> */}
      <CommonModal isOpen={modal} title={title} toggler={toggle} size="lg">
        {type === "add" && (
          <CreateUser toggle={toggle} userCreationToggle={userCreationToggle} />
        )}
        {type === "edit" && (
          <EditUser
            value={value}
            toggle={toggle}
            userUpdatedToggle={userUpdatedToggle}
          />
        )}
      </CommonModal>
    </Fragment>
  );
};

export default ModalForm;
