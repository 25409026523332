import React from "react";
import ViewCase from "./ViewCase";
import { Card, CardBody } from "reactstrap";

const CaseFlowComponent = () => {
	return (
		<Card>
			<CardBody>
				<ViewCase />
			</CardBody>
		</Card>
	);
};

export default CaseFlowComponent;
