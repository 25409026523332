import React from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Edit, Video, Activity, PlayCircle, Check } from "react-feather";

const CaseFlowHistoryComponent = (props) => {
	const { caseFlowHistoryDetails } = props;
	console.log("caseFlowHistoryDetails..........", caseFlowHistoryDetails);
	return (
		<>
			{caseFlowHistoryDetails.length === 0 ? (
				<p className="font-danger f-w-600 text-center">NO CASE HISTORY</p>
			) : (
				<VerticalTimeline>
					{caseFlowHistoryDetails.map((eachCaseStatus) => {
						return (
							<VerticalTimelineElement
								className="vertical-timeline-element--work"
								animate={true}
								date={eachCaseStatus.statusDate}
								icon={<Activity />}
							>
								<h4 className="vertical-timeline-element-subtitle">
									{eachCaseStatus.statusFlow}
								</h4>
								<p>Action By: {eachCaseStatus.actionBy}</p>
								<p>Identified By: {eachCaseStatus.identifiedBy}</p>
							</VerticalTimelineElement>
						);
					})}
				</VerticalTimeline>
			)}
		</>
	);
};

export default CaseFlowHistoryComponent;
